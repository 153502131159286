import { message } from "antd";
import { Alert } from "../../../components"
import { domainUrl4 } from "../../constants/apiconstans/domanUrl";
import { checkAuthorization } from "../../helpers";
const updateFavorite=(data)=>{
    const {userToken, key}= checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append("token", `${userToken}`)
    myHeaders.append("key", key)
    var requestOptions = {
      headers: myHeaders,
      method: 'POST',
      redirect: 'follow',
      body: data
    }
    return (
      fetch(domainUrl4+"/addRemoveFavourite",requestOptions)
        .then(response=>response.json())
        .then(result=>{
            if(result?.ResponseCode)
                {
                    message.success(result?.ResponseMsg)
                    return 1
                }
            else       
                throw 'error while marking favorite'
        })
        .catch((error)=>{
            console.error(error)
            return 0
        })
    )
}
export {updateFavorite}
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import{ Space, Typography, Skeleton} from 'antd'
import './artistp.css'
import {Cart} from ".."
import { actionsApi} from '../../shared'
import { ProfilePicture, SocialMedias } from './'
import "./index.css"

const { Title } = Typography

const ArtistprofileSocio = ({artistProfile, loading}) => {
    const dispatch= useDispatch()
    const {data: artistStories}= useSelector(state => state?.artistStories)
    const [data, setData]= useState()
    const [ visibleCart, setVisibleCart ] = useState(false)
    const loadingText= "Loading Loading Loading Loading Loading Loading Loading Loading Loading Loading"

    useEffect(()=>{
        setData(
            {
                title: artistProfile?.name,
                img: artistProfile?.image,
                bio: artistProfile?.bio,
                email:`${artistProfile?.email}`,
                bgText: new Array(10).fill(null)?.map(_=> artistProfile?.name+" "),
            }
        )
        if(artistProfile)
        {
            let user_id= localStorage?.getItem("userId")
            dispatch(actionsApi?.getArtistStories({artist_id: artistProfile?.artist_id, user_id}))
        }
    },[artistProfile])
  return (
    <div>
        <Space 
            direction='vertical' 
            className='borderbm py-3 w-100' 
            size={40}
            >
            <div 
                direction='vertical' 
                className='center profile-header'
                >
                <ProfilePicture 
                    {...{loading, artistProfile, artistStories}}
                    />
                <div>
                    <p className='line-one'>
                        {
                            loading? loadingText: data?.bgText
                        }
                    </p>
                    <p className='line-two'>
                        {
                            loading? loadingText: data?.bgText
                        }
                    </p>
                    <p className='line-three'>
                        {
                            loading? loadingText: data?.bgText
                        }
                    </p>
                </div>
            </div>
            <div className='justify-center'>
                <Space 
                    direction='vertical' 
                    className='width-93 center'
                    size={3}
                    >
                    {
                        loading ? 
                        <Skeleton.Input 
                            className='radius-6 height-25'
                            active
                            />
                        :
                        <Title level={2} className='m-0 text-center'>
                            {data?.title}
                        </Title>  
                    }
                    {
                        loading ? 
                        <Skeleton.Input 
                            className='radius-6 height-20'
                            active
                            />
                        :
                        <Title level={5} className='my-0 width-1076 text-center'>
                            {
                                data?.bio
                            }
                        </Title>
                    }
                    <SocialMedias 
                        loading={loading}
                        data={artistProfile}
                        />
                </Space>
            </div>
        </Space>
        <Cart 
            visible={visibleCart} 
            onClose={()=>setVisibleCart(false)} 
            />
    </div>
  )
}

export {ArtistprofileSocio}
import React,{useEffect } from 'react'
import { Typography, Row, Col} from 'antd'
import { actionsApi, checkAuthorizationWithoutLogin } from '../../shared'
import { useSelector, useDispatch } from 'react-redux'
import { CardSkeleton, DataNotFound, PostCard } from '../'
import { UnAuthorized } from '../'
import { useTranslation } from 'react-i18next'
const { Title } = Typography

const NewsComp = () => {
    const {t}= useTranslation()
    const {data, loading}= useSelector(state => state?.feeds)
    const dispatch= useDispatch()
    useEffect(() => {
        const {userToken, user_id}= checkAuthorizationWithoutLogin()
        if(!userToken || !user_id)
        {
            dispatch(actionsApi?.feedsResponse(-1))
            return
        }
        dispatch(actionsApi?.getFeeds())
    }, [])

  return (
    <div className='justify-center'>
        <div className='width-93 py-3 border-top-black'>
            <Row gutter={[32,32]}>
                <Col span={24}>
                    <Title level={1} className='m-0'>
                        {t("Feed.")}
                    </Title>
                </Col>
            {
                data===-1 ?
                <UnAuthorized/>
                :
                loading ?
                new Array(6)?.fill(null)?.map((_, index)=>
                <Col xs={24} sm={24} md={12} lg={8} key={'feed-'+index}>
                    <CardSkeleton/>
                </Col>
                )
                :
                !data?.length ?
                <DataNotFound alt='NFSounds no feeds found'/>
                :
                data?.map((feed, index)=>
                <Col xs={24} sm={24} md={12} lg={8} key={'feed-'+index}>
                    <PostCard 
                        feed={feed}
                        />
                </Col>
                )
            }
            </Row>
        </div>
    </div>
  )
}

export {NewsComp}
import { useEffect, useState} from "react"
import { useSelector, useDispatch} from "react-redux"
import { useParams } from "react-router"
import {Row, Col, Pagination} from "antd"
import { checkAuthorization, actionsApi, updateFavorite} from "../../../shared"
import { CardSkeleton } from "../../"
import { ItemCard } from "../../ItemCard"
import { DataNotFound } from "../../Utils"
import { useTranslation } from "react-i18next"

const Collectibles=()=>{

    let { t }= useTranslation()
    let { ID } = useParams()
    const dispatch= useDispatch()
    const {data, pagination, loading}= useSelector(state => state?.artistCollection)
    const {data: artistData}= useSelector( state => state?.artistProfile)
    const [state, setState]= useState([])

    useEffect(()=>{
        if(artistData?.subscription?.subscribtionStatus)
        {
            const {user_id}= checkAuthorization()
            let data={
                user_id,
                artist_id: ID
            }
            dispatch(actionsApi?.getMyCollectionByArtist({data, ...pagination, pageNo: 1}))
        }
    }, [ID, artistData])

    useEffect(()=>{
        if(data?.length)
            setState([...data])
    },[data])

    const call=(pageNo=1, pageSize=6)=>{
        const {user_id}= checkAuthorization()
        let data={
            user_id,
            artist_id: ID
        }
        dispatch(actionsApi?.getMyCollectionByArtist({data, pageNo, pageSize}))
    }
    const UpdateFavorite=async (store_id, is_Favourite)=>{
        const {user_id}= checkAuthorization()
        dispatch(actionsApi?.artistCollectionResponse([...state?.map(_=>{
            if(parseInt(_?.store_id) === parseInt(store_id))
            return (
                {
                    ..._,
                    is_Favourite:is_Favourite?'1':'0'
                }
            )
            return _
        })]))
        let formData = new FormData()
        formData.append(`user_id`, user_id)
        formData.append(`store_id`, store_id)
        await updateFavorite(formData)    
       }
    return (
        <>
        {
            loading && data===null ?
            <Row gutter={[16,16]}>
                {
                    new Array(6)?.fill(null)?.map((_, index)=>
                        <Col 
                            xs={24} sm={12} md={12} lg={8} 
                            key={'collection-item-'+index}
                            >
                            <CardSkeleton/>
                        </Col>
                    )
                }
            </Row>
            :
            !state?.length ?
            <DataNotFound
                icon='Nothing to see yet.png'
                alt='NFSounds no artist collection nft found'
                message= {"Sorry ! Nothing yet ..."}
                />
            :
            <Row gutter={[32, 24]}>
                {
                    state?.map((dat,dt)=>
                    <Col xs={24} sm={12} md={12} lg={8} key={'isFav-'+dt}>
                        <ItemCard 
                            store
                            data={{store_dtl:dat}}
                            to={'/item-detail/'+dat?.store_id}
                            UpdateFavorite={UpdateFavorite}
                            collection
                            />
                    </Col>
                    )
                }
                {
                    data && data?.length ?
                    <Col span={24}>
                        <div className='center'>
                            <Pagination 
                                total={pagination?.totalRecords} 
                                defaultPageSize={pagination?.pageSize} 
                                current={pagination?.pageNo}
                                onChange={(pageNo, pageSize)=> call(pageNo, pageSize)} 
                                />
                        </div>
                    </Col>
                    :<></>
                }
            </Row>
        }
        </>
    )
}
export default Collectibles
import React,{ useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Space, Typography, Skeleton, Pagination} from 'antd'
import './artist.css'
import { useDispatch, useSelector} from 'react-redux'
import { actionsApi } from '../../shared'
import {AutoCompleteInput,} from '../'
import { ArtistCard } from './'
import { useTranslation } from 'react-i18next'
import "./index.css"
const { Title} = Typography

const TextTop = () => {
    const {t}= useTranslation()
    const navigate= useNavigate()
    const dispatch= useDispatch()
    const {data, pagination, loading}= useSelector(state => state?.Artists)
    const [state, setState]= useState([])

    useEffect(()=>{
        getArtistList()
    },[])
    const getArtistList=(pageNo=1)=>{
        let user_id= localStorage?.getItem("userId")
        let formData = new FormData()
        formData.append(`user_id`,user_id || null)
        dispatch(actionsApi?.getArtistsList({formData, pageNo}))
    }
    useEffect(()=>{
        if(data)
        setState([
            ...data?.map(artist=> ({...artist?.artistDetails}))
        ])
    },[data])

  return (
    <div className='justify-center page-bg'>
        <div className='width-93 py-3'>
            <Row gutter={[32,32]}>
                <Col span={24}>
                    <div>
                        <Space className='w-100 flex-col-center' direction='vertical' size={15}>
                            <Title level={1} className='text-center width-1076 my-0'>
                                {t("artist heading 1")}
                            </Title>
                            <Title level={5} className='text-center width-1076 my-0'>
                                {t("artist para 1")}
                            </Title>
                            <Title level={5} className='text-center width-1076 my-0'>
                                {t("artist para 2")}
                            </Title>
                            <Title level={5} className='text-center width-1076 my-0'>
                                {t("artist para 3")}
                            </Title>
                            <div className='search-bar'>
                                <AutoCompleteInput/>
                            </div>
                        </Space>
                    </div>
                </Col>
                {
                    loading && !state?.length ?
                    new Array(8)?.fill(null)?.map((_, index)=>
                        <Col xs={12} sm={12} md={8} lg={6} key={'skelton-'+index}>
                            <Skeleton.Button 
                                active 
                                size='large' 
                                shape={'square'} 
                                block
                                style={{width:'100%', height:'65px'}}
                                className='radius-6'
                                />
                        </Col>
                    )
                    :
                    state?.map((artist, index)=>
                    <Col 
                        xs={12} sm={12} md={8} lg={6} 
                        key={'artist-'+index}
                        onClick={()=>{
                            navigate(`/artist/${artist?.artist_id}`, { replace: true })
                        }}
                        >
                        <ArtistCard
                            artist={artist}
                            />
                    </Col>
                    )
                }
                {
                    pagination?.totalRecords>8 ?
                    <Col span={24}>
                        <Pagination 
                            total={pagination?.totalRecords} 
                            defaultPageSize={8} 
                            current={pagination?.currentPage}
                            onChange={(value)=>{getArtistList(value)}} 
                            />
                    </Col>
                    :
                    <></>
                }
            </Row>
        </div>
    </div>
  )
}

export {TextTop}
import React,{ useState } from 'react'
import { Col, Row, Tabs, Button, Typography} from 'antd'
import FeedCards from './FeedCards'
import ArtistCollectiblesCards from './ArtistCollectiblesCards'
import ArtistLiveChat from './ArtistLiveChat'
import { ArtistLock} from './'
import { useSelector } from 'react-redux'
import StoreCard from '../storecomp/StoreCard'
import { BlackLine } from '../'
import "./index.css"
import Collectibles from './Collectibles'
const { Title } = Typography

const ArtistFeedTabs = (artist_id) => {
    const {data: artist, loading}= useSelector( state => state?.artistProfile)
    const [changetab, setChangeTab] = useState(0)
    const [colorchange, setColorChange] = useState(0)
    const data = {
        tabdata:[
            {
                title:  <Button 
                            size='large' 
                            type={colorchange===0 ? 'primary' : "default"} 
                            onClick={()=>setColorChange(0)}
                            block
                            >
                                FEED
                        </Button>,
            },
            
            {
                title:  <Button 
                            size='large' 
                            type={colorchange===1 ? 'primary' : "default"} 
                            onClick={()=>setColorChange(1)}
                            block
                            >
                                COLLECTIBLES
                        </Button>,
            },
            {
                title:  <Button 
                            size='large' 
                            type={colorchange===2 ? 'primary' : "default"} 
                            onClick={()=>setColorChange(2)}
                            block
                            >
                                LIVE CHAT
                        </Button>
            },
        ]
    }
  return (
    <>
        <div className='flex-col-center py-3'>
            <div className='width-93'>
                <Tabs 
                    className='artist-profile' 
                    centered 
                    onChange={key => setChangeTab(key)}
                    tabBarGutter={8}
                    moreIcon={null}
                    >
                    {
                        data?.tabdata?.map((tdata,td)=>
                            <Tabs.TabPane 
                                tab={tdata?.title}  
                                key={td} 
                                style={{width:'100%'}}
                                > 
                            </Tabs.TabPane>
                        )
                    }                    
                </Tabs>
            </div>
            <div className='width-96 py-3'>
                {
                    artist?.subscription?.subscribtionStatus?
                    (
                        changetab==0 ?
                        <div>
                            <FeedCards/>
                        </div>
                        :
                        changetab==1 ?
                        <Collectibles/>
                        :
                        <ArtistLiveChat artist_id={artist_id}/>
                    )
                    :
                    <ArtistLock artist_id={artist?.artist_id}/>      
                }
            </div>
        </div>
    </>
  )
}

export {ArtistFeedTabs}
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl'
export const getArtistStories = createAsyncThunk('ArtistStories',
    async (data, { dispatch, getState }) => {
        dispatch(gettingArtistStories())
        let userToken=localStorage.getItem('token')
        let user_id= localStorage?.getItem("userId")
        var myHeaders = new Headers();
        myHeaders.append("token", `${userToken}`)
        myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
        myHeaders.append("content-type", `application/json`)
        var requestOptions = {
            method: 'POST',
            headers:myHeaders,
            redirect: 'follow',
            body: JSON.stringify(data)
        }
        fetch(domainUrl + `/ShowStories`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode===1)
                    dispatch(artistStoriesResponse(result?.data?.reverse()))
                else if(result?.ResponseCode===2)
                    dispatch(artistStoriesClear())
                else
                {
                    dispatch(artistStoriesClear())
                    throw result?.ResponseMsg
                }
            })
            .catch(error => {
                dispatch(artistStoriesClear())
            })
    }
)

const artistStoriesSlice = createSlice({
    name: 'artistStories',
    initialState: {
        data: [],
        loading: false
    },
    reducers: {
        gettingArtistStories: (state, action) => {
            state.loading = true;
        },
        artistStoriesResponse: (state, action) => {
            state.data = action.payload;
            state.loading= false;
        },
        artistStoriesClear: (state, action) => {
            state.data = [];
            state.loading = false;
        },
    },
})

export const {gettingArtistStories, artistStoriesResponse, artistStoriesClear} = artistStoriesSlice.actions
export default artistStoriesSlice.reducer
import React from 'react'
import { Row, Col, Typography } from 'antd'
import { CheckoutLeftSide, CheckoutRightSide } from '../../components/CheckoutComponents'
import { useSelector, useDispatch } from 'react-redux'
import { actionsApi } from '../../shared'
import { useState, useEffect } from 'react'
const { Title } = Typography

const Checkout = () => {
  const dispatch= useDispatch()
  const {data, loading} =useSelector(state => state?.cart)
  const {data: artistCartData}= useSelector(state => state?.artistCart)
  const [state, setState]= useState([])
  const [artistState, setArtistState]= useState([])
  const [total_amount, setTotal_amount]= useState(0)
  const [artistAmount, setArtistAmount]= useState(0)
  useEffect(()=>{
    dispatch(actionsApi?.getCartList())
  },[])
  useEffect(()=>{
      if(data)
          if(data?.cart)
                  setState(data?.cart)
  }, [data])
  useEffect(()=>{
    if(state)
      {
          let total=0;
          for(let i=0;i<state?.length;i++)
              total+= (state[i]?.quantity*(state[i]?.amount || state[i]?.product?.listing_price))
          setTotal_amount(total)
      }
  },[state])
  return (
    <div  className='gradient-bg justify-center py-5'>
      <Row gutter={[32,32]} className='width-93'>
          <Col span={24}>
              <Title level={2} className='my-0'>CHECKOUT.</Title>
          </Col>
          <Col xs={24} sm={24} md={24} lg={16}>
              <CheckoutLeftSide total_amount={total_amount}/>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8}>
              <CheckoutRightSide 
                data={state} 
                total_amount={total_amount}
                />
          </Col>
      </Row>
    </div>
  )
}
export {Checkout}
import { Select } from "antd"
import CountryCodesJSON from "../../data/CountryCodes.json"
import "./signup.css"
const {Option}= Select
const CountryCodes= ({...props})=>{
    return (
        <Select
          style={{
            width: '90px',
          }}
          showSearch
          className="country-codes-select"
          {...props}
        >
            {
                CountryCodesJSON?.map((country, index)=>
                    <Option 
                        value={country?.dial_code} 
                        key={'country-code-'+index}
                        >
                        {
                            country?.dial_code+" ("+ country?.code +")"
                        }
                    </Option>
                )
            }
        </Select>
    )
}
export default CountryCodes
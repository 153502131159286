import SingleSocialSec from '../../components/singledetailcomp/SingleSocialSec'
import { ItemDetail } from '../../components/ItemDetail'
import { Benefits } from '../../components/Benefits'
const MarketPlaceItemDetail = () => {
   
  return (
    <>
      <div className='page-bg'>
        <ItemDetail isMarketPlaceItem/>
        <Benefits/>
      </div>
      <SingleSocialSec />
    </>
  )
}

export {MarketPlaceItemDetail}
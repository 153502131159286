import { Form, Row, Col, Button, Typography} from 'antd'
import React, { useEffect, useState } from 'react'
import { NFSelect} from '../Form';
import { useDispatch, useSelector } from 'react-redux';
import { actionsApi } from '../../shared';
import { releaseDateLookup, storePriceLookup } from '../../shared/lookups'
import { useTranslation } from 'react-i18next'

const Filters = () => {
    const { t }= useTranslation()
    const dispatch= useDispatch()
    const [form] = Form.useForm()
    const {marketPlaceLookups}= useSelector(state => state?.marketPlace)
    const [lookups, setLookups]= useState({artistLookup:[], genreLookup:[]})
    const [listing, setListing]= useState(false)
    useEffect(()=>{
        form.setFieldsValue({
            artist_id: 0,
            type_id: 0,
            price: null,
            release_date:null,
            listing: false,
            })
        filter()
    }, [])
    const reset=()=>{
        form.setFieldsValue({
            artist_id: 0,
            type_id: 0,
            price: null,
            release_date:null,
            list_type: false,
            })
    }
    useEffect(()=>{
        setLookups({
            artistLookup:[{id:0, name:'All'},...marketPlaceLookups?.artistLookup?.map(artist=> ({...artist,id:artist?.artist_id}))],
            genreLookup:[{id:0, name:'All'},...marketPlaceLookups?.genreLookup?.map(type=> ({...type,id:type?.type_id}))]
        })
    }, [marketPlaceLookups])
    const filter=(pageNo=1)=>{
        let data= form.getFieldsValue(['artist_id','type_id','price','release_date','list_type'])
        let user_id= localStorage?.getItem("userId")
        let formData = new FormData()
        formData.append(`user_id`,user_id || null)
        formData.append(`artist_id`, data?.artist_id || '')
        formData.append(`type_id`,data?.type_id || '')
        formData.append(`price`,data?.price)
        formData.append(`release_date`,data?.release_date || '')
        formData.append(`list_type`, data?.list_type?"my_listing": null)
        dispatch(actionsApi?.getMarketPlaceItems({formData, pageNo}))
    }
    return (
        <div className='space-between-end px-3'>
            <Typography.Title level={2} className='my-0'>
                {t("MARKETPLACE")}.
            </Typography.Title>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                onFinish={filter}
                style={{width:'700px'}}
                >
                <Row gutter={[16, 8]}>
                    <Col sm={5} xs={8} className='align-end'>
                        <Button 
                            block
                            size='large'
                            type={listing?'primary':"default"}
                            onClick={()=>{
                                form.setFieldsValue({
                                    ...form.getFieldsValue(),
                                    list_type: !listing
                                })
                                setListing(!listing)
                                filter()

                            }}
                            >
                            {t("My Listing")}
                        </Button>
                    </Col>
                    <Col sm={4} xs={8}>
                        <NFSelect
                            size='large'
                            label= {t('Artist')}
                            name="artist_id" 
                            value={form.getFieldValue('artist_id') || ''}
                            options={lookups?.artistLookup}
                            maxTagCount= 'responsive'
                            onChange={filter}
                            />
                    </Col>
                    <Col sm={5} xs={8}>
                        <NFSelect
                            size='large'
                            label= {t('GENRE')}
                            name="type_id" 
                            value={form.getFieldValue('type_id') || ''}
                            options={lookups?.genreLookup}
                            maxTagCount= 'responsive'
                            onChange={filter}
                            />
                    </Col>
                    <Col sm={5} xs={8}>
                        <NFSelect
                            size='large'
                            label= {t('RELEASE DATE')}
                            name="release_date" 
                            value={form.getFieldValue('release_date') || ''}
                            options={releaseDateLookup}
                            maxTagCount= 'responsive'
                            onChange={filter}
                            />
                    </Col>
                    <Col sm={5} xs={8}>
                        <NFSelect
                            size='large'
                            label= {t('Price')}
                            name="price" 
                            value={form.getFieldValue('price') || ''}
                            options={storePriceLookup}
                            maxTagCount= 'responsive'
                            onChange={filter}
                            />
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export {Filters}
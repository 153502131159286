import React,{ useEffect, useState } from 'react'
import {Pagination, Row, Col} from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { actionsApi, checkAuthorization } from '../../shared'
import { updateFavorite } from '../../shared'
import {ItemCard} from '../'

const Favorites = () => {
    const {data, pagination, loading} = useSelector(state => state?.favoriteItems)
    const dispatch= useDispatch()
    const [state, setState]= useState(null)
    useEffect(()=>{
        call()
    }, [])
    useEffect(()=>{
        if(data)
            setState([...data])
    },[data])
    
    const call=(pageNo=1, pageSize=6)=> dispatch(actionsApi?.getfavoriteItems({pageNo, pageSize}))
    const UpdateFavorite=async (store_id)=>{
        const {user_id}= checkAuthorization()
        dispatch(actionsApi?.favoriteItemsResponse([...state?.filter(_=> parseInt(_?.store_id)!==parseInt(store_id))]))
        let formData = new FormData()
        formData.append(`user_id`, user_id)
        formData.append(`store_id`, store_id)
        await updateFavorite(formData)    
    }
  return (
    <div>
        <Row gutter={[32, 24]}>
            {
                state?.map((dat,dt)=>
                <Col xs={24} sm={12} md={12} lg={8} key={'isFav-'+dt}>
                    <ItemCard 
                        store
                        data={{store_dtl:dat}}
                        to={'/item-detail/'+dat?.store_id}
                        UpdateFavorite={UpdateFavorite}
                        />
                </Col>
                )
            }
            {/* <Col span={24}>
                <div className='center'>
                    <Pagination 
                        total={pagination?.totalRecords} 
                        defaultPageSize={pagination?.pageSize} 
                        current={pagination?.pageNo}
                        onChange={(pageNo, pageSize)=> call(pageNo, pageSize)} 
                        />
                </div>
            </Col> */}
        </Row>            
    </div>
  )
}

export default Favorites

import React from 'react'
import { Space, Typography, Image } from 'antd'
import { useSelector } from 'react-redux'
import "./index.css"
import { DroppingSoonSlider } from './DroppingSoonSlider'
import { useTranslation } from "react-i18next"

const { Title } = Typography

export const StoreFirstComp = () => {
    const { t } = useTranslation();
    const {data, loading}= useSelector(state => state?.droppingSoonItems)
  return (
    <>
    <div className="bg-signup justify-center py-3" >
        <Space direction='vertical' className='width-93'>
            <Space direction="vertical" size={3} className='width-100'>
                <Title level={4} className='m-0 text-center'>
                    {
                        t('store first heading')
                    }
                </Title>
                <Title level={1}  className='m-0 text-center one-line'>
                    {
                        t('store second heading')
                    }
                </Title>
            </Space>
            <DroppingSoonSlider data={data}/>
            <Space direction='vertical' className='width-100 my-3'>
                <Title level={5} className='text-center my-0'>
                    {
                        t('first paragraph')
                    }
                </Title>
                <Title level={5} className='text-center my-0'>
                    {
                        t('second paragraph')
                    }
                </Title>
            </Space>
            <Space direction='vertical' className='width-100'>
                <Title level={4} className='text-center my-0'>
                    {
                        t('botton first heading')
                    }
                </Title>
                <Title level={4} className='text-center'>
                    {
                        t('bottom second heading')
                    }
                </Title>
                <Space className='w-100 justify-center' size={10}>
                    <Image src='/assets/app-store.png' width={'140px'} preview={false}/>
                    <Image src='/assets/google-play.png' width={'140px'} preview={false}/>
                </Space>
            </Space>
        </Space>
    </div>
    </>
  )
}
import { useState } from "react"
import { Collapse, Space, Typography} from "antd"
import { useTranslation } from "react-i18next"
import { IconArrowUp, IconArrowDown } from "@tabler/icons-react"
import "./index.css"
const {Title}= Typography
const { Panel } = Collapse

const AboutUs= ()=>{
    const { t }= useTranslation()
    const [currentPanel,setCurrentPanel]=useState(['1'])
    
    return (
        <div className="justify-center store-about-us" style={{background:'#fff'}}>
            <Space className="width-93 border-top-black py-5" direction='vertical'>
                <Title 
                    level={1} 
                    className="my-0"
                    >
                    {
                        t("ABOUT US")
                    }.
                </Title>
                <div className="flex-col-center about-us-collapses ">
                    <Collapse className="collapses secondary-bg" onChange={_ => setCurrentPanel(0)}>
                        <Panel 
                            showArrow={false}  
                            header={<Title className='my-0 text-white' level={5}>{t("store about us first heading")}</Title>} 
                            extra={
                                currentPanel === 0 ?
                                <IconArrowDown className='font-20 text-white'/>:
                                <IconArrowUp className='font-20 text-white'/>
                            }
                            >
                            <div  dangerouslySetInnerHTML={{ __html: t("store about us first detail")}}/>
                        </Panel>
                    </Collapse>
                    <div className="about-us-vertical-line mt-3"></div>
                    <div className="about-us-vertical-line-center">
                        <div></div>
                    </div>
                    <Collapse className="collapses mt-3 secondary-bg" onChange={_ => setCurrentPanel(1)}>
                        <Panel 
                            showArrow={false}  
                            header={<Title className='my-0 text-white' level={5}>{t("store about us second heading")}</Title>} 
                            extra={
                                currentPanel === 1 ?
                                <IconArrowDown className='font-20 text-white'/>:
                                <IconArrowUp className='font-20 text-white'/>
                            }
                            >
                            <div  dangerouslySetInnerHTML={{ __html: t("store about us second detail")}}/>
                        </Panel>
                    </Collapse>
                    <div className="about-us-vertical-line mt-3" style={{width:'100%'}}></div>
                    <div className="about-us-vertical-line-center">
                        <div></div>
                    </div>
                    <Collapse className="collapses mt-3 secondary-bg" onChange={_ => setCurrentPanel(2)}>
                        <Panel 
                            showArrow={false}  
                            header={<Title className='my-0 text-white' level={5}>{t("store about us third heading")}</Title>} 
                            extra={
                                currentPanel === 2 ?
                                <IconArrowDown className='font-20 text-white'/>:
                                <IconArrowUp className='font-20 text-white'/>
                            }
                            >
                            <div dangerouslySetInnerHTML={{ __html: t("store about us third detail")}}/>
                        </Panel>
                    </Collapse>
                    <div className="about-us-vertical-line mt-3"></div>
                    <Collapse className="collapses mt-3 secondary-bg " onChange={_ => setCurrentPanel(3)}>
                        <Panel 
                            showArrow={false}  
                            header={<Title className='my-0 text-white' level={5}>{t("store about us forth heading")}</Title>} 
                            extra={
                                currentPanel === 3 ?
                                <IconArrowDown className='font-20 text-white'/>:
                                <IconArrowUp className='font-20 text-white'/>
                            }
                            >
                            <div dangerouslySetInnerHTML={{ __html: t("store about us forth detail")}}/>
                        </Panel>
                    </Collapse>
                </div>
            </Space>
        </div>
    )
}
export {AboutUs}
import {Skeleton} from "antd"
import "./index.css"

export const CardSkeleton = ()=>{
    return (
        <div className="card-skelton">
            <Skeleton.Button 
                active
                block
                />
        </div>
    )
}
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Alert } from '../../../../components';
import { domainUrl2 } from '../../../constants/apiconstans/domanUrl';
export const loadClients = createAsyncThunk('Clients',
    async (userID, { dispatch, getState }) => {
       
        const tokenString = localStorage.getItem('token')
        const userToken = JSON.parse(tokenString);
        dispatch(gettingClients())
        var myHeaders = new Headers()
        myHeaders.append("Authorization", `Bearer ${userToken}`)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };
        fetch(domainUrl2 + `/clients/`+userID, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.success)
                    dispatch(clientsResponse(result?.data))
                else
                {
                    dispatch(clientsClear())
                    throw  'error'
                }
            })
            .catch(error => {dispatch(clientsClear()); Alert("error","error while loading clients")})
    }
)
const ClientsSlice = createSlice({
    name: 'clients',
    initialState: {
        clients: [],
        loadingClients: false,
    },
    reducers: {
        gettingClients: (state, action) => {
            state.loadingClients = true;
        },
        clientsResponse: (state, action) => {
            state.clients = action.payload;
            state.loadingClients = false;
        },
        clientsClear: (state, action) => {
            state.clients = [];
            state.loadingClients = false;
        },
    },
})
export const {gettingClients, clientsResponse, clientsClear} = ClientsSlice.actions;
export default ClientsSlice.reducer;
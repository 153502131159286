import React from 'react'
import { ProfileCombineComp } from '../../components/ProfileComp/ProfileCombineComp'

const UserProfile = () => {
  return (
    <div>
        <ProfileCombineComp />
    </div>
  )
}

export {UserProfile}
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl} from '../../../constants/apiconstans/domanUrl'
import { checkAuthorization } from '../../../helpers'

export const getMyCollectionByArtist = createAsyncThunk('artistCollection',
    async (obj, { dispatch, getState }) => {

        dispatch(gettingArtistCollection())
        const {userToken, key}= checkAuthorization()
        var myHeaders = new Headers()
        myHeaders.append("content-type", `application/json`)
        myHeaders.append("key", key)
        myHeaders.append("token", userToken)
        myHeaders.append("pageNo", obj?.pageNo)
        myHeaders.append("recordsPerPage", obj?.pageSize)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(obj?.data)
        }
        fetch(domainUrl + `/getMyCollectionByArtist`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode===1)
                {
                    
                    dispatch(artistCollectionResponse(result?.data))
                    dispatch(setPages({pageNo: obj?.pageNo, pageSize: obj?.pageSize, totalRecords: result?.totalRecords}))
                }
                else
                    throw result?.ResponseMsg
            })
            .catch(error => {
                dispatch(artistCollectionClear())
                console.error(error) 
            })
    }
)

const artistCollectionSlice = createSlice({
    name: 'artistCollection',
    initialState: {
        data: null,
        loading: false,
        pagination:{
            pageNo:1,
            pageSize: 6,
            totalRecords:0,
        },
    },
    reducers: {
        gettingArtistCollection: (state) => {
            state.loading = true;
        },
        artistCollectionResponse: (state, action) => {
            state.data = action.payload;
            state.loading= false;
        },
        artistCollectionClear: (state) => {
            state.data = null;
            state.loading = false;
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        }
    },
})

export const {
    gettingArtistCollection, 
    artistCollectionResponse, 
    artistCollectionClear, 
    setPages,
} = artistCollectionSlice.actions
export default artistCollectionSlice.reducer
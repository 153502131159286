const isUnAuthorize= ()=>{
    const userToken = localStorage.getItem('token')
    const user_id= localStorage?.getItem("userId")
    if(!userToken || !user_id)
        return 1
    return 0
}
const checkAuthorization= ()=>{
    const userToken = localStorage.getItem('token')
    const user_id= localStorage?.getItem("userId") 
    const key= "2b223e5cee713616hs54ac203b24e9a123703VT001"
    if(!userToken || !user_id || !key)
    {
        localStorage.clear()
        window.location.href='/'
    }
    return ({userToken, user_id, key})
}
const checkAuthorizationWithoutLogin= ()=>{
    const userToken = localStorage.getItem('token')
    const user_id= localStorage?.getItem("userId") 
    const key= "2b223e5cee713616hs54ac203b24e9a123703VT001"
    
    return ({userToken: userToken || null , user_id: user_id || null, key})
}
const getUserID= ()=>{
    const user_id= localStorage?.getItem("userId") 
    return (user_id || null)
}
export {isUnAuthorize, checkAuthorization, checkAuthorizationWithoutLogin, getUserID}
import { domainUrl } from "../../constants/apiconstans/domanUrl"
import { Alert } from "../../../components"
export const getMyCollection = (store_id)=>{
    const userToken = localStorage.getItem('token')
    let user_id= localStorage?.getItem("userId")
    var myHeaders = new Headers()
    myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
    myHeaders.append("token", userToken || null)
    let formData = new FormData()
    formData.append(`user_id`,user_id || null)
    formData.append(`store_id`,store_id || null)
    myHeaders.append("pageNo", 1)
    myHeaders.append("recordsPerPage", 1000)
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: formData
    };
   return (
        fetch(domainUrl + `/getMycollection`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result)
                return result
            else
            {
                Alert("error", "error while getting Collection items") 
            }
        })
        .catch(error => {
            Alert("error", error) 
            return 0
        })
   )
}
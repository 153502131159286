import {Modal, Typography, Space, Avatar} from 'antd';
import {useEffect } from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { actionsApi} from '../../shared';
import { Loader} from '../';
import { NFMediaPlayer } from '../../pages';
import { LikeSection, CommentSection } from '../'
import './index.css'
import { useTranslation } from 'react-i18next';
const { Title, Text} = Typography;

const ViewPost = ({visible, post, feed, isPost, onClose}) => {
    const {t}= useTranslation()
    const dispatch= useDispatch()
    const {data}= useSelector( state => state?.artistProfile)
    const {data : postComments, loading}= useSelector(state => state?.postComments)
    const {data : feedComments}= useSelector(state => state?.feedComments)
    useEffect(()=>{
        if(post && isPost)
            dispatch(actionsApi?.getPostComments(post?.id))
        else if(post)
            {
                let user_id= localStorage?.getItem("userId")
                let formData = new FormData()
                formData.append(`user_id`, user_id || null)
                formData.append(`feed_id`, post?.feed_id || null)
                dispatch(actionsApi?.getFeedComments(formData))
            }
    }, [post, isPost])
    useEffect(()=>{
        return (
            ()=>{
                dispatch(actionsApi?.feedCommentsClear())
            }
        )
    }, [])
  return (
    <div>
        <Modal
            title={<Title level={4} className='my-0'>{data?.name || 'Admin'}'s {t("Post")}</Title>} 
            centered
            width={700}
            visible={visible}
            onOk={onClose}
            onCancel={onClose}
            footer={
                <CommentSection
                    feed={feed}
                    isPost={isPost}
                    />
            }
        >
            <Space direction='vertical' className='w-100'>    
                    {
                        isPost &&
                        <Space>
                            <Avatar 
                                shape="cicle" 
                                size="large" 
                                src={data?.image || 'assets/avatar.png'} 
                                alt='nfsounds'
                                />
                            <Space direction='vertical'>
                                <Title level={5} className='my-0'>
                                    {
                                        data?.name 
                                    }
                                </Title>
                            </Space>
                        </Space>
                    }
                    <Text strong>
                        {
                            post?.description
                        }
                    </Text> 
                    <div  
                        className={(post?.feed_type==='video' ? 'feeds-media-auto-height' : 'feeds-media')+' center inner-shading'}
                        style={post?.feed_type==='video' ? {}:{backgroundImage:`url('${post?.image}')`}}
                        >
                        {
                            post?.feed_type==='video' ?
                            <div 
                                className={post?.feed_type==='video' ? 'w-100':'feeds-video'}
                                >
                                <NFMediaPlayer 
                                    src={post?.image} 
                                    controls={true}
                                    />
                            </div>:''
                        } 
                    </div>
                    <LikeSection feed={feed} isPost={isPost} data={null}/>
                    {
                        loading?
                        <Loader/>
                        :
                        <Space direction='vertical'>
                            {
                                [...postComments?.length? postComments: feedComments]?.map((comment, index)=>
                                    <Space key={'comment-'+index} className='align-top' style={{alignItems:'flex-start'}}>
                                        <Avatar 
                                            shape="cicle"
                                            size={40}
                                            src={(comment?.user_image || comment?.profile) || '/assets/avatar.png'} 
                                            alt='nfsounds'
                                            />
                                        <Space 
                                            direction='vertical' 
                                            size={0}
                                            className='light-grey-bg px-2 py-1 radius-6'
                                            >
                                            <Title level={5} className='my-0'>
                                                {
                                                    comment?.user_name || (comment?.user?.username)
                                                }
                                            </Title>
                                            <p className='my-0'>
                                                {
                                                    comment?.comment
                                                }
                                            </p>
                                            <small>
                                                {
                                                    comment?.datetime + " ago"
                                                }
                                            </small>
                                        </Space>
                                    </Space>
                                )
                            }
                        </Space>
                    } 
            </Space>
        </Modal>
    </div>
  )
}

export {ViewPost}
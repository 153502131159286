import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl';
import { Alert } from '../../../../components';
export const getUserSellPurchaseLog = createAsyncThunk('userSalePurchaseLog',
    async (data, { dispatch, getState }) => {
        const tokenString = localStorage.getItem('token')
        dispatch(gettingUserSalePurchaseLog())
        var myHeaders = new Headers();
        myHeaders.append("token", `${tokenString}`)
        myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
        myHeaders.append("Content-Type", "application/json")
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(data)
        }
        fetch(domainUrl + `/sellPurchaseLog`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode)
                    dispatch(userSalePurchaseLogResponse(result?.Purchase))
                else
                {
                    dispatch(userSalePurchaseLogClear())
                    Alert("error", result?.message) 
                }
            })
            .catch(error => {
                dispatch(userSalePurchaseLogClear())
                Alert("error","error while loading userSalePurchaseLog") 
            })
    }
)

const UserSalePurchaseLogSlice = createSlice({
    name: 'userSalePurchaseLog',
    initialState: {
        data: [],
        loading: false
    },
    reducers: {
        gettingUserSalePurchaseLog: (state, action) => {
            state.loading = true;
        },
        userSalePurchaseLogResponse: (state, action) => {
            state.data = action.payload;
            state.loading= false;
        },
        userSalePurchaseLogClear: (state, action) => {
            state.data = []
            state.loading = false
        },
    },
})

export const {gettingUserSalePurchaseLog, userSalePurchaseLogResponse, userSalePurchaseLogClear} = UserSalePurchaseLogSlice.actions
export default UserSalePurchaseLogSlice.reducer
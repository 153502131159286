import {Form, Checkbox} from 'antd';
export const NFCheckbox = ({name, label, size, disabled, required, message, ...props}) => {
  return (
    <Form.Item
        name={name}
        rules={[
          {
            required,
            validator: (_, value) =>
              {
                return value ?  Promise.resolve() : Promise.reject(new Error(message))
              }
          },
        ]}
        valuePropName="checked" 
        >
            <Checkbox 
                size={size || 'middle'}
                disabled={disabled || false}
                {...props}
                >
                {
                  label
                }
            </Checkbox>
    </Form.Item>  
  )
}
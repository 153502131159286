import { Row, Col, Tabs} from 'antd'
import SettingsForm from './SettingsForm'
import SecurityForm from './SecurityForm'
import ActivityTable from './ActivityTable'
import MySubscription from './MySubscription'
import Favorites from './Favorites'

const ProfileRightTabBlock = () => {
    const items = [
            {
                label:  "Favorites",
                key:0,
                children: <Favorites/>
            },
            {
                label:  "Settings",
                key:1,
                children:<SettingsForm />
            },
            {
                label:  "Security",
                key:2,
                children:<SecurityForm />
            },
            {
                label:  "Activity",
                key:3,
                children:<ActivityTable />
            },
            {
                label:  "My subscriptions",
                key:4,
                children:<MySubscription />
            },
        ]
  return (
    <div className='user-profile-right'>
        <Row className=''>
            <Col span={24} className='pt-5 user-profile'>
                <Tabs type="card" items={items} />
            </Col>
        </Row>
    </div>
  )
}

export default ProfileRightTabBlock
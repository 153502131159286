import React from 'react'
import { Col, Row, Table, Typography } from 'antd'
import './profile.css'
import { useEffect } from 'react'
import { useSelector, useDispatch} from 'react-redux'
import { LLFormatWithTime, actionsApi } from '../../shared'
const { Text } = Typography

const ActivityTable = () => {
    const dispatch= useDispatch()
    const {data, loading}= useSelector(state => state?.userSalePurchaseLog)
    useEffect(()=>{
        let user_id= localStorage?.getItem("userId")
        dispatch(actionsApi?.getUserSellPurchaseLog({user_id}))
    }, [])
    const columns = [
        {
            title: 'Sale / Purchase',
            dataIndex: 'salep',
            key: 'store_id',
            render: ()=> 'Purchase'
        },
        {
            title: 'Item',
            dataIndex: 'storeName',
            key: 'store_id',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'store_id',
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'store_id',
            render: col => LLFormatWithTime(col)
        },
      ]

  return (
    <div>
        <Row>
            <Col span={24}>
                <Table 
                    className='tablebg'
                    columns={columns} 
                    dataSource={data} 
                    scroll={{
                        x:700
                    }}
                />
            </Col>
        </Row>
    </div>
  )
}

export default ActivityTable
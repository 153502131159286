import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl';
import { Alert } from '../../../../components';
export const getUserSubscriptions = createAsyncThunk('userSubscriptions',
    async (data, { dispatch, getState }) => {
        const tokenString = localStorage.getItem('token')
        dispatch(gettingUserSubscriptions())
        var myHeaders = new Headers();
        myHeaders.append("token", `${tokenString}`)
        myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
        myHeaders.append("Content-Type", "application/json")
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(data)
        }
        fetch(domainUrl + `/getSubArtist`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode)
                    dispatch(userSubscriptionsResponse(result?.data))
                else
                {
                    dispatch(userSubscriptionsClear())
                    Alert("error", result?.message) 
                }
            })
            .catch(error => {
                dispatch(userSubscriptionsClear())
                Alert("error","error while loading user subscriptions") 
            })
    }
)

const UserSubscriptionsSlice = createSlice({
    name: 'userSubscriptions',
    initialState: {
        data: [],
        loading: false
    },
    reducers: {
        gettingUserSubscriptions: (state, action) => {
            state.loading = true;
        },
        userSubscriptionsResponse: (state, action) => {
            state.data = action.payload;
            state.loading= false;
        },
        userSubscriptionsClear: (state, action) => {
            state.data = []
            state.loading = false
        },
    },
})

export const {gettingUserSubscriptions, userSubscriptionsResponse, userSubscriptionsClear} = UserSubscriptionsSlice.actions
export default UserSubscriptionsSlice.reducer
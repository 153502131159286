import React from 'react'
import { useSelector } from 'react-redux'
import { DropingComp, StoreFirstComp, StoreComp, BoosttaCollection, AboutUs} from '../../components/storecomp'
const Store= () => {
  console.log('dd:',useSelector(state => state?.itemDetail))
  return (
    <>
      <StoreFirstComp />
      <DropingComp />
      <StoreComp />
      <BoosttaCollection/>
      <AboutUs/>
    </>
  )
}

export {Store}
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {Space, Typography, Row, Col, Pagination } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { actionsApi, updateFavorite} from '../../shared'
import { ItemCard } from '..'
import Slider from 'react-slick'
const { Title} = Typography

export const DropingComp = () => {
    const {t}= useTranslation()
    const dispatch= useDispatch()
    const {data, pagination}= useSelector(state => state?.droppingSoonItems)
    useEffect(()=>{
        getAllDroppingSoongItems()
    },[])
    const getAllDroppingSoongItems= (pageNo=1, pageSize=6)=>{
        dispatch(actionsApi?.getAllDroppingSoonItems({pageNo, pageSize}))
    }
    const UpdateFavorite=async (store_id, is_Favourite)=>{
        let user_id= localStorage?.getItem("userId")
        if(!user_id)
            return
        dispatch(actionsApi?.droppingSoonItemsResponse([...data?.map(_=>{
            if(parseInt(_?.store_id)===parseInt(store_id))
            return (
                {
                    ..._,
                    is_Favourite:is_Favourite?'1':'0'
                }
            )
            return _
        })]))
        let formData = new FormData()
        formData.append(`user_id`, user_id)
        formData.append(`store_id`, store_id)
        await updateFavorite(formData)    
    }
    var businesspartner = {
        dots: false,
        infinite: data?.length>3,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        autoplay:false,
        responsive: [
          {
            breakpoint: 1150,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 1068,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 676,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
        ]
      }
  return (
    <>
    {
        data?.length> 0 &&
        <div className='justify-center bg-white'>
            <Space 
                className='width-96 py-5 border-top-black' 
                direction='vertical' 
                size={15}
                >
                <Title level={1}>
                    {
                        t('DROPPING SOON.')
                    }
                </Title>
                <div className='width-100'>
                    <Slider 
                      {...businesspartner} 
                      className='dropping-soon-slider'
                      >
                        {
                            data?.map((dat,dt)=>
                            <div
                                key={'dropping soon-'+dt}
                                className='px-3'
                            >
                            <ItemCard
                                droppingSoon
                                data={{store_dtl:dat}}
                                to={'/item-detail/'+dat?.store_id}
                                UpdateFavorite={UpdateFavorite}
                                />
                            </div>
                        )}
                    </Slider>
                </div>
                {/* <Row gutter={[32,16]}>
                    {
                        data?.map((dat,dt)=>
                            <Col xs={24} sm={12} md={12} lg={8} key={dt}>
                                <ItemCard
                                    droppingSoon
                                    data={{store_dtl:dat}}
                                    to={'/item-detail/'+dat?.store_id}
                                    UpdateFavorite={UpdateFavorite}
                                    />
                            </Col>
                        )
                    }
                </Row> */}
                {
                    pagination?.totalRecords>6 &&
                    <div className='center'>
                        <Pagination
                            total={pagination?.totalRecords} 
                            defaultPageSize={pagination?.pageSize} 
                            current={pagination?.pageNo}
                            onChange={(pageNo, pageSize)=>getAllDroppingSoongItems(pageNo, pageSize)}
                            />
                    </div>
                }
            </Space>
        </div>
    }
    </>
  )
}
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl} from '../../../constants/apiconstans/domanUrl';
import { Alert } from '../../../../components';
import { utcToLocal } from '../../../helpers';
export const getItemDetail = createAsyncThunk('ItemDetail',
    async (itemID, { dispatch, getState }) => {
        dispatch(gettingItemDetail())
        let user_id= localStorage?.getItem("userId")
        const userToken = localStorage.getItem('token')
        var myHeaders = new Headers()
        myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
        myHeaders.append("token", userToken)
        let formData = new FormData()
        formData.append(`user_id`, user_id || null)
        formData.append(`store_id`, itemID || null)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: formData
        }
        fetch(domainUrl + `/getStoresDetails`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode)
                    dispatch(itemDetailResponse({
                        ...result?.data, 
                        drop_date: utcToLocal(result?.data?.drop_date),
                        benefits: JSON.parse(result?.data?.benefits)
                    }))
                else
                {
                    dispatch(itemDetailClear())
                    Alert("error", "error while getting Item Detail") 
                }
            })
            .catch(error => {
                dispatch(itemDetailClear())
                Alert("error", error) 
            })
    }
)

const itemDetailSlice = createSlice({
    name: 'itemDetail',
    initialState: {
        data: [],
        loading: false
    },
    reducers: {
        gettingItemDetail: (state, action) => {
            state.loading = true;
        },
        itemDetailResponse: (state, action) => {
            state.data = action.payload;
            state.loading= false;
        },
        itemDetailClear: (state, action) => {
            state.data = [];
            state.loading = false;
        },
    },
})

export const {gettingItemDetail, itemDetailResponse, itemDetailClear} = itemDetailSlice.actions
export default itemDetailSlice.reducer
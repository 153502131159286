import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl} from '../../../constants/apiconstans/domanUrl'
import { checkAuthorization} from '../../../helpers'

export const getfavoriteItems = createAsyncThunk('favoriteItems',
    async (data, { dispatch, getState}) => {
        const {userToken, user_id, key}= checkAuthorization()
        dispatch(gettingFavoriteItems())
        var myHeaders = new Headers();
        myHeaders.append("key", key)
        myHeaders.append("token", userToken)
        myHeaders.append("pageNo", data?.pageNo)
        myHeaders.append("recordsPerPage", data?.pageSize)

        let formData = new FormData()
        formData.append(`user_id`,user_id)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: formData
        };
        fetch(domainUrl + `/myFavoriteList`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.data)
                    {
                        dispatch(favoriteItemsResponse(result?.data))
                        dispatch(setPages({pageNo: data?.pageNo, pageSize: data?.pageSize, totalRecords: result?.totalRecords}))
                    }
                else
                    throw result?.message
            })
            .catch(error => {
                dispatch(favoriteItemsClear())
                console.error(error) 
            })
    }
)

const FavoriteItemsSlice = createSlice({
    name: 'favoriteItems',
    initialState: {
        data: [],
        pagination:{
            pageNo:1,
            pageSize: 6,
            totalRecords:0,
        },
        loading: false
    },
    reducers: {
        gettingFavoriteItems: (state, action) => {
            state.loading = true;
        },
        favoriteItemsResponse: (state, action) => {
            state.data = action.payload;
            state.loading= false;
        },
        favoriteItemsClear: (state, action) => {
            state.data = [];
            state.loading = false;
        },
        setPages:(state, action)=>{
            state.pagination=action.payload
        },
    },
})

export const {gettingFavoriteItems, favoriteItemsResponse, favoriteItemsClear, setPages} = FavoriteItemsSlice.actions
export default FavoriteItemsSlice.reducer
import { Col, Row, Typography, Space, Form, Button} from "antd"
import { NFInput } from "../../components"
import {forgotPassword} from "../../shared"
import { useState } from "react"
const {Title} = Typography

const ForgotPassword = () =>{
    const [form] = Form.useForm()
    const [loading, setLoading]= useState(false)
    const forgot= async ()=>{
        setLoading(true)
        let data= form.getFieldsValue()
        let formData = new FormData()
        formData.append(`email`,data?.email)
        await forgotPassword(formData)
        setLoading(false)
        form.setFieldsValue({email:''})
    }
    return(
        <div className="justify-center py-5 page-bg">
            <div className="width93">
                <Row gutter={[32, 8]}>
                    <Col span={24}>
                        <Title level={2}>Forgot?</Title>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[16,10]} className='justify-center'>
                            <Col xs={24} sm={24} md={14} lg={7}>
                                <Space direction="vertical" className="w-100 justify-center">
                                    <Space direction="vertical" size={3} className='w-100 text-center'>
                                        <Title level={2} className='m-0'>Forgot your password?</Title>
                                        <Title level={5}  className='m-0'>Enter your email address and we'll send you an email with instructions to reset your password.</Title>
                                    </Space>
                                    <Form
                                        form={form}
                                        layout="vertical"
                                        initialValues={true}
                                        onFinish={forgot}
                                        >
                                        <Row gutter={[16,16]}>
                                            <Col span={24}>
                                                <NFInput
                                                    name='email'
                                                    type='email'
                                                    label='Email'
                                                    size='large'
                                                    required
                                                    message='Please enter email' 
                                                    placeholder='user@nfsounds.com' 
                                                    value={form.getFieldValue('email')}
                                                    />
                                            </Col>
                                            <Col span={24}>
                                                <Button 
                                                    type="primary" 
                                                    htmlType="submit"
                                                    size="large"
                                                    block
                                                    loading={loading}
                                                    >
                                                    Send
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export {ForgotPassword}
import { useRef, useEffect, useState } from "react"
import { useSelector, useDispatch} from "react-redux"
import { useTranslation } from "react-i18next"
import {Modal, Typography, Form, Row, Col, Button} from "antd"
import { NFSelect } from "../../Form"
import { actionsApi, order } from "../../../shared"

const {Title}= Typography

const SwapModal=({visible, track_id, playlist_type, length, onClose})=>{

    const {t}= useTranslation()
    const dispatch= useDispatch()
    const {data : playlists}= useSelector(state => state?.playlists)
    const [form] = Form.useForm()
    const btnRef = useRef() 
    const [options, setOptions]= useState([])
    const [loading, setLoading]= useState(false)
    const selectFile = () => {
        btnRef.current.click()
    }
    useEffect(()=>{
        if(playlists?.length)
            setOptions(playlists?.map(_=>({id:_, name:_})))
    }, [playlists])
    const save= async ()=>{
        setLoading(true)
        let user_id= localStorage?.getItem("userId")
        let data=form.getFieldsValue()
        let formData = new FormData()
        formData.append(`user_id`, user_id)
        formData.append(`track_id`, track_id)
        formData.append(`playlist_type`, playlist_type)
        formData.append(`order`, data?.order)
        let result=await order(formData)
        if(result)
        {
            dispatch(actionsApi?.getTracklist(playlist_type))
            onClose()
        }
        setLoading(false)
    }
    return (
        <Modal
            visible={visible} 
            title={<Title className="my-0" level={4}>{t("Swap")}</Title>} 
            footer={
                <Button 
                    type="primary" 
                    onClick={selectFile}
                    loading={loading}
                    >
                    {t("Swap")}
                </Button>
            } 
            onCancel={onClose}
            centered
            >
                <Form 
                    layout="vertical"
                    onFinish={save} 
                    form={form}
                    initialValues={{
                        remember: true,
                    }}
                    >
                    <Row gutter={16}>
                        <Col span={24}>
                            <NFSelect
                                label= {t('Select order number')}
                                required
                                message= {t('please select order number')}
                                size='large'
                                name='order'
                                value={form.getFieldValue('order') || ''}
                                options={[...new Array(length || 0)?.fill(null)?.map((_,index)=> ({id:index+1, name:index+1}))]}
                                placeholder='e.g 2'
                                />
                        </Col>
                        <Button 
                            htmlType="submit" 
                            ref={btnRef}
                            style={{display:'none'}}
                            >
                            send
                        </Button>
                    </Row>
                </Form>
        </Modal>
    )
}
export {SwapModal}
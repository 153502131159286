import { useState, useEffect} from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector} from "react-redux"
import {Card, Avatar, Button, Space, Image, Typography, message} from "antd"
import {useNavigate } from "react-router-dom"
import {actionsApi, beforeAddToCart, isUnAuthorize, dateWithTime, getUserID} from "../../shared"
import Countdown from "react-countdown"
import { Cart} from "../"
import "./index.css"
const {Title}= Typography

const  ItemCard=({droppingSoon, store, collection, marketPlace,data, to, userID, UpdateFavorite})=>{
    const {t}= useTranslation()
    const dispatch= useDispatch()
    // const {data: droppingSoonList}= useSelector(state => state?.droppingSoonItems)
    const {data: storeItemList, filters, pagination} = useSelector(state => state?.storeItems)
    const navigate= useNavigate()
    const [ visibleCart, setVisibleCart ] = useState(false)
    const [loading, setLoading]= useState(false)
    const [disableFavorite, setDisableFavorite]= useState(false)
console.log('store:', storeItemList)
    useEffect(()=>{
        if(isUnAuthorize())
            setDisableFavorite(true)
    }, [])
    const renderer = ({days, hours, minutes, seconds, completed }) => {
        if (completed) {
            moveItem()
            return '00:00:00'
        }
        else 
          return <span>{days}D {hours}H {minutes}M {seconds}S</span>
      }
      function moveItem(){
        return
        if(pagination?.pageNo===1 && storeItemList?.length<pagination?.pageSize)
            dispatch(actionsApi?.storeResponse([data?.store_dtl,...storeItemList]))
        else
        {
            let formData = new FormData()
            formData.append(`user_id`, getUserID())
            for (const property in filters) 
                {
                    if(filters[property]===0 || filters[property]===null)
                        continue
                    formData.append(property, filters[property])
                }
            dispatch(actionsApi?.getAllStoreItems({formData, ...pagination, pageNo:1}))
        }
        //dispatch(actionsApi?.getAllDroppingSoonItems({pageNo:1, pageSize:3}))
        // dispatch(actionsApi?.droppingSoonItemsResponse([...droppingSoonList?.filter(_=>parseInt(_?.store_id)!==parseInt(data?.store_dtl?.store_id))]))
      }
    return (
        <>
        <div  className='text-white' onClick={()=>{navigate(to, { replace: true })}}>
            <Card className='cardnf border0 cardby'>
                <Space size={15} direction='vertical position-relative w-100 gapmbl'>
                    <div 
                        className="item-image-cover "
                        >
                        <div className="inner-shading"
                            style={{background: `url('${data?.store_dtl?.type==='video'?data?.store_dtl?.thumbnail:data?.store_dtl?.image}')`}}
                            >
                        </div>
                    </div>
                    <div className='heart'>
                        <Button
                            shape='circle'
                            onClick={(e)=>{
                                e.stopPropagation()
                                UpdateFavorite(data?.store_dtl?.store_id, !parseInt(data?.store_dtl?.is_Favourite))
                            }}
                            disabled={disableFavorite}
                            >
                            {
                                parseInt(data?.store_dtl?.is_Favourite)?
                                <Image 
                                    src="/assets/icons/heart-red-50.png" 
                                    alt="nf-sounds" 
                                    width={'20px'}
                                    preview={false}
                                    />:
                                <Image 
                                    src="/assets/icons/heart-black.png" 
                                    alt="nf-sounds" 
                                    width={'20px'}
                                    preview={false}
                                    />
                            }
                        </Button>
                    </div>
                    <div className='space-between-center'>
                        <Space 
                            className={parseFloat(data?.store_dtl?.artist_dtl?.subcribtion_fee)>0?'cursor-pointer':''}
                            onClick={(e)=>{
                                e.stopPropagation(); 
                                if(parseFloat(data?.store_dtl?.artist_dtl?.subcribtion_fee)>0)
                                    navigate(`/artist/${data?.store_dtl?.artist_dtl?.artist_id}`)
                            }}
                            >
                            <div className='inner-shading-avatar' style={{width:'52px'}}>
                                <Avatar 
                                    size={52} 
                                    shape='square' 
                                    src={data?.store_dtl?.artist_dtl?.image} 
                                    alt='nf sounds'
                                    className="inner-shading"
                                    />
                                <div></div>
                            </div>
                            <Title 
                                className='my-0 text-white' 
                                level={5}
                                >
                                {
                                    data?.store_dtl?.artist_dtl?.name
                                }
                            </Title>
                        </Space>
                        <Button >
                            {
                                (data?.amount || data?.store_dtl?.listing_price)+' €'
                            }
                        </Button>
                    </div>
                    <Title 
                        level={4} 
                        className='my-0 text-white w-100'
                        ellipsis
                        >
                        {
                            data?.store_dtl?.store_name
                        }
                    </Title>
                    <div className='width-100' style={{padding:"10px 5px",borderTop:"1px solid #919191"}}>
                        <div className='space-between'>
                            <Button 
                                className={
                                    (data?.store_dtl?.rarity==='Diamond'?'diamond-button':
                                    data?.store_dtl?.rarity==='Platinum'?'platinum-button':
                                    "gold-button")+ " custom-btn"
                                }
                                >
                                {
                                    t(data?.store_dtl?.rarity)
                                }
                            </Button>
                            {
                                droppingSoon?
                                <Button
                                    type="primary"
                                    style={{width:'120px'}}
                                    >
                                    <Countdown
                                        date={dateWithTime(data?.store_dtl?.drop_date)}
                                        renderer={renderer}
                                        />
                                </Button>:
                                store ?
                                <Button 
                                    type="primary"
                                    loading={loading}
                                    onClick={async (e)=>{
                                        e.stopPropagation()
                                        if(!data?.store_dtl?.available_stock)
                                            return
                                        setLoading(true)
                                        let result= await beforeAddToCart(data?.store_dtl?.store_id)
                                        setLoading(false)
                                        if(result)
                                        {
                                            message.success(result)
                                            setVisibleCart(true)
                                        }
                                    }} 
                                    >
                                    {
                                        !data?.store_dtl?.available_stock ? t("SOLD OUT") :
                                        t("BUY NOW")
                                    }
                                </Button>:
                                collection?
                                <Button
                                    type="primary"
                                    >
                                    {data?.store_dtl?.quantityOwned} / {data?.store_dtl?.available_stock + data?.store_dtl?.sold_stock}
                                </Button>:
                                <Button    
                                    type='primary'
                                    danger={data?.user_id===userID}
                                    >
                                        {
                                            data?.user_id===userID ? t('Remove') : t('BUY NOW')
                                        }
                                </Button>
                            }
                        </div>
                    </div>
                </Space>
            </Card>
        </div>
        <Cart 
            visible={visibleCart} 
            onClose={(e)=>{
                e.stopPropagation()
                setVisibleCart(false)
            }} 
            />
        </>
    )
}
export {ItemCard}
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl'

export const getUserProfile = createAsyncThunk('userProfile', 
    async (data, { dispatch, getState }) => {
        const tokenString = localStorage.getItem('token')
        dispatch(gettingUserProfile())
        var myHeaders = new Headers();
        myHeaders.append("token", `${tokenString}`)
        myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
        
        let formData = new FormData()
        let user_id= localStorage?.getItem("userId")
        formData.append(`user_id`, user_id || null)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: formData
        }
        fetch(domainUrl + `/myProfile`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode)
                    dispatch(userProfileResponse(result?.data))
                else
                    throw result?.ResponseMsg
            })
            .catch(error => {
                dispatch(userProfileClear())
                // localStorage.clear()
                // window.location.href='/'
            })
    }
)

const UserProfileSlice = createSlice({
    name: 'userProfile',
    initialState: {
        data: null,
        loading: false
    },
    reducers: {
        gettingUserProfile: (state, action) => {
            state.loading = true
        },
        userProfileResponse: (state, action) => {
            state.data = action.payload
            state.loading= false
        },
        userProfileClear: (state, action) => {
            state.data = null
            state.loading = false
        },
    },
})

export const {gettingUserProfile, userProfileResponse,userProfileClear} = UserProfileSlice.actions
export default UserProfileSlice.reducer
import {useEffect} from 'react'
import {Typography, Button, Space, Avatar } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import './profile.css'
import './index.css'
import {actionsApi, LLFormat, cancelSubscription} from '../../shared'

const { Text } = Typography

const MySubscription = () => {
    const dispatch= useDispatch()
    const {data}= useSelector(state => state?.userSubscriptions)
    useEffect(()=>{
        let user_id= localStorage?.getItem("userId")
        dispatch(actionsApi?.getUserSubscriptions({user_id}))
    }, [])
    const cancelSubs= async (subscriptionID)=>{
        let result= await cancelSubscription(subscriptionID)
        if(result)
            dispatch(actionsApi?.userSubscriptionsResponse([...data?.filter(subscription=>subscription?.subId!==subscriptionID)]))
    }
  return (
    <div style={{overflowX:'auto'}} className='px-2'>
        <table className='subscriptions-table tableResponsive'>
            <thead>
                <tr>
                    <td>
                        <Text strong>Artist</Text>
                    </td>
                    <td>
                        <Text strong>Price</Text>
                    </td>
                    <td>
                        <Text strong>Renewal date</Text>
                    </td>
                    <td width={230}></td>
                </tr>
            </thead>
            <tbody>
                {
                    data?.map((row, index)=>
                    <tr key={'subs-row-'+index}>
                        <td>
                            <Space>
                                <div className='inner-shading-avatar' style={{width:'60px'}}>
                                    <Avatar 
                                        src={row?.artistImage} 
                                        size={60} 
                                        alt='nf-sounds' 
                                        shape='square'
                                        />
                                    <div></div>
                                </div>
                                <Text strong className='text-white'>
                                    {
                                        row?.artistName
                                    }
                                </Text>
                            </Space>
                        </td>
                        <td>
                            <Text strong className='text-white'>
                                {
                                    row?.ArtistFee + ' €'
                                }
                            </Text>
                        </td>
                        <td>
                            <Text strong className='text-white'>
                                {
                                    LLFormat(row?.renewalDate)
                                }
                            </Text>
                        </td>
                        <td>
                            <Button 
                                type='primary' 
                                size='large' 
                                danger
                                onClick={()=>{cancelSubs(row?.subId)}}
                                >
                                Cancel the subscription
                            </Button>
                        </td>
                    </tr>
                    )
                }
            </tbody>
        </table>
    </div>
  )
}

export default MySubscription
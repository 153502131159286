import React,{ useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Space, Row, Col, Typography, Button} from 'antd'
import { useSelector } from 'react-redux'
import { LLFormatWithTime, dateWithTime } from '../../shared'
import { getMyCollection, RemoveItemFromSale, beforeAddToCart, renderer} from '../../shared'
import { QuantityCounter } from '../'
import { SellItemModal } from './SellItemModal'
import { BuyItemModal } from './BuyItemModal'
import Countdown from 'react-countdown'
import { useTranslation } from 'react-i18next'
const { Title, Text } = Typography

const RightDetails2 = ({isCollectionItem, isMarketPlaceItem, marketPlaceItem: marketPlace}) => {

    const {t}= useTranslation()
    let navigate = useNavigate();
    const {data}= useSelector(state => state?.itemDetail)
    const [droppingSoon, setDroppingSoon]= useState(false)
    const [timer, setTimer]= useState(null)
    const [quantity, setQuantity]= useState(1)
    const [marketPlaceQuantity, setMarketPlaceQuantity]= useState(null)
    const [visibleSellItemModal, setVisibleSellItemModal]= useState(false)
    const [visibleBuyItemModal, setVisibleBuyItemModal]= useState(false)
    const [userID, setUserID]= useState(null)
    const [marketPlaceItem, setMarketPlaceItem]= useState(null)
    const [loading, setLoading]= useState(false)

    useEffect(()=>{
        let user_id= localStorage?.getItem("userId")
        setUserID(user_id)
    }, [])
    useEffect(()=>{
        if(marketPlace?.length)
            setMarketPlaceItem(marketPlace[0])
    }, [marketPlace])
    useEffect(()=>{
        if(data)
            if(new Date(data?.drop_date).getTime()> new Date().getTime())
                {
                    setDroppingSoon(true)
                    setInterval(()=>{
                        setTimer(new Date(data?.drop_date).getTime()- new Date().getTime())
                    }, 1000)
                }
        if(data && isCollectionItem)
            getDetail(data?.store_id)
    },[data, isCollectionItem])

    const getDetail= async()=>{
        let result=await getMyCollection(data?.store_id)
        let add=0;
        for(let i=0;i<result?.collections?.length;i++)
        {
            add+=result?.collections[i]?.quantity
            
        }
        if(result?.collections?.length)
        {
            if(result?.collections[0]?.market_place?.length)
            {
                add= add-result?.collections[0]?.market_place?.length
                setMarketPlaceQuantity(result?.collections[0]?.market_place?.length)
            }
        }
        setQuantity(add)
    }
    const removeItem= async ()=>{
        setLoading(true)
        let formData = new FormData()
        formData.append(`user_id`, userID)
        formData.append(`marketplace_id`, marketPlaceItem?.marketplace_id)
        formData.append(`amount`, marketPlaceItem?.amount)
        formData.append(`type`, marketPlaceItem?.type)
        let result= await RemoveItemFromSale(formData)
        setLoading(false)
        if(result)
            navigate("/marketplace", { replace: true })
    }
    const upDown= (_, countUp=true)=>{
        setQuantity(countUp?(quantity+1):(quantity-1))
    }
    const buyNow=async ()=>{
        if(!data?.available_stock)
            return
        setLoading(true)
        await beforeAddToCart(data?.store_id, quantity)
        setLoading(false)
        navigate(`/cart`, { replace: true })
    }

  return (
        <Space 
            className='width-100 h-100'
            direction='vertical' 
            >
            <div>
                <div className='space-between px-1'>
                    <Title level={2} className='my-0'>{data?.artist_dtl?.name}</Title>
                    {
                        isCollectionItem &&
                        <div style={{background:'white', height:'30px'}} className='radius-3 px-3 align-center'>
                            <Text strong>{quantity}/{data?.available_stock+ data?.sold_stock}</Text>
                        </div>
                    }
                </div>
                <Row className='borderbottom py-3 px-1'>
                        <Col span={10}>
                            <Title level={5} className='light-font-dim my-0'>{t("Edition")}</Title>
                        </Col>
                        <Col span={14}>
                            <Title level={5} className='my-0'>{data?.edition}</Title>
                        </Col>
                </Row>
                <Row className='borderbottom py-3 px-1'>
                        <Col span={10}>
                            <Title level={5} className='light-font-dim my-0'>{t("NFT Version")}</Title>
                        </Col>
                        <Col span={14}>
                            <Title level={5} className='my-0'>{data?.nft_version}</Title>
                        </Col>
                </Row>
                <Row className='borderbottom py-3 px-1'>
                        <Col span={10}>
                            <Title level={5} className='light-font-dim my-0'>{t("Type")}</Title>
                        </Col>
                        <Col span={14}>
                            <Title level={5} className='my-0'>{data?.type_dtl?.name}</Title>
                        </Col>
                </Row>
                <Row className='borderbottom py-3 px-1'>
                        <Col span={10}>
                            <Title level={5} className='light-font-dim my-0'>{t("Category")}</Title>
                        </Col>
                        <Col span={14}>
                            <Title level={5} className='my-0'>{data?.category_dtl?.name}</Title>
                        </Col>
                </Row>
                <Row className='borderbottom py-3 px-1'>
                        <Col span={10}>
                            <Title level={5} className='light-font-dim my-0'>{t("Rarity")}</Title>
                        </Col>
                        <Col span={14}>
                            <Title level={5} className='my-0'>{data?.rarity}</Title>
                        </Col>
                </Row>
                <Row className='borderbottom py-3 px-1'>
                        <Col span={10}>
                            <Title level={5} className='light-font-dim my-0'>{t("Drop  Date")}</Title>
                        </Col>
                        <Col span={14}>
                            <Title level={5} className='my-0'>{LLFormatWithTime(data?.drop_date)}</Title>
                        </Col>
                </Row>
                <Row className='borderbottom py-3 px-1'>
                    <Col span={10}>
                        <Title level={5} className='light-font-dim my-0'>{t("Listing Price")}</Title>
                    </Col>
                    <Col span={14}>
                        <Title level={5} className='my-0'>
                            {marketPlaceItem?.amount ||data?.listing_price} €
                        </Title>
                    </Col>
                </Row>
                {
                    isCollectionItem &&
                    <>
                    <Row className='borderbottom py-3 px-1'>
                            <Col span={10}>
                                <Title level={5} className='light-font-dim my-0'>{t("Quantity")}</Title>
                            </Col>
                            <Col span={14}>
                                <Title level={5} className='my-0'>{quantity}</Title>
                            </Col>
                    </Row>
                    {
                        marketPlaceQuantity &&
                        <Row className='borderbottom py-3 px-1'>
                            <Col span={10}>
                                <Title level={5} className='light-font-dim my-0'>
                                    {t("MarketPlace Quantity")}
                                </Title>
                            </Col>
                            <Col span={14}>
                                <Title level={5} className='my-0'>{marketPlaceQuantity || 0}</Title>
                            </Col>
                        </Row>
                    }
                    </>
                }
                <Row className='borderbottom py-3 px-1'>
                        <Col span={10}>
                            <Title level={5} className='light-font-dim my-0'>
                                {
                                    isMarketPlaceItem ? t('Quantity') : t('Total Copies')
                                }
                            </Title>
                        </Col>
                        <Col span={14}>
                            <Title level={5} className='my-0'>
                                {
                                    isMarketPlaceItem ? 1 : (data?.available_stock + data?.sold_stock)
                                }
                            </Title>
                        </Col>
                </Row>
                {
                    marketPlaceItem &&
                    <Row className='borderbottom py-3 px-1'>
                        <Col span={10}>
                            <Title level={5} className='light-font-dim my-0'>{t("Seller's Nickname")}</Title>
                        </Col>
                        <Col span={14}>
                            <Title level={5} className='my-0'>
                                {
                                   marketPlaceItem?.user_dtl?.username
                                }
                            </Title>
                        </Col>
                    </Row> 
                }
                <div className='borderbottom py-3 px-1'>
                    <Title level={5} className='light-font-dim my-0 text-center'>{t("Description")}</Title>
                    <Title level={5} className='my-0 text-center'>
                        {
                            data?.description
                        }
                    </Title>
                </div>
            </div>
            <div className='py-2 px-1'>
                {
                    isCollectionItem?
                    <Button 
                        type='primary' 
                        block
                        onClick={()=>setVisibleSellItemModal(true)}
                        size='large'
                        >
                        {t("Sell Now")}
                    </Button>
                    :
                    droppingSoon ?
                    <Space direction='vertical' className='width-100 text-center'>
                        <Title level={3}>{t("OFFICIAL RELEASE IN")}</Title>
                        <Button
                            block
                            size='large'
                            type='primary'
                            >
                            <Countdown
                                date={dateWithTime(data?.drop_date)}
                                renderer={renderer}
                                />
                        </Button>
                    </Space>
                    :
                    marketPlaceItem ?
                    <>
                        {
                            userID===marketPlaceItem?.user_id?
                            <Button 
                                block
                                size='large'
                                type='primary'
                                danger
                                onClick={removeItem}
                                loading={loading}
                                >
                                {t("Remove")}
                            </Button>
                            :
                            <Button 
                                block
                                type='primary'
                                size='large'
                                onClick={()=>{setVisibleBuyItemModal(true)}}
                                >
                                    {t("PURCHASE NOW")}
                            </Button>
                        }
                    </>
                    :
                    <Space direction='vertical' className='width-100'>
                        {
                            data?.available_stock ?
                            <div className='justify-center'>
                                <QuantityCounter
                                    quantity={quantity}
                                    index={null}
                                    available_stock={data?.available_stock}
                                    upDown={upDown}
                                    onChange={(value)=>{
                                        if(value>=1 && value<=data?.available_stock)
                                            setQuantity(value)
                                        else if(value>data?.available_stock)
                                            setQuantity(data?.available_stock)
                                        else 
                                            setQuantity(1)
                                    }}
                                    />
                            </div>
                            :
                            <></>
                        }
                        <Title level={5} className='my-0 text-center'>
                            {t("Available quantity")}: {data?.available_stock}
                        </Title>
                        <Button 
                            block
                            type='primary'
                            size='large'
                            loading={loading}
                            onClick={buyNow}
                            disabled={!data?.available_stock}
                            >
                                {
                                    !data?.available_stock ? t("SOLD OUT") 
                                    : t("BUY NOW")
                                }
                        </Button>
                    </Space>
                }
            </div>
            <SellItemModal
                visible={visibleSellItemModal}
                data={data}
                onClose={()=>{setVisibleSellItemModal(false)}}
                />
            <BuyItemModal
                visible={visibleBuyItemModal}
                data={marketPlaceItem}
                onClose={()=>{setVisibleBuyItemModal(false)}}
                />
        </Space>
    )
}

export default RightDetails2
import { useSelector } from 'react-redux'
import { Space, Typography, Button, Form} from 'antd'
import './cart.css'
import { useNavigate } from 'react-router-dom'
import { NFCheckbox } from '../Form'
const { Title, Text} = Typography

const RightSide = ({amount}) => {
  const [form] = Form.useForm()
  const navigate= useNavigate()
  const {language}= useSelector(state => state?.app)
  console.log("lang:", useSelector(state => state?.app))
 
  const openPDF=(src)=>{
    let pdfWindow = window.open("")
    pdfWindow.document.write(`<iframe width='100%' height='100%' src='${src}'></iframe>`)
  }
  return (
    <div className='my-5 dflex'>
        <div className='width93'>
        <Form
          form={form}
          layout="vertical"
          initialValues={true}
          onFinish={()=>{navigate("/checkout", { replace: true })}}
          >
          <Space direction='vertical' className='radius-12 bg-white w-100 py-4 px-4  shadow-1'>
              <Space className='space-cs'>
                  <Title level={5} classNamem='m-0'>Total</Title>
                  <Title level={5} classNamem='m-0'>{amount} €</Title>
              </Space>
              <NFCheckbox
                  name='remember'
                  label={
                    <Text strong className='cursor-pointer'>
                        I have read and accepted the <span 
                            onClick={(e)=>{
                                e.stopPropagation()
                                openPDF(language === 'fr' ? '/assets/pdf/General conditions of sale French.pdf' : '/assets/pdf/General conditions of sale English.pdf')
                            }} 
                            className='link'
                            >
                            General Conditions of Sale
                        </span>
                    </Text>
                  }
                  required
                  message='Should accept agreement'
                  /> 
              <div className='blackline'></div>
              <Button 
                size='large' 
                type='primary' 
                block 
                htmlType='submit'
                > 
                Checkout
              </Button>
          </Space>
        </Form>
        </div>
    </div>
  )
}

export {RightSide}
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl4} from '../../../constants/apiconstans/domanUrl';
import { Alert } from '../../../../components';
export const getMarketPlaceItems = createAsyncThunk('MarketPlace',
    async (object, { dispatch, getState }) => {
        const userToken = localStorage.getItem('token')
        dispatch(gettingMarketPlace())
        var myHeaders = new Headers();
        myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
        myHeaders.append("token", userToken || null)
        myHeaders.append("pageNo", object?.pageNo)
        myHeaders.append("recordsPerPage", 6)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: object?.formData
        };
        fetch(domainUrl4 + `/getMarketPlace`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.data)
                {
                    dispatch(marketPlaceResponse(result?.data))
                    dispatch(setMarketPlacePages({currentPage: object?.pageNo, totalRecords: result?.totalRecords}))
                    dispatch(setMarketPlaceLookups({
                        artistLookup:result?.artist,
                        genreLookup:result?.type,
                    }))
                }
                else
                {
                    dispatch(marketPlaceClear())
                    Alert("error", "error while getting MarketPlace items") 
                }
            })
            .catch(error => {
                dispatch(marketPlaceClear())
                Alert("error", error) 
            })
    }
)

const marketPlaceSlice = createSlice({
    name: 'marketPlace',
    initialState: {
        data: [],
        loading: false,
        pagination:{
            currentPage:0,
            totalRecords:0,
        },
        marketPlaceLookups:{artistLookup:[], genreLookup:[]},
        filters:{
            artist_id: null,
            type_id: null,
            price: null,
            release_date: null,
            list_type: false,
        }
    },
    reducers: {
        gettingMarketPlace: (state, action) => {
            state.loading = true;
        },
        marketPlaceResponse: (state, action) => {
            state.data = action.payload;
            state.loading= false;
        },
        marketPlaceClear: (state, action) => {
            state.data = [];
            state.loading = false;
        },
        setMarketPlacePages:(state, action)=>{
            state.pagination= action.payload
        },
        setMarketPlaceLookups:(state, action)=>{
            state.marketPlaceLookups= action.payload
        },
        changeMarketPlaceFilters:(state, action)=>{
            state.filters= action.payload
        }
    },
})

export const {
    gettingMarketPlace, 
    marketPlaceResponse, 
    marketPlaceClear, 
    setMarketPlacePages,
    setMarketPlaceLookups,
    changeMarketPlaceFilters
} = marketPlaceSlice.actions
export default marketPlaceSlice.reducer
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl'
import { Alert } from '../../../../components'
export const getArtistsList = createAsyncThunk('Artists',
    async (data, { dispatch, getState }) => {
        dispatch(gettingArtists())
        let userToken=localStorage.getItem('token')
        let user_id= localStorage?.getItem("userId")
        var myHeaders = new Headers();
        myHeaders.append("token", `${userToken}`)
        myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
        myHeaders.append("pageNo", data?.pageNo)
        myHeaders.append("recordsPerPage", 8)
        var requestOptions = {
            method: 'POST',
            headers:myHeaders,
            redirect: 'follow',
            body: data?.formData
        }
        fetch(domainUrl + `/GetArtist`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode===1)
                    {
                        dispatch(artistsResponse(result?.data))
                        dispatch(setArtistListPages(
                            {
                                currentPage: data?.pageNo, 
                                totalRecords: result?.total
                            }
                        ))
                    }
                else
                {
                    dispatch(artistsClear())
                    throw result?.ResponseMsg
                }
            })
            .catch(error => {
                dispatch(artistsClear())
                Alert("error", error) 
            })
    }
)

const ArtistsSlice = createSlice({
    name: 'Artists',
    initialState: {
        data: [],
        pagination:{
            currentPage:0,
            totalRecords:0,
        },
        loading: false
    },
    reducers: {
        gettingArtists: (state, action) => {
            state.loading = true;
        },
        artistsResponse: (state, action) => {
            state.data = action.payload;
            state.loading= false;
        },
        artistsClear: (state, action) => {
            state.data = [];
            state.loading = false;
        },
        setArtistListPages:(state, action)=>{
            state.pagination= action.payload
        },
    },
})

export const {gettingArtists, artistsResponse, artistsClear, setArtistListPages} = ArtistsSlice.actions
export default ArtistsSlice.reducer
import { createSlice } from '@reduxjs/toolkit'
const trackSlice = createSlice({
    name: 'track',
    initialState: {
        trackList: [],
        isPlaying: false,
        isRepeat:false,
        isRandom:false,
        currentTrack:null,
    },
    reducers: {
        setTrackList: (state, action) => {
            state.trackList = action.payload
            state.isPlaying= false
            state.currentTrack= 0
        },
        playTrack: (state, action) => {
            state.isPlaying = action.payload.isPlaying
            state.currentTrack= action.payload.currentTrack
        },
        stopTrack: (state) => {
            state.isPlaying = false
        },
        repeatTrack: (state) => {
            state.isRepeat = !state.isRepeat
            state.isRandom = false
        },
        randomTrack: (state) => {
            state.isRepeat = false
            state.isRandom = !state.isRandom
        },
        clearTrack: (state) => {
            state.trackList=[]
            state.isPlaying=false
            state.currentTrack=null
        },
    },
})

export const {setTrackList, playTrack, clearTrack, repeatTrack, randomTrack, stopTrack} = trackSlice.actions
export default trackSlice.reducer
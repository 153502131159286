import { Image, Space, Typography } from "antd"
import { IconSunFilled } from '@tabler/icons-react'
const {Text}= Typography
const languagesLookup=[
  {
    id:'en', 
    name:<Space className='w-100'>
          <Image src='/assets/img/united-kingdom.png' width={'25px'} alt='NFSounds' preview={false}/>
          <Text className='fs-12'>English</Text>
        </Space>
  },
  {
    id:'fr', 
    name:<Space className='w-100'>
          <Image src='/assets/img/flag.png' width={'25px'} alt='NFSounds' preview={false}/>
          <Text className='fs-12'>French</Text>
        </Space>
  },
]
const themeModeLookup=[
  {
    id:1,
    name: <Space>
            <IconSunFilled style={{width:"14px"}} />
            <Text className='fs-12'>Light</Text>
          </Space>
  },
  {
    id:2,
    name: <Space>
            <IconSunFilled style={{width:"14px"}} />
            <Text className='fs-12'>Dark</Text>
          </Space>
  }
]
const releaseDateLookup=[
  { id: null, name:'All'},
  { id:'This week', name:'This Week'},
  { id:'Last week', name:'Last Week'},
  { id:'This month', name:'This Month'},
  { id:'Last month', name:'Last Month'},
]
const storePriceLookup=[
  { id: null, name:'All'},
  { id: 1, name:'Highest Price'},
  { id: 2, name:'Lowest Price'},
]

export {languagesLookup, themeModeLookup, releaseDateLookup, storePriceLookup}
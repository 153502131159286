
import {gettingUsers, usersResponse, usersClear, getAllUsers} from "./action/users"
import {login, loginResponse, loginClear, generateToken} from "./action/login"
import {rolesResponse, rolesError, rolesClear, showAllrole} from "./action/roles"
import {clientsResponse, loadClients} from "./action/client"
import {loadDashboard} from "./action/dashboard"
import {getSalesPersons} from "./action/salesPersons"
import { getAllAds, adsResponse } from "./action/Ads"
import {getArtistsList} from "./action/Artists"
//store
import {getAllStoreItems, storeResponse, changeStoreFilters} from "./action/Store"
//dropping soon
import { getAllDroppingSoonItems, droppingSoonItemsResponse } from "./action/Store/DroppingSoonItems"
import { getMyCollection, collectionClear, collectionResponse, setCollectionLookups, changeCollectionFilters } from "./action/Collection"
// item details
import { getItemDetail, itemDetailClear} from "./action/ItemDetail"
import { getCartList, cartResponse} from "./action/Cart"
import { getArtistCartList } from "./action/Cart/Artist"
import { placeOrder } from "./action/Checkout"
import { placeOrderArtist } from "./action/Checkout/artist"
//music
import {getPlaylists, playlistsResponse} from "./action/Music/Playlists"
import {getMyCollectionMusic, collectionMusicClear} from "./action/Collection/CollectionMusic"
import { getPlaylist, playlistResponse } from "./action/Music"
import {setTrackList, playTrack, stopTrack, repeatTrack, randomTrack, clearTrack } from "./action/Music/Track"
//artist
import {getArtistPosts, artistPostsResponse} from "./action/Artists/ArtistPosts"
import {getArtistProfile, artistProfileResponse} from "./action/Artists/ArtistProfile"
import { getPostComments, postCommentsResponse} from "./action/Artists/PostComments"
//User
import { getUserProfile, userProfileResponse} from "./action/User"
import { getfavoriteItems, favoriteItemsResponse} from "./action/Store/FavoriteItems"
import { getUserSellPurchaseLog } from "./action/User/UserSellPurchaseLog"
import { getUserSubscriptions, userSubscriptionsResponse, } from "./action/User/UserSubscriptions"
//marketplace
import { getMarketPlaceItems, marketPlaceClear, marketPlaceResponse, changeMarketPlaceFilters} from "./action/MarketPlace"
//notifications
import { showAllNotifications, clearNotifications,clearNoticationsCount, notificationsClear} from "./action/Notifications"
//artist section
import { getFeeds, feedsResponse, likeUnlikeOnFeed, commentOnFeed } from "./action/Feeds"
import { getFeedComments, feedCommentsClear } from "./action/Feeds/FeedComments"
import { getArtistStories } from "./action/Artists/ArtistStories"
// artist collection
import {getMyCollectionByArtist, artistCollectionResponse} from "./action/Collection/ArtistCollection"
//app
import { changeLanguage } from "./action/App"
export const actionsApi = {
    gettingUsers, 
    usersResponse, 
    usersClear, 
    getAllUsers,
    //login
    login,
    loginResponse, 
    loginClear,
    generateToken,
    rolesResponse,
    rolesError, 
    rolesClear, 
    showAllrole,
    clientsResponse, 
    loadClients,  
    loadDashboard,
    getSalesPersons ,
    getAllAds,
    adsResponse,
    getArtistsList,
    getAllStoreItems,
    storeResponse,
    changeStoreFilters,
    //dropping soon
    getAllDroppingSoonItems,
    droppingSoonItemsResponse,
    getMyCollection, 
    collectionClear,
    collectionResponse,
    setCollectionLookups, 
    changeCollectionFilters,
    // item detail
    getItemDetail,
    itemDetailClear,
    getCartList,
    cartResponse,
    getArtistCartList,
    placeOrder,
    placeOrderArtist,
    //music
    getPlaylists,
    playlistsResponse,
    getMyCollectionMusic,
    collectionMusicClear,
    getPlaylist, 
    playlistResponse,
    setTrackList,
    playTrack, 
    stopTrack,
    repeatTrack, 
    randomTrack,
    clearTrack,
    //artist
    getArtistPosts,
    artistPostsResponse,
    getArtistProfile,
    artistProfileResponse,
    getPostComments,
    postCommentsResponse,
    //user
    getUserProfile,
    getfavoriteItems,
    favoriteItemsResponse,
    userProfileResponse,
    getUserSellPurchaseLog,
    getUserSubscriptions,
    userSubscriptionsResponse,
    //marketplace
    getMarketPlaceItems, 
    marketPlaceClear,
    marketPlaceResponse, 
    changeMarketPlaceFilters,
    //notifications
    showAllNotifications,
    clearNotifications,
    clearNoticationsCount,
    notificationsClear,
    //feeds
    getFeeds,
    feedsResponse,
    likeUnlikeOnFeed,
    commentOnFeed,
    getFeedComments,
    feedCommentsClear,
    getArtistStories,
    // artist collection
    getMyCollectionByArtist,
    artistCollectionResponse,
    //app
    changeLanguage
}

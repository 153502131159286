import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next"
import { Row, Col, Space, Typography, Image, Dropdown} from 'antd'
import './footer.css'
import { NavLink } from 'react-router-dom'
import {
    IconChevronDown,
    IconChevronUp,
    IconBrandFacebook,
    IconBrandTwitter, 
    IconBrandInstagram,
    IconBrandSpotify,
} from '@tabler/icons-react'
import { FilePdfOutlined } from '@ant-design/icons'
import { languagesLookup, themeModeLookup } from '../../shared/lookups'
import { NFSelect } from '../Form'
import { actionsApi } from '../../shared'

const { Title, Text } = Typography

const Footer = () => {
    const { t, i18n } = useTranslation();
    const dispatch= useDispatch()
    const [updown, setUpDown] = useState(0)
    const [language, setLanguage]= useState(0)
    const [themeMode, setThemeMode]= useState(1)
    
    useEffect(()=>{
        let lang= localStorage.getItem("lang")
        setLanguage(lang  || 'en')
        i18n.changeLanguage(lang  || 'en')
        dispatch(actionsApi?.changeLanguage(lang  || 'en'))
    }, [])           
    const exploreUs = [
        {
          label:<NavLink to='/faqs'>
                    <Text strong>{t("FAQs")}</Text>
                </NavLink>,
          key: '1',
        },
        {
            label:  <Space className='align-center cursor-pointer'>
                        <Text 
                            onClick={()=>{openPDF(language === 'fr' ? '/assets/pdf/Terms of Use French.pdf' : '/assets/pdf/Terms of Use English.pdf')}} 
                            strong
                            className='center'
                            >
                            {t("Terms of Use")}
                        </Text>
                        <FilePdfOutlined className='red-5 up'/>
                    </Space>,
            key: '2',
        },
        {
            label:  <Space className='align-center cursor-pointer'>
                        <Text 
                            onClick={()=>{openPDF(language === 'fr' ? '/assets/pdf/Privacy Policy French.pdf' : '/assets/pdf/Privacy Policy English.pdf')}} 
                            strong
                            className='center'
                            >
                            {t("Privacy Policy")}
                        </Text>
                        <FilePdfOutlined className='red-5 up'/>
                    </Space>,
            key: '3',
          },
    ]

    const data = {
        logof:"logo.png",
        listin:[
            {
                title:"",
                innerlist:[
                    {
                        lst:<NavLink className='dim-color' to='/store'>{t("Store")}</NavLink>
                    },
                    {
                        lst:<NavLink className='dim-color' to='/faq'>{t("FAQ/TOU")}</NavLink>
                    }
                ]
            },
        ]
    }
    const openPDF=(src)=>{
        let pdfWindow = window.open("")
        pdfWindow.document.write(`<iframe width='100%' height='100%' src='${src}'></iframe>`)
    }
    const handleChnage= (value)=>{
        setLanguage(value)
        localStorage.setItem("lang", value)
        i18n?.changeLanguage(value)
        dispatch(actionsApi?.changeLanguage(value))
    }
  return (
    <div className='justify-center'>
        <div className='width-90'>
            <div className='border-bottom-black w-100'></div>
            <Row gutter={[16, 32]} className='py-5 show-section'>
                <Col sm={8} xs={8} className='justify-center'>
                    <NFSelect
                        withoutForm
                        value={language}
                        onChange={value => handleChnage(value)}
                        options={languagesLookup}
                        style={{width:'120px'}}
                        />
                </Col>
                <Col sm={8} xs={8} className='justify-center'>
                    <Dropdown
                        menu={{
                        items: exploreUs,
                        }}
                        trigger={['click']}
                        >
                        <a onClick={(e) => e.preventDefault()} className='text-black'> 
                            <Space onClick={()=>setUpDown(!updown)}>
                                <Text className='fs-12'>
                                    {
                                        t("Explore Us")
                                    }
                                </Text>
                                {
                                    updown?<IconChevronUp style={{width:"14px"}} />
                                    :
                                    <IconChevronDown style={{width:"14px"}} />
                                }
                            </Space>
                        </a>
                    </Dropdown>
                </Col>
                <Col sm={8} xs={8} className='center'>
                    <NFSelect
                        withoutForm
                        value={themeMode}
                        onChange={value => setThemeMode(value)}
                        options={themeModeLookup}
                        style={{width:'100px'}}
                        />
                </Col>
                <Col xs={24} sm={24} className='justify-center'>
                    <Space direction='vertical'>
                        <Title level={4} className='m-0 text-center'>{t("Join Us")}</Title>                            
                        <Space>
                            <a href='/'>
                                <IconBrandFacebook style={{color:'#4267B2'}} width={28} height={28}/>
                            </a>
                            <a href='/'>
                                <IconBrandTwitter style={{color: '#00acee'}} width={28} height={28}/>
                            </a>
                            <a href='/'>
                                <IconBrandInstagram style={{color: '#d62976'}} width={28} height={28}/>
                            </a>
                            <a href='/'>
                                <IconBrandSpotify style={{color: '#1db954'}} width={28} height={28}/>
                            </a>
                        </Space>
                        <Title level={4} className='m-0 text-center'>{t("Contact us")}</Title>
                        <Text strong>support@nfsounds.com </Text>
                    </Space>
                </Col>
                <Col xs={24} sm={24} className='justify-center'>
                    <Space direction='vertical'>
                        <Title level={4} className='m-0 text-center'>{t("Download App")}</Title>                            
                        <Space className='w-100' size={14}>
                            <Image src='/assets/img/qr.png' preview={false} width={'90px'} className='shadow-1 radius-12 px-2 py-2' />
                            <Space className='w-100' direction='vertical'>
                                <Space className='w-100'>
                                    <Image src='/assets/img/apple-logo.png' />
                                    <Text strong>App Store</Text>
                                </Space>
                                <Space className='w-100'>
                                    <Image src='/assets/img/google-play-icon.png' />
                                    <Text strong>Google Play</Text>
                                </Space>
                            </Space>
                        </Space>
                    </Space>
                </Col>
                <Col sm={24} xs={24} className='center'>
                    <Text strong>
                        &copy; {t("Copyright")}
                    </Text>
                </Col>
                
            </Row>
            <Row gutter={[16, 32]} className='py-5 hide-section'>
                <Col xs={24} sm={12} md={12} lg={6} className='justify-center'>
                    <Image 
                        src={'/assets/img/'+data?.logof} 
                        width={'130px'} 
                        preview={false}
                        alt='NFsounds'
                        />
                </Col>
                <Col xs={24} sm={12} md={12} lg={6} className='justify-center'>
                    <Space direction='vertical'>
                        <Title level={4} className='m-0'>
                            {
                                t("Explore Us")
                            }
                        </Title>
                        <Space direction='vertical' size={1} className='justify-center'>
                            <NavLink to='/faqs'>
                                <Text strong>{t("FAQs")}</Text>
                            </NavLink>
                            <Space className='align-center cursor-pointer'>
                                <Text 
                                    onClick={()=>{openPDF(language === 'fr' ? '/assets/pdf/Terms of Use French.pdf' : '/assets/pdf/Terms of Use English.pdf')}} 
                                    strong
                                    className='center'
                                    >
                                    {t("Terms of Use")}
                                </Text>
                                <FilePdfOutlined className='red-5 up'/>
                            </Space>
                            <Space className='align-center cursor-pointer'>
                                <Text 
                                    onClick={()=>{openPDF(language === 'fr' ? '/assets/pdf/Privacy Policy French.pdf' : '/assets/pdf/Privacy Policy English.pdf')}} 
                                    strong
                                    className='center'
                                    >
                                    {t("Privacy Policy")}
                                </Text>
                                <FilePdfOutlined className='red-5 up'/>
                            </Space>
                        </Space>  
                    </Space>
                </Col>
                <Col xs={24} sm={12} md={12} lg={6} className='justify-center'>
                    <Space direction='vertical'>
                        <Title level={4} className='m-0'>{t("Join Us")}</Title>                            
                        <Space>
                            <a href='/'>
                                <IconBrandFacebook style={{color:'#4267B2'}} width={28} height={28}/>
                            </a>
                            <a href='/'>
                                <IconBrandTwitter style={{color: '#00acee'}} width={28} height={28}/>
                            </a>
                            <a href='/'>
                                <IconBrandInstagram style={{color: '#d62976'}} width={28} height={28}/>
                            </a>
                            <a href='/'>
                                <IconBrandSpotify style={{color: '#1db954'}} width={28} height={28}/>
                            </a>
                        </Space>
                        <Title level={4} className='m-0'>{t("Contact us")}</Title>
                        <Text strong>support@nfsounds.com </Text>
                    </Space>
                </Col>
                <Col xs={24} sm={12} md={12} lg={6} className='justify-center'>
                    <Space direction='vertical'>
                        <Title level={4} className='m-0'>{t("Download App")}</Title>                            
                        <Space className='w-100' size={14}>
                            <Image src='/assets/img/qr.png' preview={false} width={'90px'} className='shadow-1 radius-12 px-2 py-2' />
                            <Space className='w-100' direction='vertical'>
                                <Space className='w-100'>
                                    <Image src='/assets/img/apple-logo.png' />
                                    <Text strong>App Store</Text>
                                </Space>
                                <Space className='w-100'>
                                    <Image src='/assets/img/google-play-icon.png' />
                                    <Text strong>Google Play</Text>
                                </Space>
                            </Space>
                        </Space>
                    </Space>
                </Col>
                <Col lg={8} sm={24} xs={24} className='justify-center'>
                    <NFSelect
                        withoutForm
                        value={language}
                        onChange={value => handleChnage(value)}
                        options={languagesLookup}
                        style={{width:'120px'}}
                        />
                </Col>
                <Col lg={8} sm={24} xs={24} className='center'>
                    <Text strong>
                        &copy;{t("Copyright")}
                    </Text>
                </Col>
                <Col lg={8} sm={24} xs={24} className='center'>
                    <NFSelect
                        withoutForm
                        value={themeMode}
                        onChange={value => setThemeMode(value)}
                        options={themeModeLookup}
                        style={{width:'100px'}}
                        />
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default Footer
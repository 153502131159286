import React from 'react'
import Routing from './routes'
import { Provider } from 'react-redux'
import { store } from './shared'
import "./App.less"
const App = () => { 
    return (
          <Provider store={store}>  
            <Routing />
          </Provider>
      )
}
export default App

import {
  IconPlayerPlayFilled,
  IconPlayerPauseFilled,
  IconPlayerSkipBackFilled,
  IconPlayerSkipForwardFilled,
  IconPlayerTrackNextFilled,
  IconPlayerTrackPrevFilled,
  IconArrowsRandom,
  IconRepeat
} from '@tabler/icons-react'
import { useEffect, useRef, useCallback} from 'react'
import {Space, Button} from "antd"
import { useSelector, useDispatch } from 'react-redux';
import { actionsApi } from '../../shared'

const Controls = ({audioRef, progressBarRef, duration, setTimeProgress, changeTrack, expand}) => {
  const {isPlaying, currentTrack, isRepeat, isRandom}= useSelector( state=>state?.track)
  console.log("playing:", isPlaying)
  const dispatch= useDispatch()
    const playAnimationRef = useRef()

    const togglePlayPause = () => {
        dispatch(actionsApi?.playTrack({isPlaying: !isPlaying, currentTrack}))
      }

      const repeat = useCallback(() => {
        const currentTime = audioRef.current.currentTime;
        setTimeProgress(currentTime);
        progressBarRef.current.value = currentTime;
        progressBarRef.current.style.setProperty(
          '--range-progress',
          `${(progressBarRef.current.value / duration) * 100}%`
        );
    
        playAnimationRef.current = requestAnimationFrame(repeat);
      }, [audioRef, duration, progressBarRef, setTimeProgress]);
      useEffect(() => {
        if (isPlaying) {
          audioRef.current.play()
        } else {
          audioRef.current.pause()
        }
        playAnimationRef.current = requestAnimationFrame(repeat);
      }, [isPlaying, audioRef, repeat])
      const skipForward = () => {
        audioRef.current.currentTime += 5;
      };

      const skipBackward = () => {
        audioRef.current.currentTime -= 5;
      };
      
  return (
    <div className='justify-center'>
      <Space>
        <Button 
          icon={<IconArrowsRandom className={isRandom ? 'brand-color' : ''}/>} 
          shape='circle' 
          onClick={()=> dispatch(actionsApi?.randomTrack())}
          className='music-button-transparent'
          />
        <Button 
          size='large'
          icon={<IconPlayerSkipBackFilled />} 
          shape='circle' 
          onClick={()=>{changeTrack(false)}}
          className='music-button-transparent'
          />
          <Button 
            onClick={togglePlayPause} 
            icon={isPlaying ? <IconPlayerPauseFilled />:<IconPlayerPlayFilled />} 
            shape='circle'
            />
        <Button 
          size='large'
          icon={<IconPlayerSkipForwardFilled />} 
          shape='circle' 
          onClick={()=>changeTrack(true)}
          className='music-button-transparent'
          />
        <Button 
          icon={<IconRepeat className={isRepeat ? 'brand-color' : ''}/>} 
          shape='circle' 
          onClick={()=> dispatch(actionsApi?.repeatTrack())}
          className='music-button-transparent'
          />
      </Space>
    </div>
  );
  };
  export default Controls;
import {useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import { Space, Typography, Avatar } from 'antd'

const { Title } = Typography

const ProfileLeftBlock = () => {
  const {data: userProfile}= useSelector(state => state?.userProfile)

  return (
    <div className='user-profile-left'>
        <Space direction='vertical' className='text-center w-100'>
            <Avatar
              size={170}
              shape='square'
              src={
                userProfile?.profile ||
                "/assets/user-avatar.png"
              }
              alt='NFsounds user profile picture'
            />
            <Title level={1}>
              {
                (userProfile?.fname || '') +" "+ (userProfile?.lname || '')
              }
            </Title>
        </Space>
    </div>
  )
}

export default ProfileLeftBlock
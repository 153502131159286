
import { Alert } from "../../../components"
import { domainUrl } from "../../constants/apiconstans/domanUrl"
import {key} from "../../"
const subscribe=(data)=>{
    let userToken=localStorage.getItem('token')
    var myHeaders = new Headers();
    myHeaders.append("token", `${userToken}`)
    myHeaders.append("key", key)
    myHeaders.append("content-type", `application/json`)
    var requestOptions = {
      headers: myHeaders,
      method: 'POST',
      redirect: 'follow',
      body: JSON.stringify(data)
    }
    return (
      fetch(domainUrl+"/artistSubscribtion",requestOptions)
        .then(response=>response.json())
        .then(result=>{
            if(result?.ResponseCode)
                {
                    Alert('success', result?.ResponseMsg)
                    return 1
                }
            else       
                throw result?.ResponseMsg
        })
        .catch((error)=>{
            Alert("error",error)
            return 0
        })
    )
}
export {subscribe}
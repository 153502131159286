import { useSelector } from "react-redux"
import {Row, Col} from 'antd'
import {DataNotFound, PostCard, CardSkeleton} from "../"

const FeedCards = () => {

    const {data, loading}= useSelector(state => state?.artistPosts)

    return (
        <div>
            {
                loading && data===null ?
                <Row gutter={[16,16]}>
                    {
                        new Array(6)?.fill(null)?.map((_, index)=>
                            <Col 
                                xs={24} sm={12} md={12} lg={8} 
                                key={'collection-item-'+index}
                                >
                                <CardSkeleton/>
                            </Col>
                        )
                    }
                </Row>
                :
                !data?.length ?
                <DataNotFound alt='NFSounds no artist collection nft found' />
                :
                <Row gutter={[32,32]}>
                    {
                        data?.map((post, index)=>
                        <Col xs={24} sm={24} md={12} lg={8} key={'post-'+index}>
                            <PostCard
                                feed={post}
                                data={data}
                                isPost
                                />
                        </Col>
                        )
                    }
                </Row>
            }
        </div>
    )
}

export default FeedCards
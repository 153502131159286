import React,{ useEffect, useState } from 'react'
import {Pagination, Row, Col } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { actionsApi, checkAuthorization } from '../../shared'
import { updateFavorite } from '../../shared'
import { CardSkeleton, Cart, DataNotFound, ItemCard} from '../'
import { useTranslation } from 'react-i18next'

const CollectionList = () => {
    const { t }= useTranslation()
    const [ visibleCart, setCartDrawer ] = useState(false)
    const {data, loading, pagination} = useSelector(state => state?.collection)
    const dispatch= useDispatch()
    const [state, setState]= useState(null)

    useEffect(()=>{
        getMyCollection()
    },[])
    useEffect(()=>{
        if(data)
        {
            let collection= [...data?.data]
            for(let i=0; i< collection?.length; i++)
            {
                let quantityOwnedArray= data?.collections?.filter(_ => _?.store_id === parseInt(collection[i]?.store_id))
                let quantityOwned=0
                for(let j=0; j<quantityOwnedArray?.length; j++)
                    quantityOwned+= quantityOwnedArray[j]?.quantity
                collection[i]={
                    ...collection[i],
                    quantityOwned
                }
            }
            setState([...collection])
        }
    },[data])

    const getMyCollection=(pageNo=1)=>{
        const {user_id}= checkAuthorization()
        let formData = new FormData()
        formData.append(`user_id`, user_id)
        dispatch(actionsApi?.getMyCollection({formData, pageNo}))
    }
    const UpdateFavorite= async (store_id, is_Favourite)=>{
        const {user_id}= checkAuthorization()
        dispatch(actionsApi?.collectionResponse([...state?.map(_=>{
            if(parseInt(_?.store_id)===parseInt(store_id))
            return (
                {
                    ..._,
                    is_Favourite:is_Favourite?'1':'0'
                }
            )
            return _
        })]))
        let formData = new FormData()
        formData.append(`user_id`, user_id)
        formData.append(`store_id`, store_id)
        await updateFavorite(formData)
    }
 
  return (
    <div>
        {
            loading && data===null ?
            <Row gutter={[16,16]}>
                {
                    new Array(6)?.fill(null)?.map((_, index)=>
                        <Col 
                            xs={24} sm={12} md={12} lg={8} 
                            key={'collection-item-'+index}
                            >
                            <CardSkeleton/>
                        </Col>
                    )
                }
            </Row>
            :
            !state?.length ?
            <DataNotFound alt='NFSounds no collection nft found'/>
            :
            <Row gutter={[16,16]}>
                {
                    state?.map((dat,dt)=>
                    <Col 
                        xs={24} sm={12} md={12} lg={8} 
                        key={'collection-item-'+dt}
                        > 
                        <ItemCard
                            collection
                            data={{store_dtl: dat}}
                            to={'/collection-detail/'+dat?.store_id}
                            UpdateFavorite={UpdateFavorite}
                            />
                    </Col>
                    )
                }
                {
                    pagination?.totalRecords>0 &&
                    <Col span={24}>
                        <Pagination 
                            total={pagination?.totalRecords} 
                            defaultPageSize={6} 
                            current={pagination?.currentPage}
                            onChange={(value)=>{getMyCollection(value)}} 
                            />
                    </Col>
                }
            </Row>
        }
        <Cart 
            visible={visibleCart} 
            onClose={()=>setCartDrawer(false)} 
            />
    </div>
  )
}
export default CollectionList

import {
    Filters,
    MarketplaceCards
} from "../../components/MarketPlace"
import {Space, Typography} from "antd"
const {Title}= Typography
const MarketPlace = () => {
  return (
    <div className="justify-center py-5" style={{position:'relative'}}>
        <div className='overlayblur'>
            <Space direction='vertical' className='center'>
                <div className='imgcir m-auto'>
                    <img src="/assets/lock.png" alt="" />
                </div>
                <Title level={1} className='text-uppercase'>coming soon</Title>
            </Space>
        </div> 
        <Space className="width-93" direction="vertical" size={20}>
          <Filters/>
          <div className='mblnone'>
              <div className='blackline'></div>
          </div>
          <MarketplaceCards />
      </Space>
    </div>
  )
}

export {MarketPlace}
import React from 'react'
import { Typography, Space } from 'antd'
import Filter from './Filter';
import StoreCard from './StoreCard';
const { Title } = Typography



export const StoreComp = () => {
  return (
    <>
      <div className='justify-center'>
          <Space 
            className='width-93 py-3 border-top-black' 
            direction='vertical'
            size={15}
            >
            <Filter />
            <StoreCard />
          </Space>
      </div>
    </>
  )
}
import { Alert } from "../../../components"
import { domainUrl } from "../../constants/apiconstans/domanUrl"
import { message } from "antd"
import {checkAuthorizationWithoutLogin, key} from "../../"
const getUserDataLoginWithGoogle= (user)=>{

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${user?.access_token}`)
    myHeaders.append("Accept", 'application/json')

    var requestOptions = {
        headers: myHeaders,
        method: 'GET',
        redirect: 'follow'
    }
    return (
        fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user?.access_token}`,requestOptions)
        .then(response=>response.json())
        .then(result=>{
            console.log('result:',result)
            if(result)
                return result
            else       
                throw 'error'
        })
        .catch((error)=>{
            console.error(error)
            return 0
        })
    )
}
const socialUserRegister= (data)=>{
    const {key}= checkAuthorizationWithoutLogin()
    var myHeaders = new Headers()
    myHeaders.append("content-type", `application/json`)
    myHeaders.append("key", key)
    var requestOptions = {
        headers: myHeaders,
        method: 'POST',
        redirect: 'follow',
        body: JSON.stringify(data)
    }
    return (
        fetch(domainUrl +`/socialRegister`,requestOptions)
        .then(response=>response.json())
        .then(result=>{
            if(result?.ResponseCode===1)
                {
                    message.success("User register successfully")
                    return 1
                }
            else       
                throw result?.ResponseMsg
        })
        .catch((error)=>{
            message.error(error)
            return 0
        })
    )
}
const registerSocialMediaUser= (data)=>{
    const {key}= checkAuthorizationWithoutLogin()
    var myHeaders = new Headers()
    myHeaders.append("content-type", `application/json`)
    myHeaders.append("key", key)
    var requestOptions = {
        headers: myHeaders,
        method: 'POST',
        redirect: 'follow',
        body: JSON.stringify(data)
    }
    return (
        fetch(domainUrl +`/Registersocialmediauser`,requestOptions)
        .then(response=>response.json())
        .then(result=>{
            if(result?.ResponseCode===1)
                {
                    message.success("User register successfully")
                    return 1
                }
            else       
                throw result?.ResponseMsg
        })
        .catch((error)=>{
            message.error(error)
            return 0
        })
    )
}
const logoutUser= () => {
    let userToken=localStorage.getItem('token')
    var myHeaders = new Headers();
    myHeaders.append("token", `${userToken}`)
    myHeaders.append("key", key)

    let formData = new FormData()
    let user_id= localStorage?.getItem("userId")
    formData.append(`user_id`, user_id || null)

    var requestOptions = {
      headers: myHeaders,
      method: 'POST',
      redirect: 'follow',
      body: formData
    }
    return (
      fetch(domainUrl+"/logOut",requestOptions)
        .then(response=>response.json())
        .then(result=>{
            if(result?.ResponseCode===1)
                return 1
            else       
                throw result?.ResponseMsg
        })
        .catch((error)=>{
            console.error(error)
            return 0
        })
    )
}
const forgotPassword=(formData)=>{
    let userToken=localStorage.getItem('token')
    var myHeaders = new Headers();
    myHeaders.append("token", `${userToken}`)
    myHeaders.append("key", key)
    var requestOptions = {
      headers: myHeaders,
      method: 'POST',
      redirect: 'follow',
      body: formData
    }
    return (
        fetch(domainUrl+"/forgotPassword",requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result?.ResponseCode===1)
                {
                    message.success(result?.ResponseMsg);
                    return 1
                }
            else       
                throw result?.ResponseMsg
        })
        .catch((error)=>{
            message.error(error)
            return 0
        })
    )
}
const passwordChange=(data)=>{
    let userToken=localStorage.getItem('token')
    var myHeaders = new Headers();
    myHeaders.append("token", `${userToken}`)
    myHeaders.append("key", key)
    myHeaders.append("content-type", `application/json`)
    var requestOptions = {
      headers: myHeaders,
      method: 'POST',
      redirect: 'follow',
      body: JSON.stringify(data)
    }
    return (
        fetch(domainUrl+"/changePassword",requestOptions)
        .then(response=>response.json())
        .then(result=>{
            if(result?.ResponseCode)
                {
                    message.success(result?.ResponseMsg)
                    return 1
                }
            else       
                throw result?.ResponseMsg
        })
        .catch((error)=>{
            message.error(error)
            return 0
        })
    )
}
const updateUserProfile=(formData)=>{
    let userToken=localStorage.getItem('token')
    var myHeaders = new Headers();
    myHeaders.append("token", `${userToken}`)
    myHeaders.append("key", key)
    var requestOptions = {
      headers: myHeaders,
      method: 'POST',
      redirect: 'follow',
      body: formData
    }
    return (
      fetch(domainUrl+"/updateProfile",requestOptions)
        .then(response=>response.json())
        .then(result=>{
            if(result?.ResponseCode===1)
                {
                    message.success(result?.ResponseMsg)
                    return 1
                }
            else       
                throw result?.ResponseMsg
        })
        .catch((error)=>{
            message.error(error)
            return 0
        })
    )
}
const cancelSubscription=(subscriptionID)=>{
    let userToken=localStorage.getItem('token')
    var myHeaders = new Headers();
    myHeaders.append("token", `${userToken}`)
    myHeaders.append("key", key)
    var requestOptions = {
      headers: myHeaders,
      method: 'GET',
      redirect: 'follow',
    }
    return (
      fetch(domainUrl+`/unsubsription/${subscriptionID}`,requestOptions)
        .then(response=>response.json())
        .then(result=>{
            if(result?.ResponseCode===1)
                {
                    message.success('Subscription canceled successfully');
                    return 1
                }
            else       
                throw result?.ResponseMsg
        })
        .catch((error)=>{
            message.error(error)
            return 0
        })
    )
}
export {
    getUserDataLoginWithGoogle,
    socialUserRegister,
    registerSocialMediaUser,
    logoutUser, 
    forgotPassword, 
    passwordChange, 
    updateUserProfile, 
    cancelSubscription
}
import React from 'react'
import { Space, Avatar, Typography, Button} from 'antd'
import './checkout.css'

const { Title, Text } = Typography

export const CheckoutRightSide = ({data, total_amount, subscribtion}) => {
  return (
        <div>
            <Title level={3} className='text-center'>Details</Title>
            <div direction='vertical' className='radius-6 bg-white my-3 w-100  shadow-1 parentspace'>
                {
                    subscribtion?
                        <Space className='w-100 align-center-space-between py-3 px-4 cartspace-1'>
                            <Space size={15}>
                                <Avatar
                                    shape='square' 
                                    size={70} 
                                    src={data?.image}
                                    />
                                <Space direction='vertical' size={0}>
                                    <Title level={4} className='m-0'>
                                        {
                                             data?.name
                                        }
                                    </Title>
                                </Space>
                            </Space>
                            <Button>
                                {
                                    data?.subscriptionFee
                                } €
                            </Button>
                        </Space>
                    :
                    data?.map((item, index)=>
                        <Space className='w-100 align-center-space-between py-3 px-4 cartspace-1' key={'checkout-item-'+index}>
                            <Space size={15}>
                                <Avatar
                                    shape='square' 
                                    size={70} 
                                    src={item?.product?.type==='video'? item?.product?.thumbnail:item?.product?.image}
                                    />
                                <Space direction='vertical' size={0}>
                                    <Title level={4} className='m-0'>
                                        {
                                             item?.product?.store_name
                                        }
                                    </Title>
                                </Space>
                            </Space>
                            <Button>
                                {
                                    item?.amount || item?.product?.listing_price*item?.quantity
                                } €
                            </Button>
                        </Space>
                    )
                }
                
                <Space className='w-100 align-center-space-between py-3 px-4'>
                    <Title  level={5} className='m-0'>Total</Title>
                    <Title  level={5} className='m-0'>{total_amount} €</Title>
                </Space>
            </div>
        </div>
  )
}
import React from 'react'
import 'antd/dist/antd.less'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Protected from './Protected'
import Signuppage from '../pages/signup/Signuppage'
import Footer from '../components/footer/Footer'
import {   
    Store,
    ItemDetail, 
    Collection, 
    CollectionItemDetail, 
    Music,
    MarketPlace, 
    MarketPlaceItemDetail,
    ArtistPage, 
    ArtistProfile, 
    Checkout, 
    Subscribe, 
    Cart, 
    Faqs,
    ForgotPassword,
    UserProfile
} from '../pages'
import AudioPlayer from '../components/Player/AudioPlayer'
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actionsApi } from '../shared'
import ScrollToTop from '../ScrollToTop'
import { Navbar } from '../components'
function Routing(){    
    const [isLoggedIn, setIsLoggedIn]= useState(null)
    const dispatch= useDispatch()
    useEffect(()=>{
        if(!localStorage.getItem('token') || !localStorage?.getItem("userId"))
        {
            dispatch(actionsApi?.loginResponse(null))
            setIsLoggedIn(false)
        }
        else
        {
            const userDetail= JSON.parse(localStorage.getItem('user'))
            dispatch(actionsApi?.loginResponse(userDetail))
            setIsLoggedIn(true)
        }
    },[])
    return (
        <>
            <Router>   
                <ScrollToTop/>
                <Navbar />
                <Routes>
                    <Route 
                        path="/" 
                        element={
                            <Protected isLoggedIn={isLoggedIn}>
                                <Store />
                            </Protected>
                        }
                        />
                    <Route path="/signup" element={<Signuppage />}/>
                    <Route path="/store" element={<Store />}/>
                    <Route 
                        path="/item-detail/:itemID"
                        element={
                            <Protected isLoggedIn={isLoggedIn}>
                                <ItemDetail />
                            </Protected>
                        }
                        />
                    <Route 
                        path="/collection" 
                        element={
                            <Protected isLoggedIn={isLoggedIn}>
                                <Collection />
                            </Protected>
                        }
                        />
                    <Route 
                        path="/collection-detail/:itemID" 
                        element={
                            <Protected isLoggedIn={isLoggedIn}>
                                <CollectionItemDetail />
                            </Protected>
                        }
                        />
                    <Route 
                        path="/tracklist" 
                        element={
                            <Protected isLoggedIn={isLoggedIn}>
                                <Music />
                            </Protected>
                        }
                        />
                    <Route 
                        path="/marketplace" 
                        element={
                            <Protected isLoggedIn={isLoggedIn}>
                                <MarketPlace />
                            </Protected>
                        }
                        />
                    <Route path="/marketplace">
                        <Route 
                            path=":marketplaceID/store/:storeID" 
                            element={
                                <Protected isLoggedIn={isLoggedIn}>
                                    <MarketPlaceItemDetail />
                                </Protected>
                            } 
                            />
                    </Route>
                    <Route path='/artist' element={<ArtistPage/>}/>
                    <Route path="/artist">
                        <Route 
                            path=":ID" 
                            element={
                                <Protected isLoggedIn={isLoggedIn}>
                                    <ArtistProfile />
                                </Protected>
                            }
                            />
                    </Route>
                    <Route 
                        path="/user-profile" 
                        element={
                            <Protected isLoggedIn={isLoggedIn}>
                                <UserProfile />
                            </Protected>
                        }
                        />
                    <Route 
                        path="/cart" 
                        element={
                            <Protected isLoggedIn={isLoggedIn}>
                                <Cart />
                            </Protected>
                        }
                        />
                    <Route 
                        path="/checkout" 
                        element={
                            <Protected isLoggedIn={isLoggedIn}>
                                <Checkout />
                            </Protected>
                        }
                        />
                    <Route 
                        path="/subscribe/:artistID" 
                        element={
                            <Protected isLoggedIn={isLoggedIn}>
                                <Subscribe />
                            </Protected>
                        }
                        />
                    <Route path="/forgot-password" element={<ForgotPassword />}/>
                    <Route path="/faqs" element={<Faqs/>}/>
                </Routes>
                <Footer/>
                <AudioPlayer/>
            </Router>    
        </>
    )
}

export default Routing
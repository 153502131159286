import { domainUrl4 } from "../../constants/apiconstans/domanUrl"
import { Alert } from "../../../components"
export const sellItem = (formData)=>{
    const userToken = localStorage.getItem('token')
    var myHeaders = new Headers()
    myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
    myHeaders.append("token", userToken || null)
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: formData
    };
   return (
        fetch(domainUrl4 + `/putOnSale`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result?.ResponseCode)
                {
                    Alert('success', result?.ResponseMsg)
                    return 1
                }
            else
                throw result?.ResponseMsg
        })
        .catch(error => {
            Alert("error", error) 
            return 0
        })
   )
}
export const RemoveItemFromSale = (formData)=>{
    const userToken = localStorage.getItem('token')
    var myHeaders = new Headers()
    myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
    myHeaders.append("token", userToken || null)
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: formData
    };
   return (
        fetch(domainUrl4 + `/removeFromSale`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result?.ResponseCode)
                {
                    Alert('success', result?.ResponseMsg)
                    return 1
                }
            else
                throw result?.ResponseMsg
        })
        .catch(error => {
            Alert("error", error) 
            return 0
        })
   )
}
import React, { useEffect, useState } from 'react'
import { Card, Space, Image, Typography, Pagination, Row, Col } from 'antd'
import PaginationCust from '../storecomp/PaginationCust';
import { ItemCard, NotFound404 } from '../';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
const { Title} = Typography

const MarketplaceCards = () => {
    const {data, pagination}= useSelector(state => state?.marketPlace)
    const [userID, setUserID]= useState(null)
    useEffect(()=>{
        let user_id= localStorage?.getItem("userId")
        setUserID(user_id)
    },[])
  return (
    <>
        {
            <div className='gradient-bg pt-5'>
                <Row gutter={[16,16]}>
                    {
                        data?.map((dat,dt)=>
                        <Col xs={24} sm={12} md={12} lg={8} key={'isFav-'+dt}>
                            <ItemCard 
                                data={dat}
                                to={'/marketplace/'+dat?.marketplace_id+'/store/'+dat?.store_id}
                                userID={userID}
                                />
                        </Col>
                        )
                    }
                    {
                        pagination?.totalRecords>0 &&
                        <Col span={24}>
                            <Pagination 
                                total={pagination?.totalRecords} 
                                defaultPageSize={6} 
                                current={pagination?.currentPage}
                               // onChange={(value)=>{getMyCollection(value)}} 
                                />
                        </Col>
                    }
                </Row>
            </div>
        }
    </>
  )
}
export {MarketplaceCards}
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl} from '../../../constants/apiconstans/domanUrl'
import { checkAuthorization } from '../../../helpers'
import { message } from 'antd'

export const getPlaylist = createAsyncThunk('Playlist',
    async (playlist_type, { dispatch, getState }) => {

        const {userToken, key, user_id}=checkAuthorization()
        dispatch(gettingPlaylist())
        var myHeaders = new Headers()
        myHeaders.append("key", key)
        myHeaders.append("token", userToken)
        let formData = new FormData()
        formData.append(`user_id`,user_id)
        formData.append(`playlist_type`, playlist_type || null)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: formData
        }
        fetch(domainUrl + `/getTrackList`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode)
                    dispatch(playlistResponse(result))
                else
                    throw 'error while getting playlist'
            })
            .catch(error => {
                dispatch(playlistClear())
                message.error(error) 
            })
    }
)

const playlistSlice = createSlice({
    name: 'playlist',
    initialState: {
        data: null,
        loading: false
    },
    reducers: {
        gettingPlaylist: (state) => {
            state.loading = true
        },
        playlistResponse: (state, action) => {
            state.data = action.payload
            state.loading= false
        },
        playlistClear: (state) => {
            state.data = null
            state.loading = false
        }
    }
})

export const {gettingPlaylist, playlistResponse, playlistClear} = playlistSlice.actions
export default playlistSlice.reducer
import React from 'react';
import {Space, Button, Typography} from 'antd'
import './singledetail.css'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { ProfilePicture, SocialMedias } from '../ArtistProfile';

const { Title} = Typography


const SingleSocialSec = () => {

    const { t }= useTranslation()
    const {data, loading}= useSelector(state => state?.itemDetail)

    return (
        <div className='justify-center'>
            <div className='width-93 py-3'>
                <Space 
                    className='center w-100' 
                    direction='vertical'
                    size={15}
                    >
                    <Title level={1} className='my-0'>
                        {data?.artist_dtl?.name}
                    </Title>
                    <ProfilePicture
                        loading={loading}
                        artistProfile={data?.artist_dtl}
                        isItemDetailPage
                    />
                    <p className='light-font-dim width-976 text-center my-0'>
                        {
                            data?.artist_dtl?.description
                        } 
                    </p>
                    <SocialMedias
                        loading={loading}
                        data={data?.artist_dtl}
                    />
                    {
                        parseFloat(data?.artist_dtl?.subcribtion_fee)>0 &&
                        <NavLink to={'/artist/'+data?.artist_dtl?.artist_id}>
                            <Button 
                                type='primary'
                                size='large'
                                block
                                >
                                {t("See Profile")}
                            </Button>
                        </NavLink>
                    }
                </Space>
            </div>
        </div>
    )
}

export default SingleSocialSec
import { useSelector } from "react-redux"
import { Image, Space, Typography} from "antd"
import { useTranslation } from "react-i18next"
import "./index.css"
const {Title}= Typography

const BoosttaCollection= ()=>{
    const { t }= useTranslation()
    const {language}= useSelector(state => state?.app)
    
    return (
        <div className="flex-col-center" style={{background:'#fff'}}>
            <Space className="border-top-black width-93 pt-5 center" direction='vertical'>
                <Title 
                    level={1} 
                    className="my-0 text-center"
                    >
                    {
                        t("store third section first heading")
                    }
                </Title>
                <Image
                    src="/assets/img/Capture d’écran 2023-08-19 à 10.58.16.png"
                    alt="NFSounds"
                    preview={false}
                />
                <Title 
                    level={4} 
                    className="my-0 text-center width-1076"
                >
                    {
                        t("store third section first paragraph")
                    }
                </Title>
                <Title 
                    level={4} 
                    className="my-0 text-center width-1076"
                >
                    {
                        t("store third section second paragraph")
                    } 
                </Title>
                <div className="justify-center">
                    <div className="vertical-line"></div>
                </div>
                <Title 
                    level={1} 
                    className="my-0 text-center"
                >
                    {
                        t("store third section second heading")
                    }
                </Title>
                <Image
                    src="/assets/img/Design sans titre (9).png"
                    alt="NFSounds"
                    preview={false}
                />
                <Title 
                    level={4} 
                    className="my-0 text-center width-1076"
                >
                    {
                        t("store third section third paragraph")
                    }
                </Title>
                <Title 
                    level={4} 
                    className="my-0 text-center width-1076"
                >
                    {
                        t("store third section forth paragraph")
                    }
                </Title>
                <div className="justify-center">
                    <Space size={20}>
                        <div className="vertical-line"></div>
                        <div className="vertical-line"></div>
                    </Space>
                </div>
                <Title 
                    level={1} 
                    className="my-0 text-center"
                >
                    {
                        t("store third section third heading")
                    }
                </Title>
            </Space>
            {
                language === 'fr' ?
                <Image  
                    src='/assets/img/Capture d’écran 2023-08-19 à 10.11.57.png'
                    alt="NFSounds"
                    preview={false}
                    width={'100%'}
                />
                :
                <Image  
                    src='/assets/img/Capture d’écran 2023-08-19 à 10.14.57.png'
                    alt="NFSounds"
                    preview={false}
                    width={'100%'}
                />
            }
            <Space className="width-93 pb-5 center" direction='vertical'>
                <Title 
                    level={4} 
                    className="my-0 text-center width-1076"
                >
                    {
                        t("store third section fifth paragraph")
                    }
                </Title>
                <Title 
                    level={4} 
                    className="my-0 text-center width-1076"
                >
                    {
                        t("store third section sixth paragraph")
                    }
                </Title>
                <div className="justify-center">
                    <Space size={20}>
                        <div className="vertical-line"></div>
                        <div className="vertical-line"></div>
                    </Space>
                </div>
                <Title 
                    level={1} 
                    className="my-0 text-center"
                >
                    {
                        t("store third section forth heading")
                    }
                </Title>
                <Image
                    src="/assets/img/Design sans titre (19).png"
                    alt="NFSounds"
                    preview={false}
                />
                <Title 
                    level={4} 
                    className="my-0 text-center width-1076"
                >
                    {
                        t("store third section seventh paragraph")
                    }
                </Title>
                <Title 
                    level={2} 
                    className="my-0 mt-3 text-center width-1076"
                >
                    {
                        t("store third section eight paragraph")
                    }
                </Title>
            </Space>
        </div>
    )
}
export {BoosttaCollection}
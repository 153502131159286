import {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import{ Row, Col, Card, Typography, Skeleton, Avatar} from 'antd'
import { AutoCompleteInput } from '../'
import Slider from 'react-slick'
import { actionsApi } from '../../shared'
import { ArtistCard } from '../ArtistComponents'
import './artistp.css'
const { Title } = Typography

export const ArtistprofilteTopSearch = () => {
    const dispatch= useDispatch()
    const {data, loading}= useSelector(state => state?.Artists)
    const [state, setState]= useState([])
    useEffect(() => {
        let user_id= localStorage?.getItem("userId")
        let formData = new FormData()
        formData.append(`user_id`,user_id || null)
        dispatch(actionsApi?.getArtistsList({formData, pageNo: 1}))
    }, [])
    useEffect(()=>{
        if(data)
        setState([
            ...data?.map(artist=> ({...artist?.artistDetails}))
        ])
    },[data])
    var businesspartner = {
        dots: false,
        infinite: state?.length>4,
        speed: 800,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        autoplay:false,
        responsive: [
          {
            breakpoint: 1150,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 1068,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 986,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            }
          },
          {
            breakpoint: 860,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 755,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 530,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
        ]
      }

  return (
    <div className='flex-col-center'>
        <div className='width-91 py-3'>
            <Row gutter={[16,16]}>
                <Col lg={{span:6}} sm={{span:24}} xs={{span:24}} className='align-center'>
                    <div style={{width:'100%'}}>
                        <AutoCompleteInput />
                    </div>
                </Col>
                <Col lg={{span:18}} sm={{span:24}} xs={{span:24}} className='align-center'>  
                    {
                        loading ?
                        <div className='width-100'>
                            <Slider {...businesspartner}>
                                {
                                    new Array(4)?.fill(null)?.map((_, index)=>
                                        <div className='w-100 m-0'>
                                            <div className='px-2'>
                                                <Skeleton.Button 
                                                    active 
                                                    size='large' 
                                                    shape={'square'} 
                                                    block
                                                    style={{width:'100%', height:'65px'}}
                                                    className='radius-6'
                                                    key={'skelton-'+index}
                                                    />
                                            </div>
                                        </div>
                                    )
                                }
                            </Slider>
                        </div>
                        :
                        <div className='width-100'>
                            <Slider {...businesspartner}>
                                {
                                  state?.map((artist, index)=>
                                  <div
                                    key={'artist-'+index}
                                    onClick={()=>{
                                        window.location.href=`/artist/${artist?.artist_id}`
                                    }}
                                    >
                                    <ArtistCard
                                      artist={artist}
                                      artistProfile
                                      />
                                  </div>
                                )}
                            </Slider>
                        </div>
                    }
                </Col>
            </Row>
        </div>
        <div className='width-90 border-bottom-black'></div>
    </div>
  )
}


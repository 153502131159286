import React, { useEffect, useState } from 'react'
import { Row, Col, Typography, Card, Space, Input, Button, Form} from 'antd'
import { getArtistChat, sendMesage} from '../../shared'
import Pusher from "pusher-js"
import { SendOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
const { Title } = Typography

const ArtistLiveChat = ({artist_id}) => {
    const {t}= useTranslation()
    const [state, setState]= useState([])
    const [loading, setLoading]= useState(false)
    const [form] = Form.useForm()
    useEffect(() => {
        const pusher = new Pusher('c990100ab2e049d3a02a', {
          cluster: 'ap2',
        });
        var channel = pusher.subscribe('sendMessageChannel');
        channel.bind('sendMessageTest', (data)=> {
             console.log("message:", data)
        })
    }, [])
    useEffect(()=>{
        if(artist_id)
        {
            get()
        }
    },[artist_id])
    console.log("state:", state)
    const get=async()=>{
      let result=await  getArtistChat(artist_id?.artist_id)
      setState([...result])
      
    }
    useEffect(()=>{
        if(state?.length)
        {
            var element = document.getElementsByClassName("spacheight")[0]
            element.scrollTop = element.scrollHeight;
        }
    }, [state])
    const send=async ()=>{
        setLoading(true)
        let message= form.getFieldValue("message")
        form.resetFields()
        await sendMesage({
            user_id: parseInt(localStorage?.getItem("userId")),
            artist_id: parseInt(artist_id?.artist_id),
            message
        })
        setLoading(false)
        get()
    }
  return (
    <div>
        <Row gutter={[16,16]}>
            <Col span={24}>
                <Card className='shadow-2 radius-6'>
                    <Space direction='vertical' size={10} className='spacheight'>
                        {
                            state?.map((dat,d)=>
                            <Space size={10} key={d}>
                                <Title level={3} className='brand-color m-0'>
                                    {
                                        dat?.message?.message
                                    }
                                </Title>
                                <Title level={4} className='m-0'>
                                    {
                                       '(' + dat?.userDetails?.userName + ")"
                                    }
                                </Title>
                            </Space>
                            )
                        }
                    </Space>
                    <Form 
                        layout="vertical"
                        form={form}
                        initialValues={true}
                        onFinish={send}
                    >
                        <Form.Item
                            name={'message'}
                            label={null}
                            rules={[
                                {
                                    required: true,
                                    message: `${t('please enter message')}`,
                                }
                            ]}
                            >
                            <Space 
                                className='w-100 borderGold spacfirst radius-6' 
                                style={{alignItems:'flex-end'}}
                                >
                                <Input 
                                    type={'text'}
                                    placeholder={t('Write a message')+"..."} 
                                    value={form.getFieldValue('message')}
                                    className='border0 w-100' 
                                    style={{background: 'transparent'}}
                                    />
                                <Button 
                                    loading={loading}
                                    htmlType='submit'
                                    icon={<SendOutlined />}
                                    className="post-like-button"
                                    >
                                </Button>
                            </Space>
                        </Form.Item> 
                    </Form>
                </Card>

            </Col>
        </Row>
    </div>
  )
}

export default ArtistLiveChat
import { useEffect, useState } from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { useTranslation } from 'react-i18next'
import {Space, Button, Image, Skeleton, Dropdown} from "antd"
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import Slider from 'react-slick'
import { actionsApi, removeMyPlaylist } from '../../shared'
import { MusicTable, PlaylistModal, PlaylistRenameModal} from '../../components/MusicComponents'
import "./index.css"

const Music = () => {

  const {t}= useTranslation()
  const dispatch= useDispatch()
  const {data : playlists, loading}= useSelector(state => state?.playlists)
  const [playlist_type, setPlaylistType]= useState(null)
  const [visiblePlaylistModal, setVisiblePlaylistModal]= useState(false)
  const [visiblePlaylistRenameModal, setVisiblePlaylistRenameModal]= useState(false)
  const [oldPlaylistName, setOldPlaylistName]= useState(null)
  const [current, setCurrent]= useState(0)
 
  useEffect(()=>{
    dispatch(actionsApi?.getPlaylists())
  },[])
  //
  var businesspartner = {
    dots: false,
    infinite: playlists?.length>4,
    speed: 800,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    autoplay:false,
    
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 855,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        }
      },
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 569,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  }
  const removePlaylist= async (playlistName)=>{
    let user_id= localStorage?.getItem("userId")
    let formData = new FormData()
    formData.append(`user_id`, user_id || null)
    formData.append(`playlist_name`, playlistName)
    const result= await removeMyPlaylist(formData)
    if(result)
      dispatch(actionsApi?.playlistsResponse(playlists?.filter(playlist => playlist?.name!== playlistName)))
  }
  return (
    <div>
        <div className='justify-center'>
            <Space direction='vertical' className='width-90'>
                <div className='playlist py-4 border-bottom-black'>
                  <div>
                    <Space>
                        <Button 
                            onClick={()=>{setCurrent(0);dispatch(actionsApi?.getMyCollectionMusic())}}
                            size='large'
                            type={current===0?'primary':'default'}
                            disabled={loading}
                            >
                              <Space>
                                  {
                                    current===0?
                                    <Image 
                                      src='/assets/video-white.png' 
                                      alt='NFsounds'
                                      preview={false}
                                      />:
                                    <Image 
                                      src='/assets/video-black.png' 
                                      alt='NFsounds'
                                      preview={false}
                                      />
                                  }
                                  {t("All Music")}
                                </Space>
                        </Button>
                        <Button 
                            onClick={()=>{setVisiblePlaylistModal(true);setCurrent(1)}}
                            size='large'
                            type={current===1?'primary':'default'}
                            disabled={loading}
                            >
                              {t("Create Playlist")}
                        </Button>
                    </Space>
                  </div>
                  <div>
                    {
                        loading ?
                        <div className='width-100'>
                            <Slider {...businesspartner}>
                                {
                                    new Array(8)?.fill(null)?.map((_, index)=>
                                        <div className='w-100 m-0'>
                                            <div className='px-2'>
                                                <Skeleton.Button 
                                                    active 
                                                    size='large' 
                                                    shape={'square'} 
                                                    block
                                                    style={{width:'100%', height:'40px'}}
                                                    className='radius-6'
                                                    key={'skelton-'+index}
                                                    />
                                            </div>
                                        </div>
                                    )
                                }
                            </Slider>
                        </div>
                        :
                        <div className='width-100'>
                            <Slider {...businesspartner}>
                                {
                                  playlists?.map(( playlist, index)=>
                                    <div key={'playlist-'+index}>
                                      <div className='px-2'>
                                        <Dropdown.Button
                                          menu={{
                                            items:[
                                              {
                                                key: '1',
                                                label: <DeleteOutlined className='red-5' onClick={()=> removePlaylist(playlist?.name)}/>,
                                              },
                                              {
                                                key: '2',
                                                label: <EditOutlined className='secondary-color' onClick={()=> {setOldPlaylistName(playlist?.name); setVisiblePlaylistRenameModal(true)}}/>,
                                              }
                                            ]
                                          }}
                                          className='playlist-button'
                                          onClick={()=>{
                                              setCurrent(index+2)
                                              setPlaylistType(playlist?.name)
                                              dispatch(actionsApi?.getPlaylist(playlist?.name))
                                          }}
                                          type={current===(index+2)?'primary':'default'}
                                          size='large'
                                          >
                                          {
                                            playlist?.name
                                          }
                                        </Dropdown.Button>
                                      </div>
                                    </div>
                                  )
                                }
                            </Slider>
                        </div>
                    }
                  </div>
                </div>
                <div>
                    <MusicTable 
                        playlist_type={playlist_type}
                    />
                </div>
            </Space>
      </div>
      <PlaylistModal
        visible={visiblePlaylistModal}
        onClose={()=>{setVisiblePlaylistModal(false)}}
        createPlaylist
        />
      <PlaylistRenameModal
        visible={visiblePlaylistRenameModal}
        old_playlist_name={oldPlaylistName}
        onClose={()=> setVisiblePlaylistRenameModal(false)}
        />
    </div>
  )
}

export {Music}
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl2} from '../../../constants/apiconstans/domanUrl';
import { Alert } from '../../../../components';
export const getArtistCartList = createAsyncThunk('ArtistCart',
    async (data, { dispatch, getState }) => {
        dispatch(gettingArtistCart())
        const userToken = localStorage.getItem('token')
        let user_id= localStorage?.getItem("userId")
        var myHeaders = new Headers()
        myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
        myHeaders.append("token", userToken || null)
        let formData = new FormData()
        formData.append(`user_id`,user_id || null)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: formData
        };
        fetch(domainUrl2 + `/getCartList`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode)
                    dispatch(artistCartResponse(result))
                else
                {
                    dispatch(artistCartClear())
                    Alert("error", "error while getting artist cart list") 
                }
            })
            .catch(error => {
                dispatch(artistCartClear())
                Alert("error", error) 
            })
    }
)

const artistCartSlice = createSlice({
    name: 'artistCart',
    initialState: {
        data: null,
        loading: false
    },
    reducers: {
        gettingArtistCart: (state, action) => {
            state.loading = true;
        },
        artistCartResponse: (state, action) => {
            state.data = action.payload;
            state.loading= false;
        },
        artistCartClear: (state, action) => {
            state.data = null;
            state.loading = false;
        },
    },
})

export const {gettingArtistCart, artistCartResponse, artistCartClear} = artistCartSlice.actions
export default artistCartSlice.reducer
import {Avatar, Space, Typography} from "antd"
import { useEffect, useState } from "react"
import { ViewPost } from "../ArtistProfile"
import { NFMediaPlayer } from "../../pages"
import { LikeSection } from "./LikeSection"
import { CommentSection } from "./CommentSection"
import "./index.css"
const {Title, Text}= Typography
export const PostCard = ({feed, data, isPost})=>{
    const [visiblePost, setVisiblePost]= useState(false)
    const [post, setPost]= useState(false)
    useEffect(()=>{
        if(feed)
        {
            videoWidth()
            window.addEventListener('resize', videoWidth)
        }
    }, [feed])
    const videoWidth= ()=>{
        let videoCover= document.getElementsByClassName("feeds-video")
        let video= document.getElementsByTagName("video")
        if(video)
        {
            for(let i=0; i<video?.length;i++)
                video[i].style.height=`${videoCover[i]?.offsetHeight}px`
        }
    }
    return (
        <>
        <div 
            className='radius-6 shadow-1 h-100 post-card'
            onClick={(e)=>{
                e.stopPropagation()
                setVisiblePost(true)
                setPost(feed)
            }}
            >                        
            <div  
                className='feeds-media center inner-shading'
                style={feed?.feed_type==='video'?{}:{backgroundImage:`url('${feed?.image}')`}}
                >
                {
                    feed?.feed_type==='video' ?
                    <div 
                        className="feeds-video" 
                        onClick={e=> e.stopPropagation()}
                        >
                        <NFMediaPlayer 
                            src={feed?.image} 
                            controls={true}
                            thumbnail={feed?.thumbnail}
                            />
                    </div>:''
                } 
            </div>
            <Space 
                size={5} 
                direction='vertical' 
                className="py-3 px-3 w-100"
                >
                <div className="space-between-no-wrap w-100">
                    <Space>
                        <Avatar
                            size={40} 
                            shape='square' 
                            src={feed?.artist_image || '/assets/img/logo.png'} 
                            alt='nf sounds'
                            className="inner-shading"
                            />
                        <Title 
                            className='m-0' 
                            level={4} 
                            ellipsis
                            style={{maxWidth:'200px'}}
                            >
                            {
                                feed?.user?.username || 'Nfsounds'
                            }
                        </Title>
                    </Space> 
                    <LikeSection 
                        feed={feed} 
                        data={data} 
                        isPost={isPost}
                        />
                </div>
                <Text strong ellipsis className="w-100">
                    {
                        feed?.feed_text
                    }
                </Text>
                <CommentSection
                    feed={feed}
                    isPost={isPost}
                    />
            </Space>
        </div>
        <ViewPost
            visible={visiblePost}
            post={post}
            feed={feed}
            isPost={isPost}
            onClose={()=>{setVisiblePost(false);}}
            />
        </>
    )
}
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl'
import { Alert } from '../../../../components'
export const getFeedComments= createAsyncThunk('feedComments',
    async (formData, { dispatch, getState }) => {
        dispatch(gettingFeedComments())
        let userToken=localStorage.getItem('token')
        var myHeaders = new Headers();
        myHeaders.append("token", `${userToken}`)
        myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
        var requestOptions = {
            method: 'POST',
            headers:myHeaders,
            redirect: 'follow',
            body: formData
        }
        fetch(domainUrl + `/getCommentList`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode===1)
                    dispatch(feedCommentsResponse(result?.data))
                else
                {
                    dispatch(feedCommentsClear())
                    throw result?.ResponseMsg
                }
            })
            .catch(error => {
                dispatch(feedCommentsClear())
                Alert("error", error) 
            })
    }
)

const feedCommentsSlice = createSlice({
    name: 'feedComments',
    initialState: {
        data: [],
        loading: false
    },
    reducers: {
        gettingFeedComments: (state, action) => {
            state.loading = true
        },
        feedCommentsResponse: (state, action) => {
            state.data = action.payload
            state.loading= false
        },
        feedCommentsClear: (state, action) => {
            state.data = []
            state.loading = false
        }
    },
})

export const {
    gettingFeedComments, 
    feedCommentsResponse, 
    feedCommentsClear,
} = feedCommentsSlice.actions
export default feedCommentsSlice.reducer
import moment from 'moment'
const utcToLocal=(utcDateString)=>{
   
const utcMoment = moment.utc(utcDateString)
const localMoment = utcMoment.local()
return localMoment.format('YYYY-MM-DDTHH:mm:ss')
}
const dateWithTime=(date)=>{
    return (
        date?
        moment(date).format('YYYY-MM-DDTHH:mm:ss'):  moment().format('YYYY-MM-DDTHH:mm:ss')
    )
}
const LLFormat=(date)=>{
    return (
        date?
        moment(date).format('LL'):  moment().format('LL')
    )
}
const LLFormatWithTime=(date)=>{
    return (
        date?
        moment(date).format('LL HH:mm:ss'):  moment().format('LL HH:mm:ss')
    )
}
const onlyTime=(date)=>{
    return (
        date?
        moment(date).format('HH:mm:ss'):  moment().format('HH:mm:ss')
    )
}
const renderer = ({days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return '00:00:00';
    } else {
      // Render a countdown
      return <span>{days}D {hours}H {minutes}M {seconds}S</span>;
    }
  }
export {dateWithTime, LLFormat, LLFormatWithTime, onlyTime, renderer, utcToLocal}

import { Alert } from "../../../components"
import { domainUrl } from "../../constants/apiconstans/domanUrl"
import {key} from "../../"
const searchArtist=(data)=>{

    let userToken=localStorage.getItem('token')
    let user_id= localStorage?.getItem("userId")
    var myHeaders = new Headers();
    myHeaders.append("token", `${userToken}`)
    myHeaders.append("key", key)
    let formData = new FormData()
    formData.append(`user_id`,user_id || null)
    myHeaders.append("content-type", `application/json`)
    var requestOptions = {
      headers: myHeaders,
      method: 'POST',
      redirect: 'follow',
      body: JSON.stringify(data)
    }
    return (
      fetch(domainUrl+"/SearchArtist",requestOptions)
        .then(response=>response.json())
        .then(result=>{
            if(result?.ResponseCode)
                return result?.data
            else       
                throw result?.message
        })
        .catch((error)=>{
            Alert("error",error)
            return 0
        })
    )
}
const artistProfile=(data)=>{
    let userToken=localStorage.getItem('token')
    var myHeaders = new Headers();
    myHeaders.append("token", `${userToken}`)
    myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
    myHeaders.append("content-type", `application/json`)
    var requestOptions = {
      headers: myHeaders,
      method: 'POST',
      redirect: 'follow',
      body: JSON.stringify(data)
    }
    return (
      fetch(domainUrl+`/artistProfile`,requestOptions)
        .then(response=>response.json())
        .then(result=>{
            if(!result?.success)
                return result?.data
            else       
                throw 'error while getting artist data'
        })
        .catch((error)=>{
            Alert("error",error)
            return 0
        })
    )
}
const likePost=(data)=>{
    let userToken=localStorage.getItem('token')
    var myHeaders = new Headers();
    myHeaders.append("token", `${userToken}`)
    myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
    myHeaders.append("content-type", `application/json`)
    var requestOptions = {
      headers: myHeaders,
      method: 'POST',
      redirect: 'follow',
      body: JSON.stringify(data)
    }
    return (
      fetch(domainUrl+`/likePost`,requestOptions)
        .then(response=>response.json())
        .then(result=>{
           return result
        })
        .catch((error)=>{
            Alert("error",error)
            return 0
        })
    )
}
const commentOnPost=(data)=>{
  let userToken=localStorage.getItem('token')
  var myHeaders = new Headers();
  myHeaders.append("token", `${userToken}`)
  myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
  myHeaders.append("content-type", `application/json`)
  var requestOptions = {
    headers: myHeaders,
    method: 'POST',
    redirect: 'follow',
    body: JSON.stringify(data)
  }
  return (
    fetch(domainUrl+`/postComment`,requestOptions)
      .then(response=>response.json())
      .then(result=>{
         if(result?.ResponseCode)
          return 1
         else
          throw "error while adding comment...try again"
      })
      .catch((error)=>{
          Alert("error",error)
          return 0
      })
  )
}
export {searchArtist, artistProfile, likePost, commentOnPost}
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl'
export const getFeeds= createAsyncThunk('Feeds',
    async (data, { dispatch, getState }) => {
        dispatch(gettingFeeds())
        let userToken=localStorage.getItem('token')
        let user_id= localStorage?.getItem("userId")
        var myHeaders = new Headers();
        myHeaders.append("token", `${userToken}`)
        myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
        let formData = new FormData()
        formData.append(`user_id`,user_id || null)
        var requestOptions = {
            method: 'POST',
            headers:myHeaders,
            redirect: 'follow',
            body: formData
        }
        fetch(domainUrl + `/getFeed`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode === 1)
                    dispatch(feedsResponse(result?.data?.map(feed =>{
                        return ({
                            ...feed,
                            IsLike: parseInt(feed?.IsLike),
                            like_count: parseInt(feed?.like_count),
                            comment_count: parseInt(feed?.comment_count)
                        })
                    })))
                else if(result?.ResponseCode === 0)
                    dispatch(feedsResponse(-1))
                else
                {
                    dispatch(feedsClear())
                    throw result?.ResponseMsg
                }
            })
            .catch(error => {
                dispatch(feedsClear())
                console.error(error)
            })
    }
)

const feedsSlice = createSlice({
    name: 'feeds',
    initialState: {
        data: null,
        loading: false
    },
    reducers: {
        gettingFeeds: (state) => {
            state.loading = true
        },
        feedsResponse: (state, action) => {
            state.data = action.payload
            state.loading= false
        },
        feedsClear: (state) => {
            state.data = null
            state.loading = false
        },
        likeUnlikeOnFeed: (state, action)=> {
            let index= state?.data?.findIndex(feed=> feed?.feed_id=== action.payload)
            if(index>-1)
            {
                let feed= state.data[index]
                state.data[index]={
                    ...feed,
                    like_count: feed?.IsLike ? feed?.like_count-1 : feed?.like_count+1,
                    IsLike: !feed?.IsLike
                }
            }
            
        },
        commentOnFeed: (state, action)=> {
            let index= state?.data?.findIndex(feed=> feed?.feed_id=== action.payload)
            if(index>-1)
            {
                let feed= state.data[index]
                state.data[index]={
                    ...feed,
                    comment_count: feed?.comment_count+1
                }
            }
            
        }
    },
})

export const {
    gettingFeeds, 
    feedsResponse, 
    feedsClear, 
    likeUnlikeOnFeed,
    commentOnFeed
} = feedsSlice.actions
export default feedsSlice.reducer
import { Image, Typography, Space, Dropdown,Menu, Button, Avatar, Divider} from "antd"
import { ClockCircleOutlined} from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { actionsApi } from "../../shared"
import { NavLink } from "react-router-dom"
const {Title, Text, Paragraph}= Typography

export const  Notifications =()=>{
    const dispatch= useDispatch()
    const {data, count}= useSelector(state=> state?.notifications)

    useEffect(()=>{
        dispatch(actionsApi?.showAllNotifications())
    }, [])

    const notificationsClear= (isOpen)=>{
        if(!isOpen)
           dispatch(actionsApi?.notificationsClear())
        else
            dispatch(actionsApi?.clearNotifications())
    }

    const Notification = (
        <Menu 
          className="px-3"
          style={{
            transform:'translateY(10)',
            maxHeight:'50vh',
            overflowY: 'auto'
          }}

          items={[
            {
              label: <>
                        {
                            data?.length ? 
                            <>
                                <Title level={4} className="my-0">Notifications</Title>
                                <Divider className="my-1"/>
                            </> :
                            <Title level={5} className="my-1">Nothing's new</Title>
                        }
                      </>,
              key: '0',
            },
            ...data?.map((notification, index)=>(
                {
                    label: (
                    <NavLink 
                        to={
                            notification?.artist_id ? '/artist/'+notification?.artist_id :
                            notification?.store_id ? '/item-detail/'+notification?.store_id :
                            '/'
                        }
                        >
                        <Space 
                            size={20} 
                            >
                            <Avatar
                                src={notification?.image || 'assets/icons/bell.png' }
                                shape='circle'
                                size={43}
                                alt='NFSound notification'
                                /> 
                            <Space direction='vertical' size={1} style={{width:'320px'}}>
                                <Paragraph strong className="my-0" ellipsis={{rows:2}}>
                                    {
                                        notification?.description
                                    }
                                </Paragraph>
                                <Text strong className="my-0" ellipsis>
                                    {
                                        notification?.name
                                    }
                                </Text>
                                <Space>
                                    <ClockCircleOutlined className="up"/>
                                    <Text strong className="my-0">
                                        {
                                            notification?.time
                                        }
                                    </Text>
                                </Space>
                            </Space>
                        </Space>
                    </NavLink>
                    ),
                    key: index+ 1,
                }
            ))
            ]}
        />
      );
    return(
        <Dropdown 
            overlay={Notification} 
            trigger={['click']}
            placement="bottom"
            onOpenChange={(isOpen)=> notificationsClear(isOpen)}
            arrow
         >
            <Button size='middle' shape='circle'>
                    <Image src='/assets/img/notifyicon.png' width={'20px'} preview={false} />
            </Button>
        </Dropdown>
    )
}
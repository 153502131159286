import React,{ useEffect, useState } from 'react'
import {Pagination, Row, Col} from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { CardSkeleton } from '../'
import { actionsApi } from '../../shared'
import { updateFavorite } from '../../shared'
import { Alert, Cart, DataNotFound, ItemCard} from '../'
import { getUserID } from '../../shared'
import moment from 'moment'

const StoreCard = ({isFav}) => {
    const [ visibleCart, setVisibleCart ] = useState(false)
    const {data, filters, pagination,loading} = useSelector(state => state?.storeItems)
    const dispatch= useDispatch()
    const [state, setState]= useState(null)
   
    useEffect(()=>{
        if(data)
        {
            if(isFav)
                setState([...data?.filter(_=>parseInt(_?.is_Favourite))])
            else
            setState([...data])
        }
    },[data])
    const call=(pageNo=1, pageSize=6)=>{
        let formData = new FormData()
        formData.append(`user_id`, getUserID())
        for (const property in filters) 
            {
                if(filters[property]===0 || filters[property]===null)
                    continue
                formData.append(property, filters[property])
            }
        dispatch(actionsApi?.getAllStoreItems({formData, pageNo, pageSize}))
    }
   const UpdateFavorite=async (store_id, is_Favourite)=>{
    let user_id= localStorage?.getItem("userId")
    if(!user_id)
    {
        Alert("error", "please login in")
        return
    }
    dispatch(actionsApi?.storeResponse([...state?.map(_=>{
        if(parseInt(_?.store_id)===parseInt(store_id))
        return (
            {
                ..._,
                is_Favourite:is_Favourite?'1':'0'
            }
        )
        return _
    })]))
    let formData = new FormData()
    formData.append(`user_id`, user_id)
    formData.append(`store_id`, store_id)
    formData.append('dateTime', moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss'))
    await updateFavorite(formData)    
   }
  return (
    <div>
        {   loading && data===null ?
            <Row gutter={[16,16]}>
                {
                    new Array(6)?.fill(null)?.map((_, index)=>
                        <Col 
                            xs={24} sm={12} md={12} lg={8} 
                            key={'collection-item-'+index}
                            >
                            <CardSkeleton/>
                        </Col>
                    )
                }
            </Row>
            :
            !state?.length ?
            <DataNotFound alt='NFSounds no store nft found'/>
            :
            <Row gutter={[32, 32]}>
                {
                    state?.map((dat,dt)=>
                    <Col xs={24} sm={12} md={12} lg={8} key={'isFav-'+dt}>
                        <ItemCard 
                            store
                            data={{store_dtl:dat}}
                            to={'/item-detail/'+dat?.store_id}
                            UpdateFavorite={UpdateFavorite}
                            />
                    </Col>
                    )
                }

                <Col span={24}>
                    <div className='center'>
                        <Pagination 
                            total={pagination?.totalRecords} 
                            defaultPageSize={pagination?.pageSize} 
                            current={pagination?.pageNo}
                            onChange={(pageNo, pageSize)=> call(pageNo, pageSize)} 
                            />
                    </div>
                </Col>
            </Row>
        }
        <Cart 
            visible={visibleCart} 
            onClose={()=>setVisibleCart(false)} 
            />
            
    </div>
  )
}

export default StoreCard

import React, { useEffect, useState } from 'react'
import { Avatar, Space, Typography, Button, Image, Skeleton} from 'antd'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from 'react-redux'
import { actionsApi, checkAuthorization, LLFormat, order, removeTrackFromPlaylist } from '../../../shared'
import { PlaylistModal } from '../'
import { DeleteOutlined} from '@ant-design/icons'
import { SwapModal } from '../'
import { useTranslation } from 'react-i18next'
import "./index.css"
import { DataNotFound } from '../../';
const { Text} = Typography


const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  
  const grid = 8;
  
  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    margin: `0 0 ${grid}px 0`,
    // background: isDragging ? "#D7C189" : "",
    ...draggableStyle,
    // width:'100%'
  });
  
  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? "lightgrey" : "",
    padding: grid,
    width: "100%",
  });
const MusicTable = ({playlist_type}) => {
    
    const {t}= useTranslation()
    const dispatch= useDispatch()
    const {data: collections, loading}= useSelector( state=>state?.collectionMusic)
    const {data: playlist}= useSelector(state => state?.playlist)
    const {isPlaying, currentTrack}= useSelector( state=>state?.track)
    const [state, setState]= useState([])
    const [visiblePlaylistModal, setVisiblePlaylistModal]= useState(false)
    const [visibleSwapModal, setVisibleSwapModal]= useState(false)
    const [track, setTrack]= useState(null)
    const [trackID, setTrackID]= useState(null)

    useEffect(()=>{
        dispatch(actionsApi?.getMyCollectionMusic())
    }, [])
    useEffect(()=>{
        if(collections)
        {
            setState([
                ...collections?.filter(track=> track?.type==='video')?.map(track => ({...track, id: `${track?.store_id}`}))      
            ])
            //dispatch(actionsApi?.setTrackList([...collections?.filter(track=> track?.type==='video')]))
        }
    },[collections])

    useEffect(()=>{
        if(playlist)
        {
            if(playlist?.data)
            {
                dispatch(actionsApi?.collectionMusicClear())
                setState([
                    ...playlist?.data?.map(track => ({...track, id: `${track?.order}`}))
                ])
            }
        }
    },[playlist])
    
    const onDragEnd = async (result) => {
        if (!result.destination) return;
        const items = reorder(state, result.source.index, result.destination.index);
        setState([...items])
        const {user_id}= checkAuthorization()
        await order({
                user_id,
                playlist_type,
                order: items?.map(data => parseInt(data?.order))
            })
      };
    const togglePlayPause = () => {
        dispatch(actionsApi?.playTrack({isPlaying: !isPlaying, currentTrack}))
    }

    const removeTrack=async(track_id)=>{
        let user_id= localStorage?.getItem("userId")
        let formData = new FormData()
        formData.append(`user_id`,user_id || null)
        formData.append(`playlist_type`, playlist_type)
        formData.append(`track_id`, track_id)
        let result= await removeTrackFromPlaylist(formData)
        if(result)
            dispatch(actionsApi?.playlistResponse({
                ...playlist,
                data:[...state?.filter(track=> track?.track_id!==track_id)]
            }))
    }
  return (
    <div className='tableResponsive music-table'>
        <table className='w-100' cellPadding={7}>
            <thead className='border-bottom-black'>
                <td></td>
                <td>
                    <p className='my-0'>
                        {t("Title")}
                    </p>
                </td>
                <td className='hide-on-mobile'>
                    <p className='my-0'>
                        {t('Artist')}
                    </p>
                </td>
                <td className='hide-on-mobile'>
                    <p className='my-0'>
                        {t("Release date")}
                    </p>
                </td>
                {/* <td>
                    <Text>Time</Text>
                </td> */}
                <td></td>
            </thead>
            {
                loading && !collections ?
                <tbody>
                    {
                        new Array(4)?.fill(null)?.map((_, index)=>
                            <tr key={'track-'+index}>
                                <td colSpan={6}>
                                    <Skeleton.Button
                                        active 
                                        shape={'square'} 
                                        block
                                        style={{width:'100%', height:'55px'}}
                                        className='radius-6'
                                    />
                                </td>
                            </tr>
                        )
                    }
                </tbody> 
                :
                !state?.length ?
                <tbody>
                    <tr>
                        <td colSpan={6}>
                            <DataNotFound alt='NFSounds no tracks found'/>
                        </td>
                    </tr>
                </tbody>
                :
                collections ?
                <tbody>
                    {
                        state?.map(( track,index)=>(
                                <tr 
                                    key={'track-'+index} 
                                    className={index===currentTrack?'bgchange shadow-2 px-2 my-2':'px-2 my-2'} 
                                    onClick={(e)=>{
                                        e.stopPropagation()
                                        dispatch(actionsApi?.setTrackList([...state]))
                                        dispatch(actionsApi?.playTrack({isPlaying:true, currentTrack: index}))
                                    }}
                                >
                                    <td style={{borderRadius: '8px 0px 0px 8px'}}>
                                        <div className='inner-shading-avatar' style={{width:'55px'}}>
                                            <Avatar 
                                                shape='square' 
                                                size={55} 
                                                src={track?.type==='video' || track?.tracklist_type ? track?.thumbnail: track?.image} 
                                                alt='NFsounds'
                                                />
                                            <div></div>
                                        </div>
                                    </td>
                                    <td>
                                        <Text strong className={index===currentTrack?'text-white':''} >
                                            {track?.store_name || track?.tracklist_name}
                                        </Text>
                                        <p className='my-0 show-on-mobile'>{track?.artist_dtl?.name}</p>
                                    </td>
                                    <td className='hide-on-mobile'>
                                        <p className='my-0'>{track?.artist_dtl?.name}</p>
                                    </td>
                                    <td className='hide-on-mobile'>
                                        <p className='my-0'>
                                            {
                                                track?.rarity
                                            }
                                        </p>
                                    </td>
                                    <td className='hide-on-mobile'>
                                        <p className='my-0'>
                                            {
                                                LLFormat(track?.drop_date)
                                            }
                                        </p>
                                    </td>
                                    {/* <td>
                                        <Text>
                                            {
                                                onlyTime(track?.drop_date)
                                                
                                            }
                                        </Text>
                                    </td> */}
                                    <td className='w-100px' style={{borderRadius: '0px 8px 8px 0px'}}>
                                        {
                                            track?.store_name?
                                            <Button
                                                block
                                                size='large'
                                                type={currentTrack===index?"default":'primary'}
                                                onClick={(e)=>{
                                                    e.stopPropagation()
                                                    setVisiblePlaylistModal(true)
                                                    setTrack(track)
                                                }}
                                                className='ant-btn-responsive'
                                                >
                                                    <Text className={currentTrack===index?'text-black':'text-white'}>
                                                        {t("Add to playlist")}
                                                    </Text>
                                            </Button>:
                                            <Space>
                                                <Button
                                                    icon={<DeleteOutlined/>}
                                                    onClick={(e)=>{
                                                        e.stopPropagation()
                                                        removeTrack(track?.track_id)
                                                    }}
                                                    shape='circle'
                                                    type='danger'
                                                /> 
                                                <Image 
                                                    src={currentTrack===index?'/assets/menu-white.png':'/assets/menu.png'}
                                                    alt='nfsounds'
                                                    preview={false}
                                                />
                                            </Space>
                                        }
                                    </td>
                                </tr>
                            )
                        )
                    }
                </tbody>
                :
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <tbody
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {
                                    state?.map(( track,index)=>(
                                        <Draggable
                                            key={track?.id}
                                            draggableId={track?.id}
                                            index={index}
                                        >
                                            {
                                                (provided, snapshot) => (
                                                    <tr 
                                                        key={'track-'+index} 
                                                        className={index===currentTrack?'bgchange shadow-2 px-2 my-2':'px-2 my-2'} 
                                                        onClick={(e)=>{
                                                            e.stopPropagation()
                                                            dispatch(actionsApi?.setTrackList([...state]))
                                                            dispatch(actionsApi?.playTrack({isPlaying:true, currentTrack: index}))
                                                        }}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        <td style={{borderRadius: '8px 0px 0px 8px'}}>
                                                            <div className='inner-shading-avatar' style={{width:'55px'}}>
                                                                <Avatar 
                                                                    shape='square' 
                                                                    size={55} 
                                                                    src={track?.type==='video' || track?.tracklist_type ? track?.thumbnail: track?.image} 
                                                                    alt='NFsounds'
                                                                    />
                                                                <div></div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <Text strong className={index===currentTrack?'text-white':''} >
                                                                {track?.store_name || track?.tracklist_name}
                                                            </Text>
                                                            <p className='my-0 show-on-mobile'>{track?.artist_dtl?.name}</p>
                                                        </td>
                                                        <td className='hide-on-mobile'>
                                                            <p className='my-0'>{track?.artist_dtl?.name}</p>
                                                        </td>
                                                        <td className='hide-on-mobile'>
                                                            <p className='my-0'>
                                                                {
                                                                    track?.rarity
                                                                }
                                                            </p>
                                                        </td>
                                                        <td className='hide-on-mobile'>
                                                            <p className='my-0'>
                                                                {
                                                                    LLFormat(track?.drop_date)
                                                                }
                                                            </p>
                                                        </td>
                                                        {/* <td>
                                                            <Text>
                                                                {
                                                                    onlyTime(track?.drop_date)
                                                                    
                                                                }
                                                            </Text>
                                                        </td> */}
                                                        <td className='w-100px' style={{borderRadius: '0px 8px 8px 0px'}}>
                                                            {
                                                                track?.store_name?
                                                                <Button
                                                                    block
                                                                    size='large'
                                                                    type={currentTrack===index?"default":'primary'}
                                                                    onClick={(e)=>{
                                                                        e.stopPropagation()
                                                                        setVisiblePlaylistModal(true)
                                                                        setTrack(track)
                                                                    }}
                                                                    className='ant-btn-responsive'
                                                                    >
                                                                        <Text className={currentTrack===index?'text-black':'text-white'}>
                                                                            {t("Add to playlist")}
                                                                        </Text>
                                                                </Button>:
                                                                <Space>
                                                                    <Button
                                                                        icon={<DeleteOutlined/>}
                                                                        onClick={(e)=>{
                                                                            e.stopPropagation()
                                                                            removeTrack(track?.track_id)
                                                                        }}
                                                                        shape='circle'
                                                                        type='danger'
                                                                    /> 
                                                                    <Image 
                                                                        src={currentTrack===index?'/assets/menu-white.png':'/assets/menu.png'}
                                                                        alt='nfsounds'
                                                                        preview={false}
                                                                        // onClick={(e)=>{
                                                                        //     e.stopPropagation()
                                                                        //     setVisibleSwapModal(true)
                                                                        //     setTrackID(track?.track_id)
                                                                        // }}
                                                                    />
                                                                </Space>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </Draggable>
                                        )
                                    )
                                }
                            {provided.placeholder}
                            </tbody>
                        )}
                    </Droppable>
                </DragDropContext>
            }
        </table>
        <PlaylistModal
            visible={visiblePlaylistModal}
            track={track}
            onClose={()=>{setVisiblePlaylistModal(false); setTrack(null)}}
            />
        <SwapModal
            visible={visibleSwapModal}
            track_id={trackID}
            playlist_type={playlist_type}
            length={state?.length}
            onClose={()=>{
                setVisibleSwapModal(false)
                setTrackID(null)
            }}
            />
    </div>
  )
}

export {MusicTable}
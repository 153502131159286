import React from 'react'
import { Row, Col, Typography } from 'antd'
import { CheckoutLeftSide, CheckoutRightSide } from '../../components/CheckoutComponents'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { actionsApi } from '../../shared'
import { useState, useEffect } from 'react'
const { Title } = Typography

const Subscribe = () => {
  let { artistID } = useParams()
  const dispatch= useDispatch()
  const {data, loading}= useSelector( state => state?.artistProfile)
  const [state, setState]= useState([])
  const [total_amount, setTotal_amount]= useState(0)

  useEffect(()=>{
    let user_id= localStorage?.getItem("userId")
    let data={
        artist_id:artistID,
        user_id
    }
    dispatch(actionsApi?.getArtistProfile(data))
  },[])
  useEffect(()=>{
    if(data)
      setTotal_amount(data?.subscriptionFee)
  },[data])
  return (
    <div  className='gradient-bg justify-center py-5'>
      <Row gutter={[32,32]} className='width-93'>
          <Col span={24}>
              <Title level={2} className='my-0'>Subscribe.</Title>
          </Col>
          <Col xs={24} sm={24} md={24} lg={16}>
              <CheckoutLeftSide 
                total_amount={total_amount} 
                artist_id={artistID} 
                subscribtion
                />
          </Col>
          <Col xs={24} sm={24} md={24} lg={8}>
              <CheckoutRightSide 
                data={data} 
                total_amount={total_amount}
                subscribtion
                />
          </Col>
      </Row>
    </div>
  )
}
export {Subscribe}
import {Space, Skeleton} from "antd"
import { SocialMediaButton } from "./SocialMediaButton"

const SocialMedias= ({loading, data})=>{

    return (
        <>
        {
            loading ? 
            <Space size={20} className='mb-2'>
                {
                    new Array(4)?.fill(null)?.map((_, index)=>
                    <Skeleton.Avatar 
                        key={'socal-media-icon-'+index}
                        active
                        />
                    )
                }
            </Space>
            :
            <Space size={20} className='mb-2'>
                {
                    data?.instagram ?
                    <SocialMediaButton icon='instagram.png' url={data?.instagram}/>
                    :<></>
                }
                {
                    data?.twitter ?
                    <SocialMediaButton icon='twitter.png' url={data?.twitter}/>
                    :<></>
                }
                {
                    data?.spotify ?
                    <SocialMediaButton icon='spotify.png' url={data?.spotify}/>
                    :<></>
                }
                {
                    data?.youtube ?
                    <SocialMediaButton icon='youtube.png' url={data?.youtube}/>
                    :<></>
                }
            </Space> 
        }
        </>
    )
}
export {SocialMedias}
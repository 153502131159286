import { configureStore } from "@reduxjs/toolkit";
import {
	users, 
	loginCheck,
	login, 
	roles, 
	loadClients, 
	dashboard,
	salesPersons,
	ads,
	Artists,
	storeItems,
	droppingSoonItems,
	collection,
	collectionMusic,
	itemDetail,
	cart,
	artistCart,
	checkout,
	//music
	playlist,
	playlists,
	artistPosts,
	artistProfile,
	postComments,
	//user
	userProfile,
	favoriteItems,
	userSalePurchaseLog,
	userSubscriptions,
	marketPlace,
	notifications,
	track,
	feeds,
	feedComments,
	artistStories,
	//artist collection
	artistCollection,
	//app
	app
} from "./action"
const store = configureStore({
	reducer: {
		users,
		loginCheck,
		login,
		roles,
		loadClients,
		dashboard,
		salesPersons,
		ads,
		Artists,
		storeItems,
		droppingSoonItems,
		collection,
		collectionMusic,
		itemDetail,
		cart,
		artistCart,
		checkout,
		//music
		playlist,
		playlists,
		artistPosts,
		artistProfile,
		postComments,
		//user
		userProfile,
		favoriteItems,
		userSalePurchaseLog,
		userSubscriptions,
		marketPlace,
		notifications,
		track,
		feeds,
		feedComments,
		artistStories,
		//artist collection
		artistCollection,
		//app
		app
	},
})
export default store;
import React from "react";
import { Slider as AntSlider, Button, Space, Typography} from "antd";
import {
  Slider,
  withStyles,
} from "@material-ui/core";
import {LoadingOutlined, FullscreenOutlined} from "@ant-design/icons"
import {
    IconPlayerPlayFilled,
    IconPlayerPauseFilled,
    IconPlayerTrackNextFilled,
    IconPlayerTrackPrevFilled,
    IconVolume,
    IconVolume2,
    IconVolumeOff
} from '@tabler/icons-react'
import "./index.css";
const {Title}= Typography

const PrettoSlider = withStyles({
  root: {
    height: "auto",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  thumb: {
    height: 12,
    width: 12,
    backgroundColor: "#fff",
    border: "2px solid var(--repla)",
    marginTop: -0,
    marginLeft: '1px',
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-41% + 4px)",
  },
  track: {
    height: 3,
    borderRadius: 4,
    width: "100%",
  },
  rail: {
    height: 3,
    borderRadius: 4,
  },
})(Slider);

const Control = ({
    videoPlayerRef,
    progressBarRef,
    onPlayPause,
    playing,
    onRewind,
    onForward,
    played,
    onSeek,
    onSeekMouseUp,
    onVolumeChangeHandler,
    onVolumeSeekUp,
    volume,
    mute,
    onMute,
    duration,
    currentTime,
    onMouseSeekDown,
    controlRef,
    buffer,
    openFullscreen,
    thumbnail
    }) => 
    {
    const handleProgressChange = () => {
        videoPlayerRef.current.currentTime = progressBarRef.current.value;
    }
    console.log("ccc:", played)
    return (
        <div className="control_Container" ref ={controlRef} style={played===0 ? {background:`url(${thumbnail})`} : {}}>
            <div className="mid__container">
                {
                    buffer ?
                    <LoadingOutlined style={{color:'#fff', fontSize:'30px'}}/>:
                    <Space>
                        <Button shape= 'circle' className="control-button">
                            <IconPlayerTrackPrevFilled 
                                className="text-white" 
                                onDoubleClick={onRewind}
                                />
                        </Button>
                        <Button shape= 'circle' className="control-button">
                            <div 
                                onClick={onPlayPause} 
                                className="text-white"
                                >
                                {
                                    playing ? 
                                    <IconPlayerPauseFilled/>: 
                                    <IconPlayerPlayFilled/>
                                }
                            </div>
                        </Button>
                        <Button shape= 'circle' className="control-button">
                            <IconPlayerTrackNextFilled 
                                className="text-white"
                                fontSize="medium" 
                                onDoubleClick={onForward} 
                                />
                        </Button>
                    </Space>
                }
            </div>
            <div className="bottom__container px-2 py-2">
                <div className="slider__container">
                    <PrettoSlider
                        min={0}
                        max={100}
                        value={played * 100}
                        onChange={onSeek}
                        onChangeCommitted={onSeekMouseUp}
                        onMouseDown={onMouseSeekDown}
                    />
                </div>
                <div className="space-between">
                    <Space>
                        <Button shape= 'circle' className="control-button">
                            <div onClick={onPlayPause}>
                                {
                                    playing ? 
                                    <IconPlayerPauseFilled className="text-white"/>: 
                                    <IconPlayerPlayFilled className="text-white"/>
                                }
                            </div>
                        </Button>
                        <Button shape= 'circle' className="control-button">
                            <div onClick={onMute} className="text-white">
                                {
                                    // mute?<IconVolumeOff/> : <IconVolume/>
                                    !volume?<IconVolumeOff/>:volume<66 ? <IconVolume2/>:<IconVolume/>
                                }
                            </div>
                        </Button>
                        <div>
                            <AntSlider
                                onChange={onVolumeChangeHandler}
                                value={volume * 100}
                                style={{width:'90px'}}
                                //   onChangeCommitted={onVolumeSeekUp}
                                />
                        </div>
                        <Title level={5} className="text-white" style={{transform: 'translateY(5px)'}}>
                            { currentTime} : {duration}
                        </Title>
                    </Space>  
                    <Button shape= 'circle' className="control-button" onClick={openFullscreen}>
                        <FullscreenOutlined className="text-white"/>
                    </Button>  
                </div>
            </div>
        </div>
    );
};

export default Control;
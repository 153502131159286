import {Modal, Typography, Button, Space} from "antd"
import { beforeAddToCartMarketplace } from "../../shared";
import { useState } from "react";
const {Text}= Typography
const BuyItemModal=({visible, data,onClose})=>{
    const [loading, setLoading]= useState(false)
    const purchaseNow=async ()=>{ 
        setLoading(true)
        await beforeAddToCartMarketplace(data?.store_id, data?.marketplace_id)
        setLoading(false)
        onClose()
    }
    return (
        <Modal
            title={data?.store_dtl?.store_name}
            visible={visible}
            onClose={onClose}
            onCancel={onClose}
            footer={
                <Button 
                    type='primary'
                    htmlType='submit'
                    loading={loading}
                    onClick={purchaseNow}
                    >
                    Pay
                </Button>
            }
        >
            <Space direction="vertical" className="width-100">
                <div className="space-between">
                    <Text strong>Total Price</Text>
                    <Text strong>
                        {data?.amount+" €"}
                    </Text>
                </div>
            </Space>
        </Modal>
    )
}
export {BuyItemModal}
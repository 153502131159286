import React, {useState} from 'react'
import { Row, Col} from 'antd'
import { LeftSide, RightSide } from '../../components/CartPage'
const Cart = () => {
  const [amount, setAmount]= useState(0)
  return (
    <div className='cart-bg justify-center py-5'>
        <Row gutter={[16,16]} className='width-93'>
            <Col xs={{order:1,span:24}} sm={{order:1,span:24}} md={{order:1,span:24}} lg={{order:1,span:12}}>
                <LeftSide setAmount={setAmount}/>
            </Col>
            <Col xs={{order:2,span:24}} sm={{order:2,span:24}} md={{order:2,span:24}} lg={{order:2,span:12}}>
                <RightSide amount={amount}/>
            </Col>
        </Row>
    </div>
  )
}

export {Cart}
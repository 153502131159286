import React from 'react'
import {Typography, Space} from 'antd'
import Filters from './Filters'
import CollectionList from './CollectionList'
const { Title } = Typography

const CollectionComp = () => {
  return (
    <div className='justify-center py-5'>
        <Space className='width-93' direction='vertical'>
            <Filters />
            <div className='mblnone py-3 justify-center'>
                <div className='blackline'></div>
            </div>
            <CollectionList />
        </Space>
    </div>
  )
}

export {CollectionComp}
import { NavLink } from "react-router-dom"
import { useSelector } from "react-redux"
import {Space, Button, Typography} from "antd"
import {UnlockOutlined, EyeOutlined, MessageOutlined, SketchOutlined, LinkOutlined} from "@ant-design/icons"
const {Text}= Typography
export const ArtistLock=({artist_id})=>{
    const {data}= useSelector( state => state?.artistProfile)
    return (
        <div
            style={{background:'rgba(0,0,0,0.8)', width:'100%', display:"flex"}}
            className='justify-center py-5 radius-6'
            >
                <Space direction='vertical' size={10}>
                    <NavLink 
                        to={'/subscribe/'+artist_id}
                        >
                        <Button 
                            block 
                            size='large' 
                            className="gold-button"
                            >
                                Subscribe
                        </Button>
                    </NavLink>
                    <Text 
                        strong 
                        style={{textAlign:'center', display:'flex', color:'white', width:'100%'}}
                        >
                        {data?.subscriptionFee} € / month – Cancel anytime
                    </Text>
                    <Space>
                        <Button icon={<UnlockOutlined />} shape='circle'/>
                        <Text strong style={{color:'white'}} >
                            Unlock to access my exclusive feed
                        </Text>
                    </Space>
                    <Space>
                        <Button icon={<EyeOutlined />} shape='circle'/>
                        <Text strong style={{color:'white'}}>
                            Check out my private stories and contents
                        </Text>
                    </Space>
                    <Space>
                        <Button icon={<SketchOutlined />} shape='circle'/>
                        <Text strong style={{color:'white'}}>
                            Attempts to obtain exceptional rewards
                        </Text>
                    </Space>
                    <Space>
                        <Button icon={<MessageOutlined />} shape='circle'/>
                        <Text strong style={{color:'white'}}>
                            Access my private chat
                        </Text>
                    </Space>
                    <Space>
                        <Button icon={ <LinkOutlined />} shape='circle'/>
                        <Text strong style={{color:'white'}}>
                            Enter my innermost circle
                        </Text>
                    </Space>
                </Space>
        </div>
    )
}
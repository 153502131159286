import React from 'react'
import { Row, Col } from 'antd'
import ProfileLeftBlock from './ProfileLeftBlock'
import ProfileRightTabBlock from './ProfileRightTabBlock'

export const ProfileCombineComp = () => {
  return (
    <div className='justify-center page-bg py-5'>
        <div className='width-93 user-profile-cover'>
            <ProfileLeftBlock />
            <ProfileRightTabBlock />
        </div>
    </div>
  )
}

import {Space ,Typography, Avatar} from "antd"
import { useEffect , useState} from "react"
const {Title, Text}= Typography
const DisplayTrack = ({trackList, currentTrack, audioRef, setDuration, progressBarRef, changeTrack, expand}) => {
    const [track, setTrack]= useState(null)
    const onLoadedMetadata = () => {
        let seconds=audioRef.current.duration
        setDuration(seconds)
        progressBarRef.current.max = seconds
      }
      useEffect(()=>{
        if(trackList?.length && currentTrack!==null)
        {
          if(trackList[currentTrack])
            setTrack(trackList[currentTrack])
        }
      },[trackList, currentTrack])
      console.log("track:", track)
    return (
      <>
        <audio 
          src={track?.image || track?.video} 
          ref={audioRef} 
          onLoadedMetadata={onLoadedMetadata} 
          onEnded={()=>{
          changeTrack(true, true)
          }}
          />
        <marquee>
          <Title level={5} className="m-0 text-white">
            {
              track?.store_name || track?.tracklist_name
            }
          </Title>
        </marquee>
        <Space size={10} className="w-100 authordiv">
          <div className='inner-shading-avatar' style={{width: (expand?60:40)+'px'}}>
            <Avatar 
              src={ track?.type==='video' || track?.tracklist_type ? track?.thumbnail: track?.image} 
              shape='square' 
              size={expand?60:40}
              />
            <div></div>
          </div>
            <Space direction='vertical' className="w-100" size={0}>
                <Title 
                  level={5} 
                  className="m-0 text-white width-90" 
                  ellipsis
                  >
                  {
                    track?.store_name || track?.tracklist_name
                  }
                </Title>
                <Text strong className='text-white' ellipsis>
                  {
                    track?.artist_dtl?.name
                  }
                </Text>
            </Space>
        </Space>
      </>
      
    )
  };
  export default DisplayTrack;
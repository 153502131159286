import { NavLink } from "react-router-dom"
import {Image, Button, Space} from "antd"
const UnAuthorized=()=>{
    return (
        <div className="width-100">
            <Space 
                direction="vertical" 
                className="center"
                size={10}
                >
                <Image
                    src='/assets/unauthorized.png'
                    alt='NFSounds unAuthorized access'
                    preview= {false}
                    />
                <p>
                    Sorry, you are not authorized to access feed.
                </p>
                <NavLink to='/'>
                    <Button type="primary" size="large">Back Log In</Button>
                </NavLink>
            </Space>
        </div>
    )
}
export {UnAuthorized}
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch} from "react-redux"
import { useTranslation } from "react-i18next"
import {Modal, Typography, Form, Row, Col, Button} from "antd"
import { NFInput, NFSelect } from "../../Form"
import { actionsApi, addToPlaylist, domainUrl4} from "../../../shared"
import { Alert } from "../../Alerts"
const {Title}= Typography

const PlaylistModal=({visible, createPlaylist, track, onClose})=>{

    const {t}= useTranslation()
    const dispatch= useDispatch()
    const {data : playlists}= useSelector(state => state?.playlists)
    const [form] = Form.useForm()
    const btnRef = React.useRef() 
    const [options, setOptions]= useState([])
    const [loading, setLoading]= useState(false)
    const selectFile = () => {
        btnRef.current.click()
    }
    useEffect(()=>{
        if(playlists?.length)
            setOptions(playlists?.map(playlist=>({id: playlist?.name, name: playlist?.name})))
    }, [playlists])
    const save= async ()=>{
        setLoading(true)
        let user_id= localStorage?.getItem("userId")
        let data=form.getFieldsValue()
        let formData = new FormData()
        formData.append(`user_id`, user_id)
        formData.append(`playlist_name`, data?.playlist_name)
        let userToken=localStorage.getItem('token')
        var myHeaders = new Headers();
        myHeaders.append("token", `${userToken}`)
        myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
        var requestOptions = {
        headers: myHeaders,
        method: 'POST',
        redirect: 'follow',
        body: formData
        }
        fetch(domainUrl4+"/addPlayListType",requestOptions)
            .then(response=>response.json())
            .then(result=>{
                if(result?.ResponseCode)
                    {
                        Alert("success", "Playlist created successfully")
                      
                        onClose()
                        dispatch(actionsApi?.playlistsResponse(
                            [...playlists,{name: data?.playlist_name, count: 0}]
                        ))
                        setLoading(false)
                    }
                else       
                    throw result?.ResponseMsg
            })
            .catch((error)=>{
                Alert("error",error)
                setLoading(false)
                return 0
            })
        
    }
    const AddToPlaylist=async ()=>{
        setLoading(true)
        let user_id= localStorage?.getItem("userId")
        let data=form.getFieldsValue()
        let formData = new FormData()
        formData.append(`user_id`, user_id)
        formData.append(`track_id`, track?.store_id)
        formData.append(`playlist_type`, data?.playlist_type)
        formData.append(`tracklist_type`, track?.type)
        await addToPlaylist(formData)
        onClose()
        setLoading(false)
    }
    return (
        <Modal
            visible={visible} 
            title={<Title className="my-0" level={4}>{createPlaylist? t('Create playlist'): t('Add to playlist')}</Title>} 
            footer={
                <Button 
                    type="primary" 
                    onClick={createPlaylist?selectFile:AddToPlaylist}
                    loading={loading}
                    size="large"
                    >
                    {
                        createPlaylist ? t('Create playlist'): t('Add to playlist')
                    }
                </Button>
            } 
            onCancel={onClose}
            centered
            >
                <Form 
                    layout="vertical"
                    onFinish={save} 
                    form={form}
                    initialValues={{
                        remember: true,
                    }}
                    >
                    <Row gutter={16}>
                        <Col span={24}>
                            {
                                createPlaylist?
                                <NFInput
                                    label= {t('Playlist Name')}
                                    required
                                    message= {t('please enter playlist name')}
                                    size='large'
                                    name='playlist_name'
                                    value={form.getFieldValue('playlist_name') || ''}
                                    placeholder= {t('e.g my favorites')}
                                    maxLength={10}
                                    showCount
                                    />
                                :
                                <NFSelect
                                    label= {t('Select Playlist')}
                                    required
                                    message= {t('please select playlist')}
                                    size='large'
                                    name='playlist_type'
                                    value={form.getFieldValue('playlist_type') || ''}
                                    options={options}
                                    placeholder= {t('e.g my favorites')}
                                    />
                            }
                        </Col>
                        <Button 
                            htmlType="submit" 
                            ref={btnRef}
                            style={{display:'none'}}
                            >
                            send
                        </Button>
                    </Row>
                </Form>
        </Modal>
    )
}
export {PlaylistModal}
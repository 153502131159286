import { useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Typography } from 'antd'
import "./index.css"
import { BlackLine } from '../'
import { useTranslation } from 'react-i18next'
const { Title, Text } = Typography

const Benefits = ({isArtistProfile, benefits}) => {
    const { t }= useTranslation()
    const {data}= useSelector(state => state?.itemDetail)
    const [state, setState]= useState([])
    useEffect(()=>{
        if(benefits)
            setState(benefits)
        else if(data)
            setState(data?.benefits)
    }, [data, benefits])
    return (
        <>
        {
            state?.length ?
            <>
                {
                    isArtistProfile &&
                    <BlackLine width= '88%'/>
                }
                <div className='justify-center'>
                    <div className='text-center py-3 width-90 flex-col-center'>
                        <Title level={1}>
                            {t("MANY BENEFITS TO BE EARNED")}
                        </Title>
                        <Row gutter={[64,16]} className='m-0 benefit-cover benefit-cover-1 width-100'>
                            {
                                state?.map((benefit, index)=>
                                <Col xs={12} sm={12} md={8} key={'benefit'+index}>
                                    <div className='secondary-bg p-3 radius-6 benefit-box shadow-1' style={{width:'100%'}}>     
                                        <Text className='my-0 text-white p-18' ellipsis>
                                            {
                                                benefit
                                            }
                                        </Text>
                                    </div>
                                </Col>
                                )
                            }
                        </Row>
                        <Row gutter={[32,16]} className='m-0 benefit-cover benefit-cover-2'>
                            {
                                state?.map((benefit, index)=>
                                <Col xs={12} sm={12} md={8} key={'benefit'+index}>
                                    <div className='secondary-bg p-3 radius-6 benefit-box shadow-1'>     
                                        <Text className='my-0 text-white p-18' ellipsis>
                                            {
                                                benefit
                                            }
                                        </Text>
                                    </div>
                                </Col>
                                )
                            }
                        </Row>
                        <Row gutter={[16,16]} className='m-0 benefit-cover benefit-cover-3'>
                            {
                                state?.map((benefit, index)=>
                                <Col xs={12} sm={12} md={8} key={'benefit'+index}>
                                    <div className='secondary-bg p-3 radius-6 benefit-box shadow-1'>     
                                        <Text className='my-0 text-white p-18' ellipsis>
                                            {
                                                benefit
                                            }
                                        </Text>
                                    </div>
                                </Col>
                                )
                            }
                        </Row>
                        <Row gutter={[8,16]} className='m-0 benefit-cover benefit-cover-4'>
                            {
                                state?.map((benefit, index)=>
                                <Col xs={12} sm={12} md={8} key={'benefit'+index}>
                                    <div className='secondary-bg p-3 radius-6 benefit-box shadow-1'>     
                                        <Text className='my-0 text-white p-18' ellipsis>
                                            {
                                                benefit
                                            }
                                        </Text>
                                    </div>
                                </Col>
                                )
                            }
                        </Row>
                    </div>
                </div>
                {
                    isArtistProfile &&
                    <BlackLine width='88%'/>
                }
            </> 
            :<></>
        }
        </>
    )
}

export {Benefits}
import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector} from 'react-redux'
import { ArtistprofilteTopSearch,ArtistprofileSocio, ArtistFeedTabs } from '../../components/ArtistProfile'
import { actionsApi } from '../../shared'
import { Benefits } from '../../components/Benefits'

const ArtistProfile = () => {

  let { ID } = useParams()
  const dispatch= useDispatch()
  const {data, loading}= useSelector( state => state?.artistProfile)
  
  useEffect(()=>{
    let user_id= localStorage?.getItem("userId")
    let data={
        artist_id:ID,
        user_id
    }
    dispatch(actionsApi?.getArtistProfile(data))
  },[ID])
  useEffect(()=>{
    if(data)
    {
      if(data?.subscription?.subscribtionStatus)
        dispatch(actionsApi?.getArtistPosts(ID))
    }
  },[data])
  return (
    <>
      <div className='page-bg'>
        <ArtistprofilteTopSearch />
        <ArtistprofileSocio 
          artistProfile={data} 
          loading={loading} 
          artist_id={ID}
          />
        <Benefits 
          isArtistProfile
          benefits= {data?.benefits || []}
          />
      </div>
      <ArtistFeedTabs  
        artist_id={ID}
        />
    </>
  )
}
export {ArtistProfile}
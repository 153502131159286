import { Form, Row, Col, Button, Typography} from 'antd'
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react'
import { NFSearch, NFSelect} from '../Form';
import { useDispatch, useSelector } from 'react-redux';
import { actionsApi, getUserID } from '../../shared';
import { releaseDateLookup, storePriceLookup } from '../../shared/lookups'
import moment from 'moment';
const {Title}= Typography

const Filter = () => {
    const {t}= useTranslation()
    const dispatch= useDispatch()
    const [form] = Form.useForm();
    const {filters, pagination, storeLookups}= useSelector(state => state?.storeItems)
    const [lookups, setLookups]= useState({artistLookup:[], genreLookup:[]})
    useEffect(()=>{
        let formData = new FormData()
        formData.append(`user_id`, getUserID())
        formData.append('dateTime', moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss'))
        for (const property in filters) 
            {
                if(filters[property]===0 || filters[property]===null)
                    continue
                formData.append(property, filters[property])
            }
        dispatch(actionsApi?.getAllStoreItems({formData, ...pagination, pageNo: 1}))
        form.setFieldsValue({
            ...filters,
        })
    }, [])
    useEffect(()=>{
        setLookups({
            artistLookup:[{id:0, name:'All'},...storeLookups?.artistLookup?.map(artist=> ({...artist,id:artist?.artist_id}))],
            genreLookup:[{id:0, name:'All'},...storeLookups?.genreLookup?.map(type=> ({...type,id:type?.type_id}))]
        })
    }, [storeLookups])
    const filter=()=>{
        let data= form.getFieldsValue()
        let formData = new FormData()
        formData.append(`user_id`, getUserID())
        formData.append('dateTime', moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss'))
        for (const property in data) 
            {
                if(data[property]===0 || data[property]===null)
                    continue
                formData.append(property, data[property])
            }
        dispatch(actionsApi?.getAllStoreItems({formData, ...pagination, pageNo:1}))
        dispatch(actionsApi?.changeStoreFilters({...data}))
    }
  return (
    <div className='space-between-end px-3'>
        <Title level={1} className='m-0'>
            {
                t("STORE.")
            }
        </Title>
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            onFinish={filter}
            style={{width: '768px'}}
            >
            <Row gutter={[16,16]}>
                <Col md={7} sm={24} xs={24}>
                    <NFSearch
                        label={ t('Search Store') }
                        name="search_text" 
                        value={form.getFieldValue('search_text') || ''}
                        placeholder='Search...'
                        size='large'
                        onSearch={filter}
                        />
                </Col>
                <Col md={5} sm={6} xs={12}>
                    <NFSelect
                        label={ t('Artist')}
                        name="artist_id" 
                        value={form.getFieldValue('artist_id') || ''}
                        onChange={filter}
                        options={lookups?.artistLookup}
                        maxTagCount= 'responsive'
                        size='large'
                        />
                </Col>
                <Col md={4} sm={6} xs={12}>
                    <NFSelect
                        label={ t('GENRE')}
                        name="type_id" 
                        value={form.getFieldValue('type_id') || ''}
                        onChange={filter}
                        options={lookups?.genreLookup}
                        maxTagCount= 'responsive'
                        size='large'
                        />
                </Col>
                <Col md={4} sm={6} xs={12}>
                    <NFSelect
                        label={ t('RELEASE DATE')}
                        name="release_date" 
                        value={form.getFieldValue('release_date') || ''}
                        onChange={filter}
                        options={releaseDateLookup}
                        maxTagCount= 'responsive'
                        size='large'
                        />
                </Col>
                <Col md={4} sm={6} xs={12}>
                    <NFSelect
                        label={ t('Price')}
                        name="price" 
                        value={form.getFieldValue('price') || ''}
                        onChange={filter}
                        options={storePriceLookup}
                        maxTagCount= 'responsive'
                        size='large'
                        />
                </Col>
                <Button 
                    type='primary'
                    size='large'
                    htmlType='submit'
                    style={{display:'none'}}
                    >
                    {
                        t("Search")
                    }
                </Button>
            </Row>
        </Form>
    </div>
  )
}

export default Filter
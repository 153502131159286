import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch} from 'react-redux';
import { Col, Row, Form, Button, Image, Typography, Avatar} from "antd"
import { NFInput, NFDateTimePicker } from '../Form'
import moment from 'moment'
import { actionsApi, updateUserProfile } from '../../shared'
import CountryCodes from '../../pages/signup/CountryCodes'
import "./profile.css"
const {Text}= Typography

const SettingsForm = () => {

    const dispatch= useDispatch()
    const {data: userProfile}= useSelector(state => state?.userProfile)
    const [form] = Form.useForm()
    const fileRef = React.useRef() 
    const [file, setFile] = useState(null)
    const [countryCode, setCountryCode]= useState('+33')
    const [loading, setLoading]= useState(false)

    useEffect(()=>{
        if(userProfile)
        {
            let phone= userProfile?.phone?.split(" ")
            setCountryCode(phone[0])
            form.setFieldsValue({...userProfile, dob: moment(userProfile?.dob), phone: phone[1]})
        }
    }, [userProfile])

    const selectFile = () => {
            fileRef.current.click()
        }
    const handleFileChange = (e) => {
        let reader = new FileReader()
        let file = e.target.files[0]   
        reader.onloadend = () => {
            setFile({
                file: file,
                preview: reader.result
            })            
        }
        if(file)
            reader.readAsDataURL(file)
    }
    const UpdateUserProfile= async ()=>{
        setLoading(true)
        let data= form.getFieldsValue(['city', 'country', 'phone', 'address', 'pincode'])
        let user_id= localStorage?.getItem("userId")
        let formData = new FormData()
        formData.append(`user_id`, user_id)
        if(file?.file)
        formData.append(`profile`, file?.file )
        formData.append(`city`, data?.city)
        formData.append(`country`, data?.country)
        formData.append(`phone`, countryCode+" "+data?.phone)
        formData.append(`address`, data?.address)
        formData.append(`pincode`, data?.pincode)
        let result= await updateUserProfile(formData)
        setLoading(false)
        if(result)
            dispatch(actionsApi?.userProfileResponse({...userProfile, ...data, profile: file?.preview}))
    }
  return (
    <div className='py-3'>
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            onFinish={UpdateUserProfile}
            >
            <Row gutter={[16,16]}>
                <Col span={24} xs={24} className='center'>
                    <div className='user-profile-edit'>   
                        <input 
                            type="file"  
                            ref={fileRef} 
                            onChange={(e) => handleFileChange(e)} 
                            className='dnon' 
                            accept=".png, .jpg, .jpeg" 
                            />                                             
                        <Avatar 
                            src={
                                file?.preview ||
                                form.getFieldValue("profile") ||
                                "/assets/user-avatar.png"
                            }
                            alt='NFsounds user profile picture'
                            size={140}
                            shape='square'
                            />
                        <Button
                            icon={<Image src='/assets/img/editpen.svg' preview={false} alt='edit icon'/>}
                            className='user-profile-edit-button'
                            onClick={(e) => selectFile() }
                        >
                        </Button>
                    </div>         
                </Col>
                <Col md={8} sm={12} xs={24}>
                    <NFInput
                        name='fname'
                        label='First Name'
                        size='large'
                        required
                        message='Please first name' 
                        placeholder='i.e Mughira' 
                        value={form.getFieldValue('fname')}
                        disabled
                        />
                </Col>
                <Col md={8} sm={12} xs={24}>
                    <NFInput
                        name='lname'
                        label='Last Name'
                        size='large'
                        required
                        message='Please enter last name' 
                        placeholder='i.e blockchainer' 
                        value={form.getFieldValue('lname')}
                        disabled
                        />
                </Col>
                <Col md={8} sm={12} xs={24}>
                    <NFDateTimePicker
                        datePicker
                        name='dob'
                        label='Date of birth'
                        size='large'
                        required
                        message='Please select date of birth' 
                        value={form.getFieldValue('dob')}
                        disabled
                        />
                </Col>
                <Col md={8} sm={12} xs={24}>
                    <NFInput
                        name='username'
                        label='User name'
                        size='large'
                        required
                        message='Please enter user name' 
                        placeholder='i.e Mughira176' 
                        value={form.getFieldValue('username')}
                        disabled
                        />
                </Col>
                <Col md={8} sm={12} xs={24}>
                    <NFInput
                        name='phone'
                        label='Phone'
                        size='large'
                        required
                        message='Please enter phone' 
                        placeholder='i.e 31 234 5678' 
                        addonBefore= {
                            <CountryCodes
                                value= {countryCode}
                                onChange={(value)=> setCountryCode(value)}
                            />
                        }
                        value={form.getFieldValue('phone')}
                        />
                </Col>
                <Col  md={8} sm={12} xs={24}>
                    <NFInput
                        type='email'
                        name='email'
                        label='Email'
                        size='large'
                        required
                        message='Please enter email' 
                        placeholder='i.e mug@hotmail.com' 
                        value={form.getFieldValue('email')}
                        disabled
                        />
                </Col>
                <Col md={12} sm={12} xs={24}>
                    <NFInput
                        name='blockchainAddress'
                        label='BLOCKCHAIN ADDRESS'
                        size='large'
                        placeholder='0xA46d0Df9CDb7d4E4B0228E5505836C9bc42c7d00' 
                        value={form.getFieldValue('blockchainAddress')}
                        disabled
                        />
                </Col>
                <Col md={12} sm={12} xs={24}>
                    <NFInput
                        name='address'
                        label='Address'
                        size='large'
                        required
                        message='Please enter address' 
                        placeholder='i.e 4023' 
                        value={form.getFieldValue('address')}
                        />
                </Col>
                <Col md={8} sm={12} xs={24}>
                    <NFInput
                        name='pincode'
                        label='Zip Code'
                        size='large'
                        required
                        message='Please enter zip code' 
                        placeholder='0000' 
                        value={form.getFieldValue('pincode')}
                        />
                </Col>
                <Col md={8} sm={12} xs={24}>
                    <NFInput
                        name='city'
                        label='City'
                        size='large'
                        required
                        message='Please enter city' 
                        placeholder='Paris' 
                        value={form.getFieldValue('city')}
                        />
                </Col>
                <Col md={8} sm={12} xs={24}>
                    <NFInput
                        name='country'
                        label='Country'
                        size='large'
                        required
                        message='Please enter country' 
                        placeholder='France' 
                        value={form.getFieldValue('country')}
                        />
                </Col>
                <Col span={24} className='justify-center'>
                    <div style={{width:'200px'}}>
                        <Button
                            size='large'
                            htmlType='submit'
                            type='primary'
                            loading={loading}
                            block
                        >
                            <Text strong style={{color:'white'}}>Update</Text>
                        </Button>
                    </div>
                </Col>
            </Row>
        </Form>
    </div>
  )
}

export default SettingsForm
import React,{ useState } from 'react'
import { Space, Row, Col, Typography, Radio, Image, Button, Form, Collapse } from 'antd'
import { NFInput } from '../Form'
import {  IconChevronUp, IconChevronDown } from '@tabler/icons-react'
import './checkout.css'
import {subscribe, checkout} from '../../shared'
import { useNavigate } from 'react-router-dom'

const { Panel } = Collapse;
const { Title } = Typography

export const CheckoutLeftSide = ({total_amount, artist_id, subscribtion}) => {
    const [currentPanel,setCurrentPanel]=useState(['1'])
    const [form] = Form.useForm()
    const navigate= useNavigate()
    const [loading, setLoading]= useState(false)
    const [paymentMethod, setPaymentMethod]= useState(1)
        const faqData = 
            {   
                title:"Payment methods",
                spaceContent:[
                    {
                        faqs:[
                            {
        
                                head:<div className='align-center-space-between w-100'>
                                        <Radio value={0}>
                                            <Title level={5} className='m-0'>PayPal</Title>
                                        </Radio>
                                        <Image src='/assets/img/paypal.png' preview={false} />
                                    </div>,
                            },
                            {
        
                                head:<div className='align-center-space-between w-100'>
                                        <Radio value={1}>
                                            <Title level={5} className='m-0'>Credit card</Title>
                                        </Radio>
                                        <Space style={{alignItems: 'center'}}>
                                            <Image src='/assets/img/visa.png' preview={false} />
                                            <Image src='/assets/img/master.png' preview={false} />
                                        </Space>
                                    </div>,
                            },
                        ]
                    }
                ]
            }
    const Checkout=async ()=>{
        setLoading(true)
        let user_id= localStorage?.getItem("userId")
        let data=form.getFieldsValue()
        if(subscribtion)
        {
            let dataa={
                ...data,
                user_id: user_id,
                exp_month: data?.expiry_month,
                exp_year: data?.expiry_year,
                artist_id
            }
            
            let result= await subscribe(dataa)
            if(result)
                navigate(`/artist/${artist_id}`, { replace: true })
            setLoading(false)
        }
        else
        {
            let formData = new FormData()
            formData.append(`user_id`, user_id)
            formData.append(`card_number`, data?.card_number)
            formData.append(`card_name`, data?.card_name)
            formData.append(`expiry_month`, data?.expiry_month)
            formData.append(`expiry_year`, data?.expiry_year)
            formData.append(`cvc`, data?.cvc)
            formData.append(`total_amount`, total_amount)
            let result= await checkout(formData)
            if(result)
                navigate("/collection", { replace: true })
            setLoading(false)
        }
    }
  return (
        <div>
            <Title level={3} className='text-center m-0'>{faqData?.title}</Title>
            <Radio.Group 
                value={paymentMethod}
                size='small'
                style={{width:'100%'}}
                >
                <div direction='vertical' className='radius-6 bg-white my-3 w-100  parentspace'>                
                    {
                        faqData?.spaceContent?.map((spacec, index)=>
                        <div className='w-100  cartspace' key={'payment-form'+index}>
                            <Collapse
                                defaultActiveKey={1}
                                className='py-4 px-2 border0 shadow-1'
                                onChange={(key)=>{setCurrentPanel(key);setPaymentMethod(parseInt(key))}}
                                accordion
                                >
                                {
                                    spacec?.faqs?.map((faq,f)=>
                                    <Panel 
                                        className='collapseBorder'
                                        showArrow={false}  
                                        header={
                                            <div>{faq?.head}</div>
                                        } 
                                        key={f} 
                                        extra={
                                            paymentMethod===f?
                                            <IconChevronDown style={{transform:"translateY(10px)"}} />:
                                            <IconChevronUp style={{transform:"translateY(10px)"}} />
                                        }
                                    >
                                            <Form
                                                form={form}
                                                layout="vertical"
                                                initialValues={true}
                                                onFinish={Checkout}
                                            >
                                                <Row gutter={[12,16]}>
                                                    <Col span={24}>
                                                        <NFInput
                                                            label='Card Number'
                                                            name='card_number'
                                                            placeholder= '0000 0000 0000 0000'
                                                            value= {form.getFieldValue('card_number')}
                                                            required 
                                                            message="please enter card number"
                                                            size= 'large'
                                                            onChange={(e)=>{
                                                                let cardNumber = e.target.value.replace(/\D/g, '')
                                                                cardNumber= cardNumber?.length > 16 ? cardNumber?.slice(0, 16) : cardNumber
                                                                cardNumber = cardNumber?.replace(/(\d{4})/g, '$1 ')?.trim()
                                                                form.setFieldsValue({...form?.getFieldsValue(), card_number: cardNumber})
                                                            }}
                                                            />
                                                    </Col>
                                                    <Col span={24}>
                                                        <NFInput
                                                            label='Card Name'
                                                            name='card_name'
                                                            placeholder= 'John Doe'
                                                            value= {form.getFieldValue('card_name')}
                                                            required 
                                                            message="please enter name"
                                                            size= 'large'
                                                            />
                                                    </Col>
                                                    <Col sm={8} xs={24}>
                                                        <NFInput
                                                            type='number'
                                                            label='Expiry Month'
                                                            name='expiry_month'
                                                            placeholder= '12'
                                                            value= {form.getFieldValue('expiry_month')}
                                                            required 
                                                            message="please enter expiry month"
                                                            size= 'large'
                                                            />
                                                    </Col>
                                                    <Col sm={8} xs={24}>
                                                        <NFInput
                                                            type='number'
                                                            label='Expiry Year'
                                                            name='expiry_year'
                                                            placeholder= '24'
                                                            value= {form.getFieldValue('expiry_year')}
                                                            required 
                                                            message="please enter expiry year"
                                                            size= 'large'
                                                            />
                                                    </Col>
                                                    <Col sm={8} xs={24}>
                                                        <NFInput
                                                            type='number'
                                                            label='CVC'
                                                            name='cvc'
                                                            placeholder= '123'
                                                            value= {form.getFieldValue('cvc')}
                                                            required 
                                                            message="please enter cvc"
                                                            size= 'large'
                                                            />
                                                    </Col>
                                                    <Col span={24}>
                                                        <Button
                                                            block
                                                            type='primary'
                                                            size='large'
                                                            htmlType='submit'
                                                            loading={loading}
                                                            >
                                                            PAY NOW
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form>
                                    </Panel>
                                    )
                                }
                            </Collapse>
                        </div>
                        )
                    }
                </div>
            </Radio.Group>
        </div>
  )
}
import React from "react";
import {Modal, Form, Typography, Row, Col, Button, Space} from "antd"
import { NFInput } from "../Form";
import { sellItem } from "../../shared"
import { useTranslation } from "react-i18next";
const {Title}= Typography
const {Text}= Typography
const SellItemModal=({visible, data,onClose})=>{
    const { t }= useTranslation()
    const [form] = Form.useForm();
    const btnRef = React.useRef() 
    const selectFile = () => {
        btnRef.current.click()
    }
    const sell=async ()=>{
        let Data= form.getFieldsValue()
        const user_id=localStorage.getItem("userId")
        let formData = new FormData()
        formData.append(`user_id`, user_id)
        formData.append(`store_id`, data?.store_id)
        formData.append(`type`, data?.sale_type)
        formData.append(`amount`, Data?.amount)
        formData.append(`quantity`, 1)
        await sellItem(formData)
        onClose()
    }
    return (
        <Modal
            title={<Title className="my-0" level={4}>{t("Sell Collectible(Comming Soon)")}</Title>}
            visible={visible}
            onClose={onClose}
            onCancel={onClose}
            footer={
                <Button 
                    type='primary'
                    htmlType='submit'
                    onClick={selectFile}
                    disabled
                    >
                    {t("Sell NFT")}
                </Button>
            }
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                onFinish={sell}
                >
                <Row gutter={[16,16]}>
                    <Col span={24}>
                        <NFInput
                            label= {t('Price')}
                            name="amount" 
                            value={form.getFieldValue('amount')}
                            placeholder= {t('e.g 20')}
                            size='large'
                            required
                            message= {t('please enter selling price')}
                            />
                    </Col>
                    <Col span={24}>
                        <Space direction='vertical'>
                            <Text strong>{t("Service fees will be applied")}</Text>
                            <Text strong>{t("Net selling price")}:</Text>
                        </Space>
                        <Button 
                            htmlType='submit'
                            ref={btnRef}
                            style={{display:'none'}}
                            >
                            Search
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
export {SellItemModal}
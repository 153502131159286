import "./index.css"
import DisplayTrack from "./DisplayTrack";
import Controls from "./Controls";
import ProgressBar from "./ProgressBar"
import { useState , useRef, useEffect} from "react"
import {Space, Button, Slider} from "antd"
import { useSelector,useDispatch } from "react-redux"
import { actionsApi, generateRandomNumberExcluding } from "../../shared"
import { NavLink } from "react-router-dom"
import {
  IconVolume,
  IconVolume2,
  IconVolumeOff,
  IconResize,
  IconLayoutNavbarExpand,
  IconMusic
} from '@tabler/icons-react'
import {
  CloseOutlined
} from "@ant-design/icons"
const AudioPlayer = () => {
  const dispatch= useDispatch()
  const {trackList, currentTrack, isRepeat}= useSelector(state => state?.track)
  const audioRef = useRef();
  const progressBarRef = useRef()
  const [timeProgress, setTimeProgress] = useState(0)
  const [duration, setDuration] = useState(0)
  const [volume, setVolume]= useState(60)
  const [expand, setExpand]= useState(true)
  useEffect(() => {
    if (audioRef) {
      audioRef.current.volume = volume / 100
    }
  }, [volume, audioRef])
  const changeTrack=(next, ended=false)=>
      {
        let nextTrackIndex
        if(ended)
        {
          if(isRepeat || trackList?.length===1)
            {
              audioRef.current.currentTime=0
              audioRef.current.play()
              return
            }
          else
            nextTrackIndex= generateRandomNumberExcluding(currentTrack, trackList?.length)
        }
        else
        {
          if(next)
            nextTrackIndex= currentTrack===(trackList?.length-1)?0:currentTrack+1
          else
            nextTrackIndex= currentTrack===0?(trackList?.length-1):currentTrack-1
        }
        dispatch(actionsApi?.playTrack({isPlaying: true, currentTrack: nextTrackIndex}))
      }
    return (
      <div className={currentTrack!==null?'player-cover animation':'player-cover animation player-cover-hide'}>
        <div className='py-1 player px-3 width-93'>
          <div>
              <DisplayTrack 
                {...{trackList, currentTrack, audioRef, setDuration, progressBarRef, changeTrack, expand}}
                />
          </div>
          <div 
            className='px-2 player-middle'
            >
            <Space direction='vertical' style={{width:'100%'}} size={1}>
              <Controls {...{audioRef, progressBarRef, duration, setTimeProgress, changeTrack, expand}} />
              <ProgressBar {...{ progressBarRef, audioRef, timeProgress, duration, expand}}/>
            </Space>
          </div>
          <div className="player-right-controls-cover">
            <Button
                icon={<CloseOutlined />}
                shape='circle'
                onClick={()=> dispatch(actionsApi?.clearTrack())}
                className='music-button-transparent'
                />
            <div 
              className="player-right-controls w-100"
              >
              <span>
                {/* <Button
                  onClick={()=> setExpand(!expand)}
                  shape='circle'
                  icon={expand?<IconLayoutNavbarExpand/>: <IconResize/>}
                  className='music-button-transparent'
                  /> */}
                <NavLink 
                  to={'/collection-detail/'+(trackList[currentTrack]?.store_id ||trackList[currentTrack]?.track_id)}
                  >
                  <Button
                    shape='circle'
                    icon={<IconMusic/>}
                    className='music-button-transparent'
                    />
                </NavLink>
              </span>
              <Button
                icon={
                  !volume?<IconVolumeOff/>:volume<66 ? <IconVolume2/>:<IconVolume/>
                  }
                shape='circle'
                onClick={()=>{
                  setVolume(volume?0:20)
                }}
                className='music-button-transparent hide-volume'
                />
              <Slider 
                size='small'
                value={volume}
                onChange={value => setVolume(value)}
                style={{width:'calc(100% - 150px)'}}
                className="hide-volume"
                />             
            </div>
          </div>
        </div>
      </div>
    );
  };
  export default AudioPlayer;
import { message } from "antd"
import { domainUrl4, domainUrl2 } from "../../constants/apiconstans/domanUrl"
import { Alert } from "../../../components"
export const checkout = (formData)=>{
    var myHeaders = new Headers()
    myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
    const userToken = localStorage.getItem('token')
    myHeaders.append("token", userToken || null)
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: formData
    };
   return (
        fetch(domainUrl4 + `/placeOrder`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result?.ResponseCode)
            {
                Alert("success", result?.ResponseMsg) 
                return 1
            }
            else
                throw result?.ResponseMsg
        })
        .catch(error => {
            Alert("error", error) 
            return 0
        })
   )
}
export const addToCart = (formData)=>{
    var myHeaders = new Headers()
    myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
    const userToken = localStorage.getItem('token')
    myHeaders.append("token", userToken || null)
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: formData
    };
   return (
        fetch(domainUrl4 + `/addToCart`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result?.ResponseCode)
                return result?.ResponseMsg
            else
                throw result?.ResponseMsg
        })
        .catch(error => {
            message.error(error)
            return 0
        })
   )
}
export const addToCartArtist = (formData)=>{
    var myHeaders = new Headers()
    myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
    const userToken = localStorage.getItem('token')
    myHeaders.append("token", userToken || null)
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: formData
    };
   return (
        fetch(domainUrl2 + `/addtocartArtist`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result?.ResponseCode)
                return 1
            else
                throw 'error while adding item to cart'
        })
        .catch(error => {
            Alert("error", error) 
            return 0
        })
   )
}
export const removeCartItem = (formData)=>{
    var myHeaders = new Headers()
    myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
    const userToken = localStorage.getItem('token')
    myHeaders.append("token", userToken || null)
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: formData
    };
   return (
        fetch(domainUrl4 + `/removeCartItem`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result?.ResponseCode)
                {
                    message.success("Item removed from the cart.")
                    return 1
                }
            else
                throw 'error while removing item from cart'
        })
        .catch(error => {
            message.error(error)
            return 0
        })
   )
}
export const beforeAddToCartMarketplace=async (store_id, marketplace_id)=>{
    let user_id= localStorage?.getItem("userId")
    let formData = new FormData()
    formData.append(`user_id`,user_id || null)
    formData.append(`store_id`,store_id || null)
    formData.append(`marketplace_id`,marketplace_id || null)
    return await addToCart(formData)
}
export const beforeAddToCart=async (store_id, quantity=1)=>{
    let user_id= localStorage?.getItem("userId")
    let formData = new FormData()
    formData.append(`user_id`,user_id || null)
    formData.append(`store_id`,store_id || null)
    formData.append(`quantity`, quantity)
    return await addToCart(formData)
}
export const beforeAddToCartArtist=async (artist_id, amount=200)=>{
    let user_id= localStorage?.getItem("userId")
    let formData = new FormData()
    formData.append(`user_id`,user_id || null)
    formData.append(`artist_id`,artist_id || null)
    formData.append(`amount`, amount)
    return await addToCartArtist(formData)
}
export const beforeRemoveCartItem=async (store_id)=>{
    let user_id= localStorage?.getItem("userId")
    let formData = new FormData()
    formData.append(`user_id`,user_id || null)
    formData.append(`store_id`,store_id || null)
    return await removeCartItem(formData)
}
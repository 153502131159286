import SingleSocialSec from '../../components/singledetailcomp/SingleSocialSec'
import { ItemDetail as ItemDetailComponent } from '../../components/ItemDetail'
import { Benefits } from '../../components/Benefits'

const ItemDetail = () => {
  return (
    <>
      <div className='page-bg'>
        <ItemDetailComponent/>
        <Benefits/>
        <SingleSocialSec />
      </div>
    </>
  )
}

export {ItemDetail}
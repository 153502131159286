import React,{ useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Typography, Space, Form, Button, Image, Avatar, Input} from "antd";
import { NFInput, NFDateTimePicker, NFCheckbox} from '../../components';
import './signup.css'
import { Alert } from '../../components'
import CountryCodes from './CountryCodes'
import SignUpWithSoicalPlatforms from './SignUpWithSoicalPlatforms'
import { useTranslation } from "react-i18next"
import { domainUrl } from '../../shared/constants/apiconstans/domanUrl'
const {Text, Title}= Typography

const Signuppage = () => {
    const {t}= useTranslation()
    const navigate= useNavigate()
    const {language}= useSelector(state => state?.app)
    var md5 = require('md5')
    const [form] = Form.useForm()
    const fileRef = React.useRef() 
    const [state, setState] = useState(null)
    const [countryCode, setCountryCode]= useState('+33')
    const selectFile = () => {
            fileRef.current.click()
        }
    const [loading, setLoading]= useState(false)

    const RegisterUser=()=>{
        setLoading(true)
        let user= form.getFieldsValue()
        user= {
            ...user,
            phone: countryCode+" "+user?.phone?.trim()
        }
        var myHeaders = new Headers()
        myHeaders.append("Authorization", `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI1IiwianRpIjoiN2M3M2Q1ZjFlN2M5ZTI3OWVmZDVjODE1Njk0ODM4MjhmMDViNjE2MTEwY2YzMjUyYTRhNjJlNjgwOGRiNGU2OWVlYzNjOTU3ZGMxZDdmMTciLCJpYXQiOjE2NzcxNDA5NzQuNDQ0MDQ1LCJuYmYiOjE2NzcxNDA5NzQuNDQ0MDU0LCJleHAiOjE3MDg2NzY5NzQuMTY4MjE2LCJzdWIiOiIxMSIsInNjb3BlcyI6W119.I-uyOMaimHoJEaWFqmWHI8RzDsXkyEV368camDsmHOuKN-iJSVjmGyztKTIqZA3zwfazdFM-zmpH1Di4j6W1AQllDwoggsz458cbQj4O0EvPMlqbdPxarwYa49kJ-AOP9nxGyRdF7u4XpcMx14Nj5wQRgFxYSjedf3n7DIwxz-I-5YcaNInTqCvi7IslsZirY7Vh6aI3ruVgr_JgpgeFpfHH9X9tQU6Fm-PD9XgivZKtLeQIxJUypFxSWrxolEiMNYH_CCMKvJpolQIMwlC7uxcHzaOVcdWeHxm7pOkVYf0Hp9okWvywHPos_v6BUhGyvWS03yFvgRKRmFgN-AHs6M2ZuBavTr_l7usEx5G8XlCc0IDWjs4c7GGdtaUfdpIA3NkESFwwjet4mwnDd7b34k-hd9cOcxv_5GEe9MPHOSuLeBmRpMIp0ZsTFES9r0DgS8e1YZGccLsNXiyyBRERgzhVdkKjXl5IuvpGaWqQya96eWxGzEA5h6pTSxf4tXZvcH1TEoiV3gBlX9IzWSqygEZqPJmLaTLpJ6eE6xKKVHXc1WKajueBEccqrQawwoovbSZ3MV1zMNwRQ876kXsQ5vUWF7UrlxR7QSjMYt_htmmwO6xLyGLrwZv3scB_RX8HlO9G06vdzI276aJidJUjwyeUt6NQDYhv3wUgYHCPJE8`);
        myHeaders.append("Content-Type", "application/json")
        myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(user) ,
            redirect: 'follow'
        }
        fetch(domainUrl+'/signUp', requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result?.ResponseCode)
                {  
                    setLoading(false)
                    Alert('success', "user registered successfully");       
                    navigate("/", { replace: true })   
                }
            else
                throw  result?.ResponseMsg
        })
        .catch(error => {setLoading(false);Alert("error", error)})
    }
    const handleFileChange = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];   
        reader.onloadend = () => {
            setState({
                file: file,
                preview: reader.result
            })            
        }
        if(file)
            reader.readAsDataURL(file)
    }
     const openPDF=(src)=>{
        let pdfWindow = window.open("")
        pdfWindow.document.write(`<iframe width='100%' height='100%' src='${src}'></iframe>`)
    }
    return (
    <div className="flex-col-center py-5 page-bg">
        <div className="width-786">
            <Row gutter={[32, 4]}>
                <Col span={24}>
                    <Title level={2}>{t("SIGN UP.")}</Title>
                </Col>
                <Col span={24}>
                    <Row gutter={[16,10]} className='justify-center'>
                        <Col xs={24} sm={24}>
                            <Space direction="vertical" className="w-100 justify-center">
                                <Form
                                    form={form}
                                    layout="vertical"
                                    initialValues={true}
                                    onFinish={RegisterUser}
                                    >
                                    <Row gutter={[16,16]}>
                                        <Col span={24}>
                                            <div 
                                                direction="vertical" 
                                                size={3} 
                                                className='w-100 text-center maindiv'
                                                >   
                                                <input 
                                                    type="file"  
                                                    ref={fileRef} 
                                                    onChange={(e) => handleFileChange(e)} 
                                                    className='dnon' accept=".png, .jpg, .jpeg" 
                                                    />                                             
                                                <Avatar 
                                                    src={state?.preview ||'/assets/img/signup-img.png'} 
                                                    size={150}
                                                    alt='NFSounds profile upload'
                                                    shape='square'
                                                    />
                                                <div className='editbtn'>    
                                                    <label for='upload'  onClick={(e) => selectFile() } >
                                                        <Image src='/assets/img/editpen.svg' preview={false} style={{cursor: 'pointer'}}/>
                                                    </label>
                                                </div>
                                            </div>
                                            
                                        </Col>
                                        <Col md={12} sm={24} xs={24}>
                                            <NFInput
                                                name='fname'
                                                label={t('First Name')}
                                                size='large'
                                                required
                                                message={t('Please first name')} 
                                                placeholder='John' 
                                                value={form.getFieldValue('fname')}
                                                />
                                        </Col>
                                        <Col md={12} sm={24} xs={24}>
                                            <NFInput
                                                name='lname'
                                                label={t('Last Name')}
                                                size='large'
                                                required
                                                message={t('Please enter last name')} 
                                                placeholder='Doe' 
                                                value={form.getFieldValue('lname')}
                                                />
                                        </Col>
                                        <Col md={12} sm={24} xs={24}>
                                            <NFDateTimePicker
                                                datePicker
                                                name='dob'
                                                label={t('Date of birth')}
                                                size='large'
                                                required
                                                message={t('Please select date of birth')} 
                                                value={form.getFieldValue('dob')}
                                                />
                                        </Col>
                                        <Col md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name='username'
                                                label={<Typography.Text strong>{t("User name")}</Typography.Text>}
                                                rules={[
                                                    {
                                                        required: true,
                                                        validator: (_, value) => {
                                                           
                                                            const reg = new RegExp(/^(?!.*@.*$)(?=.*[a-zA-Z].*[a-zA-Z].*[a-zA-Z]).*$/)
                                                            if (!reg.test(value))
                                                                return Promise.reject(new Error(t('username message')))
                                                            else
                                                                return (Promise.resolve())
                                                        }
                                                    }
                                                ]}
                                                >
                                                <Input
                                                    placeholder='Johndeo01'
                                                    value={form.getFieldValue('username')} 
                                                    size='large'
                                                    />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} sm={24} xs={24}>
                                            <NFInput
                                                name='phone'
                                                label={t('Phone')}
                                                size='large'
                                                required
                                                addonBefore= {
                                                    <CountryCodes
                                                        value= {countryCode}
                                                        onChange={(value)=> setCountryCode(value)}
                                                    />
                                                }
                                                message={t('Please enter phone')}
                                                placeholder='711223344' 
                                                value={form.getFieldValue('phone')}
                                                />
                                        </Col>
                                        <Col md={12} sm={24} xs={24}>
                                            <NFInput
                                                type='email'
                                                name='email'
                                                label={t('Email')}
                                                size='large'
                                                required
                                                message={t('Please enter email')} 
                                                placeholder='user@nfsounds.com' 
                                                value={form.getFieldValue('email')}
                                                />
                                        </Col>
                                        <Col md={12} sm={24} xs={24}>
                                            <Form.Item
                                                name='password'
                                                label={<Typography.Text strong>{t("Password")}</Typography.Text>}
                                                rules={[
                                                    {
                                                        required: true,
                                                        validator: (_, value) => {
                                                            const reg = new RegExp(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/)
                                                            if (!reg.test(value))
                                                                return Promise.reject(new Error(t('password message')))
                                                            else
                                                                return (Promise.resolve())
                                                        }
                                                    }
                                                ]}
                                                >
                                                <Input.Password
                                                    value={form.getFieldValue('password')} 
                                                    size='large'
                                                    placeholder='Abcd@123'
                                                    />
                                            </Form.Item>
                                        </Col>
                                        <Col md={12} sm={24} xs={24}>
                                            <NFInput
                                                name='pincode'
                                                label={t('Zipcode')}
                                                size='large'
                                                required
                                                message={t('Please enter pincode')} 
                                                placeholder='00000' 
                                                value={form.getFieldValue('pincode')}
                                                />
                                        </Col>
                                        <Col md={8} sm={24} xs={24}>
                                            <NFInput
                                                name='address'
                                                label={t('Address')}
                                                size='large'
                                                required
                                                message={t('Please enter address')} 
                                                placeholder='1 rue du Chemin' 
                                                value={form.getFieldValue('address')}
                                                />
                                        </Col>
                                        <Col md={8} sm={24} xs={24}>
                                            <NFInput
                                                name='city'
                                                label={t('City')}
                                                size='large'
                                                required
                                                message={t('Please enter city')} 
                                                placeholder='Paris' 
                                                value={form.getFieldValue('city')}
                                                />
                                        </Col>
                                        <Col md={8} sm={24} xs={24}>
                                            <NFInput
                                                name='country'
                                                label={t('Country')}
                                                size='large'
                                                required
                                                message={t('Please enter country')} 
                                                placeholder='France' 
                                                value={form.getFieldValue('country')}
                                                />
                                        </Col>
                                        <Col span={24} className='justify-center'>
                                            <Form.Item>
                                                <div style={{width:'270px'}}>
                                                    <Button
                                                        size='large'
                                                        htmlType='submit'
                                                        type='primary'
                                                        loading={loading}
                                                        block
                                                    >
                                                        <Text strong style={{color:'white'}}>{t("Register")}</Text>
                                                    </Button>
                                                </div>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24} className='justify-center'>
                                            <NFCheckbox
                                                name='remember'
                                                label={
                                                    <Space className='cursor-pointer'>
                                                        <Text strong>{t("I have read and accepted the")}</Text>
                                                        <Text 
                                                            onClick={(e)=>{
                                                                e.stopPropagation()
                                                                openPDF(language === 'fr' ? '/assets/pdf/Terms of Use French.pdf' : '/assets/pdf/Terms of Use English.pdf')
                                                            }} 
                                                            strong
                                                            className='center link'
                                                            >
                                                            {t("Terms of Use")}
                                                        </Text>
                                                    </Space>
                                                    
                                                }
                                                required
                                                message={t('Should accept agreement')}
                                                /> 
                                        </Col>
                                    </Row>
                                </Form> 
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
        <SignUpWithSoicalPlatforms/>
    </div>
  )
}

export default Signuppage
import React from 'react'
import { Button, Image, Typography} from 'antd'
import "./index.css"
const {Link}= Typography
const CollectionBanner = () => {
  return (
    <div 
      className='collection-banner' 
      style={{backgroundImage:"linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url('/assets/img/banner.png')"}}>
        <div>
          <Link 
            href='https://oncyber.io/spaces/oaycV75EMWQUrN5KAFND'
            target='self'
            >
            <Button
              type='primary'
              size='large'
              className='gold-button'
              >
                ENTER SHOWROOM
            </Button>
          </Link>
        </div>
    </div>
  )
}

export {CollectionBanner}
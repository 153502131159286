import { Button,InputNumber} from "antd"
import { MinusOutlined, PlusOutlined } from "@ant-design/icons"
import "./index.css"

export const QuantityCounter=({quantity, index, available_stock, upDown, onChange, size})=>{
    return(
        <div className='quantity-counter' style={{display:'flex'}}>
            <Button 
                type="primary"
                size={size || "large"}
                className='prevrad'
                 disabled={quantity<=1}
                onClick={()=>{upDown(index, false)}}
                >
                <MinusOutlined className='up'/>
            </Button>
            <InputNumber 
                controls={false} 
                style={{width:'50px', border:'none', backgroundColor:'transparent'}} 
                value={quantity} 
                size={size || "large"}
                onChange={onChange}
                />
            <Button 
                type="primary"
                className='nextrad'
                size={size || "large"}
                disabled={quantity>=available_stock}
                onClick={()=>{upDown(index, true)}}
                >
                <PlusOutlined className='up'/>
            </Button>
        </div>
    )
}
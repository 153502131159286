import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl'
import { Alert } from '../../../../components'
export const getPostComments = createAsyncThunk('postComments',
    async (postID, { dispatch, getState }) => {
        dispatch(gettingPostComments())
        let userToken=localStorage.getItem('token')
        var myHeaders = new Headers();
        myHeaders.append("token", `${userToken}`)
        myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
        var requestOptions = {
            method: 'GET',
            headers:myHeaders,
            redirect: 'follow'
        }
        fetch(domainUrl + `/viewPostComment/${postID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode)
                    dispatch(postCommentsResponse(result?.data))
                else
                {
                    dispatch(postCommentsClear())
                    throw 'error'
                }
            })
            .catch(error => {
                dispatch(postCommentsClear())
                Alert("error","error while loading artist posts") 
            })
    }
)

const postCommentsSlice = createSlice({
    name: 'postComments',
    initialState: {
        data: [],
        loading: false
    },
    reducers: {
        gettingPostComments: (state, action) => {
            state.loading = true;
        },
        postCommentsResponse: (state, action) => {
            state.data = action.payload;
            state.loading= false;
        },
        postCommentsClear: (state, action) => {
            state.data = [];
            state.loading = false;
        },
    },
})

export const {gettingPostComments, postCommentsResponse, postCommentsClear} = postCommentsSlice.actions
export default postCommentsSlice.reducer
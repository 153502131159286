import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl';
import { Alert } from '../../../../components'
import { message } from 'antd';
export const login = createAsyncThunk('login',
    async (data, { dispatch, getState }) => {
        dispatch(getting())
        var myHeaders = new Headers()
        myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: data
        }
        fetch(domainUrl+"/login", requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode===1)
                {
                    let data=result?.data
                    localStorage.setItem('user', JSON.stringify(data));
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('userId', data.user_id)
                    dispatch(loginResponse(result?.data))
                    window.location="/"
                }
                else
                    {
                        message.error(result?.ResponseMsg)
                        dispatch(loginClear())
                    }
            })
            .catch(error => { dispatch(loginClear()); Alert("error","error while login. plz try again later") })
    }
)
export const generateToken = createAsyncThunk('login',
    async (data, { dispatch, getState }) => {
        dispatch(getting())
        var myHeaders = new Headers()
        myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
        myHeaders.append("content-type","application/json")
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(data)
        }
        fetch(domainUrl+"/generateToken", requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode===1)
                {
                    let data=result?.data
                    localStorage.setItem('user', JSON.stringify(data));
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('userId', data.user_id)
                    dispatch(loginResponse(result?.data))
                   window.location="/"
                }
                else
                    {
                        message.error(result?.ResponseMsg)
                        dispatch(loginClear())
                    }
            })
            .catch(error => { dispatch(loginClear()); Alert("error","error while login. plz try again later") })
    }
)
const loginSlice = createSlice({
    name: 'login',
    initialState: {
        data: null,
        loading: false
    },
    reducers: {
        getting: (state, action) => {
            state.loading = true;
        },
        loginResponse: (state, action) => {
            state.data = action.payload;
            state.loading = false
        },
        loginClear: (state, action) => {
            state.data = null;
            state.loading = false;
            localStorage.clear()
        }
    },
})


export const {getting, loginResponse, loginClear} = loginSlice.actions;
export default loginSlice.reducer;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl} from '../../../constants/apiconstans/domanUrl';
import { Alert } from '../../../../components';
import { message } from 'antd';
import { checkAuthorization } from '../../../helpers';
export const getMyCollection = createAsyncThunk('Collection',
    async (data, { dispatch, getState }) => {

        const {userToken, key}= checkAuthorization()
        dispatch(gettingCollection())
        var myHeaders = new Headers()
        myHeaders.append("key", key)
        myHeaders.append("token", userToken)
        myHeaders.append("pageNo", data?.pageNo)
        myHeaders.append("recordsPerPage", 6)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: data?.formData
        }
        fetch(domainUrl + `/getMycollection`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode===1)
                {
                    dispatch(collectionResponse(result))
                    dispatch(setCollectionPages({currentPage: data?.pageNo, totalRecords: result?.totalRecords}))
                    dispatch(setCollectionLookups({
                        artistLookup:result?.artist,
                        genreLookup:result?.type,
                    }))
                }
                else
                    throw result?.ResponseMsg
            })
            .catch(error => {
                dispatch(collectionClear())
                message.error("error", error) 
            })
    }
)

const collectionSlice = createSlice({
    name: 'collection',
    initialState: {
        data: null,
        pagination:{
            currentPage:0,
            totalRecords:0,
        },
        loading: false,
        collectionlookups:{artistLookup:[], genreLookup:[]},
        filters:{
            artist_id: null,
            type_id: null,
            price: null,
            release_date: null,
            search_text: null,
        }
    },
    reducers: {
        gettingCollection: (state, action) => {
            state.loading = true;
        },
        collectionResponse: (state, action) => {
            state.data = action.payload;
            state.loading= false;
        },
        collectionClear: (state) => {
            state.data = null
            state.loading = false
        },
        setCollectionPages:(state, action)=>{
            state.pagination= action.payload
        },
        setCollectionLookups:(state, action)=>{
            state.collectionlookups= action.payload
        },
        changeCollectionFilters:(state, action)=>{
            state.filters= action.payload
        }
    },
})

export const {
    gettingCollection, 
    collectionResponse, 
    collectionClear, 
    setCollectionPages,
    setCollectionLookups,
    changeCollectionFilters
} = collectionSlice.actions
export default collectionSlice.reducer
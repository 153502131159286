import React, {useState} from 'react'
import { Col, Row, Form, Button, Image, Input, Typography, Space} from "antd"
import { NFInput } from '../Form'
import { passwordChange } from '../../shared'
const {Text}= Typography
const SecurityForm = () => {
    const [form] = Form.useForm()
    const [loading, setLoading]= useState(false)
    const changePassword=async ()=>{
        setLoading(true)
        let data= form.getFieldsValue()
        let user_id= localStorage?.getItem("userId")
        await passwordChange({...data, user_id})
        setLoading(false)
    }
  return (
    <Space className='py-3 flex-col-center width-100' size={15}>
        <Image 
            src='/assets/img/logo.png' 
            preview={false} 
            width={120} 
            />
        {/* <div
            className='width-576 px-2 py-2 center secondary-bg fw-600 text-white radius-6'
            >
            Reset Password
        </div> */}
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            onFinish={changePassword}
            className='width-576'
            >
            <Row gutter={[16,16]}>
                <Col xs={24} sm={24}>
                    <NFInput
                        password
                        name='old_password'
                        label='Old Password'
                        size='large'
                        required
                        message='Please enter old password' 
                        value={form.getFieldValue('old_password')}
                        />
                </Col>
                <Col xs={24} sm={24}>
                    <Form.Item
                        name='new_password'
                        label={<Text strong>New Password</Text>}
                        rules={[
                            {
                                required: true,
                                validator: (_, value) => {
                                    const reg = new RegExp(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/)
                                    if (!reg.test(value))
                                        return Promise.reject(new Error('password should container atleast 6 characters, one uppercase, one number, one special character'))
                                    else
                                        return (Promise.resolve())
                                }
                            }
                        ]}
                        >
                        <Input.Password
                            value={form.getFieldValue('new_password')} 
                            size='large'
                            placeholder='Abcd@123'
                            />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24}>
                    <Form.Item
                        name='confirmPassword'
                        label={<Text strong>Confirm Password</Text>}
                        rules={[
                            {
                                required: true,
                                validator: (_, value) => {
                                    const reg = new RegExp(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/)
                                    if (value !== form.getFieldValue('new_password'))
                                        return Promise.reject(new Error('confirm password should be same'))
                                    else
                                        return (Promise.resolve())
                                }
                            }
                        ]}
                        >
                        <Input.Password
                            value={form.getFieldValue('confirmPassword')} 
                            size='large'
                            placeholder='Abcd@123'
                            />
                    </Form.Item>
                </Col>
                <Col span={24} align='center'>
                    <Button 
                        type='primary'
                        htmlType='submit'
                        loading={loading}
                        size='large'
                        >
                            Update Password
                    </Button>
                </Col>
            </Row>
        </Form>
    </Space>
  )
}

export default SecurityForm

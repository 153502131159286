import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl4} from '../../../constants/apiconstans/domanUrl';
import { Alert } from '../../../../components';
export const getCartList = createAsyncThunk('Cart',
    async (data, { dispatch, getState }) => {
        dispatch(gettingCart())
        const userToken = localStorage.getItem('token')
        let user_id= localStorage?.getItem("userId")
        var myHeaders = new Headers()
        myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
        myHeaders.append("token", userToken || null)
        let formData = new FormData()
        formData.append(`user_id`,user_id || null)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: formData
        };
        fetch(domainUrl4 + `/getCartList`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode)
                    dispatch(cartResponse(result))
                else
                {
                    dispatch(cartClear())
                    Alert("error", "error while getting cart list") 
                }
            })
            .catch(error => {
                dispatch(cartClear())
                Alert("error", error) 
            })
    }
)

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        data: null,
        loading: false
    },
    reducers: {
        gettingCart: (state, action) => {
            state.loading = true;
        },
        cartResponse: (state, action) => {
            state.data = action.payload;
            state.loading= false;
        },
        cartClear: (state, action) => {
            state.data = null;
            state.loading = false;
        },
    },
})

export const {gettingCart, cartResponse, cartClear} = cartSlice.actions
export default cartSlice.reducer
import React,{ useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from '../'
import { Space, Button, Typography } from 'antd'
import { HeartOutlined, HeartFilled} from '@ant-design/icons'
import './singlecss.css'
import { useSelector } from 'react-redux'
import { updateFavorite } from '../../shared'
import { useEffect } from 'react'
import { NFMediaPlayer } from '../../pages'
const { Title} = Typography

const LeftCardSingle2 = ({isCollectionItem}) => {
    const {t}= useTranslation()
    const {data, loading}= useSelector(state => state?.itemDetail)
    const [state, setState]= useState(null)
    useEffect(()=>{
        if(data)
            setState(data)
    },[data])
    const UpdateFavorite= async (store_id, is_Favourite)=>{
        let user_id= localStorage?.getItem("userId")
        if(!user_id)
        {
            Alert("error", "please login in")
            return
        }
        setState({
            ...state,
            is_Favourite: is_Favourite?'1':'0'
        })
        let formData = new FormData()
        formData.append(`user_id`, user_id)
        formData.append(`store_id`, store_id)
        await updateFavorite(formData)
    }
    console.log("state:", state)
  return (
            <div className='radius-6 item-detail-left' style={{overflow:'hidden'}}>
                <div className="header px-2 py-2 bg-black">
                    <div>
                        <Button 
                            className={
                                state?.rarity==='Diamond'?'diamond-button':
                                state?.rarity==='Platinum'?'platinum-button':
                                "gold-button"}
                            >
                            {
                                t(state?.rarity)
                            }
                        </Button>
                    </div>
                    <marquee>
                        <Title level={4} className='m-0 text-white light-font' ellipsis>
                            {
                                data?.store_name
                            }
                        </Title>
                    </marquee>
                    <div>
                        <Button
                            icon={ parseInt(state?.is_Favourite)?<HeartFilled style={{color:'red', fontSize:'28px'}}/>: <HeartOutlined className='favorite' style={{fontSize:'28px'}}/>}
                            shape='circle'
                            onClick={()=> UpdateFavorite(state?.store_id, !parseInt(state?.is_Favourite))}
                            />
                    </div>
                </div>
                <div 
                    className={(isCollectionItem && state?.type==='video' ? 'product-media-auto-height' : 'product-left-side')+' inner-shading'}
                    style={{background: `url("${isCollectionItem ? '' : state?.type==='video'?state?.thumbnail:state?.image}")`}}
                    >
                    {
                        isCollectionItem && state?.type==='video' ?
                            <div className='product-video'>
                                <NFMediaPlayer 
                                    src={state?.image} 
                                    controls={true}
                                    thumbnail= {state?.thumbnail}
                                    />
                            </div>
                        :<></>
                    }
                </div>
                
            </div>
  )
}

export default LeftCardSingle2
import {useState, useEffect, useRef} from "react"
import {Row, Col, Space, message, Divider} from "antd"
import { LoginSignupButton } from "../../components"
import { useGoogleLogin } from '@react-oauth/google'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { facebookAppID, generateRandomUsername, registerSocialMediaUser, socialUserRegister } from "../../shared"
import { useTranslation } from "react-i18next"

const SignUpWithSoicalPlatforms= () =>{
    const {t}= useTranslation()
    const [ user, setUser ] = useState(null)
 
    useEffect(() => {
        if (user) 
            getUsetData(user)
    }, [user] )
    
    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: () => message.error('something went wrong. Please try again later!!!')
    })
    const getUsetData= async (user)=>{
      await socialUserRegister({accessToken: user?.access_token})
    }

    // facebook
    const responseFacebook = async (response) => {
        console.log(response)
        let name= response?.name?.split(" ")
        let data= {
            firstName: name[0],
            lastName: name?.length>1 ? name[1] : '',
            picture: response?.picture?.url,
            googleId: response?.userID,
            email: response?.email,
            username: generateRandomUsername(name[0])
        }
        await registerSocialMediaUser(data)
      }
    return (
        <>
        <div className='justify-center width-96 py-2'>
            <Divider>{t('OR')}</Divider>
        </div>
        <div className="width-93">
            <Row gutter={[16,10]} className='justify-center'>
                <Col xs={24} sm={24} md={14} lg={7}>
                    <Space 
                        direction="vertical"
                        className="w-100"
                        size={15}
                        >
                        <LoginSignupButton
                            title={t('Sign Up with Google')}
                            style={{background: '#4186F5'}}
                            icon= 'google.png'
                            onClick={login}
                            />
                        <LoginSignupButton
                            title={t('Sign Up with Apple')}
                            style={{background: '#000'}}
                            icon= 'apple.png'
                            />
                        <FacebookLogin
                            appId= {facebookAppID}
                            fields="name,email,picture"
                            scope="public_profile,user_friends,email"
                            callback={responseFacebook}
                            render={renderProps => (
                                <LoginSignupButton
                                    title={t('Sign Up with Facebook')}
                                    style={{background: '#3B599A'}}
                                    icon= 'facebook.png'
                                    onClick={renderProps.onClick}
                                />
                            )}
                            />
                    </Space>
                </Col>
            </Row>
        </div>
        </>
    )
}
export default SignUpWithSoicalPlatforms 
import React from 'react'
import { TextTop, NewsComp} from '../../components/ArtistComponents'
const ArtistPage = () => {
  return (
    <>
      <TextTop />
      <NewsComp />
    </>
  )
}
export {ArtistPage}
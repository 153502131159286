import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl2} from '../../../constants/apiconstans/domanUrl';
import { Alert } from '../../../../components';
export const placeOrderArtist = createAsyncThunk('ArtistCheckout',
    async (formData, { dispatch, getState }) => {
        dispatch(gettingArtistCheckout())
        const userToken = localStorage.getItem('token')
        var myHeaders = new Headers()
        myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
        myHeaders.append("token", userToken || null)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: formData
        };
        fetch(domainUrl2 + `/placeOrder`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode)
                    {
                        Alert("success", result?.ResponseMsg)
                        // setTimeout(()=>{
                        //     window.location.href='/store'
                        // },[4000])
                    }
                else
                {
                    dispatch(artistCheckoutClear())
                    Alert("error", "error while artist checkout") 
                }
            })
            .catch(error => {
                dispatch(artistCheckoutClear())
                Alert("error", error) 
            })
    }
)

const artistCheckoutSlice = createSlice({
    name: 'artistCheckout',
    initialState: {
        data: null,
        loading: false
    },
    reducers: {
        gettingArtistCheckout: (state, action) => {
            state.loading = true;
        },
        artistCheckoutResponse: (state, action) => {
            state.data = action.payload;
            state.loading= false;
        },
        artistCheckoutClear: (state, action) => {
            state.data = null;
            state.loading = false;
        },
    },
})

export const {gettingArtistCheckout, artistCheckoutResponse, artistCheckoutClear} = artistCheckoutSlice.actions
export default artistCheckoutSlice.reducer
import {Typography, Image} from "antd"
import "./index.css"
const {Title}= Typography
const LoginSignupButton= ({title, icon, ...props})=>{
    return (
        <div className="width-100 login-signup-button radius-6 p-1" {...props}>
            <span className="p-1 radius-6 bg-white">
                <Image 
                    src={"/assets/icons/"+icon} 
                    alt={'NFsounds login with '+title} 
                    preview={false}
                    />
            </span>
            <div className='center px-2'>
                <Title level={5} className='text-white my-0' ellipsis>
                    {title}
                </Title>
            </div>
        </div>
    )
}
export {LoginSignupButton}
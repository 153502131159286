import React from 'react'
import { Space, Typography } from 'antd'
import { FaqsList } from '../../components'

const { Title, Text } = Typography

const Faqs = () => {
  return (
    <div className='justify-center py-5'>
      <div className='width-90'>
        <Space direction='vertical' className='w-100' size={15}>
          <Space direction='vertical'>
            <Title level={2} className='m-0'  >Frequently asked questions</Title>
            <Text className="fs-16" style={{fontWeight:"600"}}>Join Stacks community now to get free updates and also alot of freebies are waiting for you or</Text>
          </Space>
          <FaqsList />
        </Space>
      </div>
    </div>
  )
}

export {Faqs} 
import { Form, Row, Col, Button, Typography} from 'antd'
import React, { useEffect, useState } from 'react'
import {NFSearch, NFSelect} from '../Form';
import { useDispatch, useSelector } from 'react-redux';
import { actionsApi, checkAuthorization} from '../../shared';
import { releaseDateLookup, storePriceLookup } from '../../shared/lookups'
import { useTranslation } from 'react-i18next';
const {Title}= Typography

const Filters = () => {

    const { t }= useTranslation()
    const dispatch= useDispatch()
    const [form] = Form.useForm();
    const {collectionlookups}= useSelector(state => state?.collection)
    const [lookups, setLookups]= useState({artistLookup:[], genreLookup:[]})

    useEffect(()=>{
        form.setFieldsValue({
            artist_id: 0,
            type_id: 0,
            price: null,
            release_date:null,
            search_text: '',
            })
    }, [])

    useEffect(()=>{
        setLookups({
            artistLookup:[{id:0, name:'All'},...collectionlookups?.artistLookup?.map(artist=> ({...artist,id:artist?.artist_id}))],
            genreLookup:[{id:0, name:'All'},...collectionlookups?.genreLookup?.map(type=> ({...type,id:type?.type_id}))]
        })
    }, [collectionlookups])
    const filter=()=>{
        let data= form.getFieldsValue()
        const {user_id}= checkAuthorization()
        let formData = new FormData()
        formData.append(`user_id`,user_id)
        formData.append(`artist_id`, data?.artist_id || '')
        formData.append(`type_id`,data?.type_id || '')
        formData.append(`price`,data?.price)
        formData.append(`release_date`,data?.release_date || '')
        formData.append(`search_text`, data?.search_text || '')
        dispatch(actionsApi?.getMyCollection({formData, pageNo:1}))
    }
  return (
    <div className='space-between-end px-3'>
        <Title level={1} className='m-0'>
            {t("COLLECTION")}.
        </Title>
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            onFinish={filter}
            style={{width: '768px'}}
            >
            <Row gutter={[16,16]}>
                <Col md={7} sm={24} xs={24}>
                    <NFSearch
                        label= {t('Search Collection')}
                        name="search_text" 
                        value={form.getFieldValue('search_text') || ''}
                        placeholder='Search...'
                        size='large'
                        onSearch={filter}
                        />
                </Col>
                <Col md={5} sm={6} xs={12}>
                    <NFSelect
                        label= {t('Artist')}
                        name="artist_id" 
                        value={form.getFieldValue('artist_id') || ''}
                        onChange={filter}
                        options={lookups?.artistLookup}
                        maxTagCount= 'responsive'
                        size='large'
                        />
                </Col>
                <Col md={4} sm={6} xs={12}>
                    <NFSelect
                        label= {t('GENRE')}
                        name="type_id" 
                        value={form.getFieldValue('type_id') || ''}
                        onChange={filter}
                        options={lookups?.genreLookup}
                        maxTagCount= 'responsive'
                        size='large'
                        />
                </Col>
                <Col md={4} sm={6} xs={12}>
                    <NFSelect
                        label= {t('RELEASE DATE')}
                        name="release_date" 
                        value={form.getFieldValue('release_date') || ''}
                        onChange={filter}
                        options={releaseDateLookup}
                        maxTagCount= 'responsive'
                        size='large'
                        />
                </Col>
                <Col md={4} sm={6} xs={12}>
                    <NFSelect
                        label= {t('Price')}
                        name="price" 
                        value={form.getFieldValue('price') || ''}
                        onChange={filter}
                        options={storePriceLookup}
                        maxTagCount= 'responsive'
                        size='large'
                        />
                </Col>
                <Button 
                    type='primary'
                    size='large'
                    htmlType='submit'
                    style={{display:'none'}}
                    >
                    Search
                </Button>
            </Row>
        </Form>
    </div>
  )
}

export default Filters
import {Typography} from "antd";
import "./customize-progress-bar.css"
const {Text}= Typography
const ProgressBar = ({progressBarRef, audioRef, timeProgress,duration}) => {
    const handleProgressChange = () => {
        console.log(audioRef.current.currentTime);
        audioRef.current.currentTime = progressBarRef.current.value;
      };
      const formatTime = (time) => {
        if (time && !isNaN(time)) {
          const minutes = Math.floor(time / 60);
          const formatMinutes =
            minutes < 10 ? `0${minutes}` : `${minutes}`;
          const seconds = Math.floor(time % 60);
          const formatSeconds =
            seconds < 10 ? `0${seconds}` : `${seconds}`;
          return `${formatMinutes}:${formatSeconds}`;
        }
        return '00:00';
      };
    return (
      <div style={{width:'100%', display:'flex', justifyContent:"space-between", alignItems:"center"}}>
        <Text strong style={{width:'50px', color:'white'}}>
          {
            timeProgress ? formatTime(timeProgress) : '00:00'
          }
        </Text>
        <input 
          type="range"
          ref={progressBarRef}
          defaultValue="0"
          onChange={handleProgressChange}
          style={{width:'calc(100% - 100px)'}}
          className='animation'
          />
    
        <Text strong style={{width:'50px',  textAlign:'end', color:'white'}}>
          {
            formatTime(duration)
          }
        </Text>
      </div>
    )
  };
  export default ProgressBar;
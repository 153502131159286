import {Card, Avatar, Typography} from "antd"
const {Title}= Typography
const ArtistCard= ({artist, artistProfile})=>{
    return (
        <div 
            className={(artistProfile?'mx-2':'')+' artist-card bg-black radius-6 p-0 align-center overflow-hidden cursor-pointer'}
        >
            <Avatar
                src={artist?.image}
                size={65}
                alt='NFsounds'
                shape='square'
                />
            <div className='center px-2 h-100'>
                <Title level={3} className='text-white my-0' ellipsis>
                    {
                        artist?.name
                    }
                </Title>
            </div>
        </div>
    )
}
export {ArtistCard}
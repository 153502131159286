import React, { useEffect, useState } from 'react'
import { useNavigate} from 'react-router-dom'
import { Drawer, Typography, Button, Space, Divider, Form} from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { actionsApi, beforeAddToCart, beforeRemoveCartItem} from '../../shared'
import {DeleteOutlined} from '@ant-design/icons'
import { QuantityCounter } from '../'
import { NFCheckbox } from '../'
import "./index.css"
const { Title, Text } = Typography

const Cart = ({visible, onClose}) => {
    let navigate = useNavigate();
    const [form] = Form.useForm()
    const {data}= useSelector(state => state?.cart)
    const {language}= useSelector(state => state?.app)
    const dispatch= useDispatch()
    const [state, setState]= useState([])
    const [amount, setAmount]= useState(0)
    const [artistAmount, setArtistAmount]= useState(0)
   
    useEffect(()=>{
        if(visible)
            {
                dispatch(actionsApi?.getCartList())
                dispatch(actionsApi?.getArtistCartList())
            }
        else
            {
                setState([])
                setAmount(0)
            }
    },[visible])

    useEffect(()=>{
        if(data)
            if(data?.cart)
                setState(data?.cart)
    }, [data])
    useEffect(()=>{
        if(state)
        {
            let total=0;
            for(let i=0;i<state?.length;i++)
                total+= (state[i]?.quantity*state[i]?.product?.listing_price)
            setAmount(total)
        }
    },[state])
    const removeCartItem= async(store_id)=>{
        let result= await beforeRemoveCartItem(store_id)
        if(result)
            dispatch(actionsApi?.cartResponse(
                {
                    ...data,
                    cart: [...state?.filter(item=> item?.store_id!== store_id)]
                }
            ))
    }
    const upDown=async (index, countUp=true)=>{
        setState([
            ...state?.map((item, i)=>{
                if(i===index)
                return (
                    {
                        ...item,
                        quantity: countUp?(item?.quantity+1):(item?.quantity-1)
                    }
                )
                else 
                    return item
            })
        ])
        await beforeAddToCart(state[index]?.store_id, countUp?(state[index]?.quantity+1):(state[index]?.quantity-1))
    }
    const handleChange=async(value, index)=>{
        setState([
            ...state?.map((item, i)=>{
                if(i===index)
                return (
                    {
                        ...item,
                        quantity: value
                    }
                )
                else 
                    return item
            })
        ])
        await beforeAddToCart(state[index]?.store_id, value)
    }
    const openPDF=(src)=>{
        let pdfWindow = window.open("")
        pdfWindow.document.write(`<iframe width='100%' height='100%' src='${src}'></iframe>`)
      }
  return (
    <div>
        <Drawer 
            title='Cart' 
            className='drawercs'  
            placement="right"  
             visible={visible} 
             onClose={onClose}
             width={430}
             footer={
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={true}
                    onFinish={()=>{navigate("/checkout", { replace: true })}}
                    >
                    <NFCheckbox
                        name='remember'
                        label={
                            <Text strong className='cursor-pointer'>
                                I have read and accepted the <span 
                                    onClick={(e)=>{
                                        e.stopPropagation()
                                        openPDF(language === 'fr' ? '/assets/pdf/General conditions of sale French.pdf' : '/assets/pdf/General conditions of sale English.pdf')
                                    }} 
                                    className='link'
                                    >
                                    General Conditions of Sale
                                </span>
                            </Text>
                        }
                        required
                        message='Should accept agreement'
                        />
                    <div className='space-between-center'>
                        <Title level={4} classNamem='my-0'>Total: {amount+artistAmount} €</Title>
                        <Button
                            type='primary'
                            htmlType='submit'
                            >
                                Checkout
                        </Button>
                    </div>
                </Form>
             }
             >
            <div
                className='px-3 py-3'
                >
                {
                    state?.map((item, index)=>
                    <>
                        <Space size={20} direction='vertical' className='w-100 px-3' key={'cart-item-'+index}>
                            
                            <div 
                                className='center radius-6 inner-shading cart-product-card'
                                style={{background: `url('${item?.product?.type==='video'?item?.product?.thumbnail:item?.product?.image}')`}}
                                >
                                <Button 
                                    shape='circle' 
                                    type='primary' 
                                    danger 
                                    style={{position:"absolute", right:'10px', top:'10px'}}
                                    onClick={()=>{removeCartItem(item?.store_id)}}
                                    >
                                    <DeleteOutlined className='up'/>
                                </Button>
                            </div>
                            <div className='space-between-center' style={{maxWidth:"100%"}}>
                                <Space direction='vertical' size={0}>
                                    <Text strong>
                                        {item?.product?.listing_price*item?.quantity} €
                                    </Text>
                                    <Title level={4} className='my-0' ellipsis style={{maxWidth:'200px'}}>
                                        {
                                            item?.product?.store_name
                                        }
                                    </Title>
                                </Space>
                                <QuantityCounter
                                    quantity={item?.quantity}
                                    index={index}
                                    available_stock={item?.product?.available_stock}
                                    upDown={upDown}
                                    onChange={(value)=>{
                                        if(value>=1 && value<=item?.product?.available_stock)
                                            handleChange(value, index)
                                        else  if(value>item?.product?.available_stock)
                                            handleChange(item?.product?.available_stock, index)
                                        else 
                                        handleChange(1, index)
                                    }}
                                    />
                            </div>
                        </Space>
                        <Divider/>
                    </>
                    )
                }
            </div>
        </Drawer>
    </div>
  )
}

export {Cart}
import { useState } from "react"
import {Space, Skeleton, Avatar} from "antd"
import { ViewStory } from "./"

const ProfilePicture= ({loading, artistProfile, artistStories, isItemDetailPage})=>{

    const [visibleStoryModal, setVisibleStoryModal]= useState(false)

    return (
        <>
            <Space 
                className='center w-100 profile' 
                direction='vertical'
            >
                {
                    loading ?
                    <Skeleton.Button 
                        active 
                        size='large' 
                        shape={'square'} 
                        block
                        style={{width:'280px', height:'280px'}}
                        />
                    :
                    isItemDetailPage ?
                    <div className="item-detail-profile-picture">
                        <Avatar 
                            src={artistProfile?.image} 
                            alt='NFSounds'
                            size={280} 
                            shape='square'
                            />
                    </div>
                    :
                    <div 
                        className={artistStories?.length ? 'goldborder shadow-1' : 'no-story shadow-1'}
                        onClick={()=>{
                            if(artistStories?.length)
                                setVisibleStoryModal(true)
                        }}
                        >
                        <Avatar 
                            src={artistProfile?.image} 
                            alt='NFSounds'
                            size={280} 
                            shape='square'
                            />
                    </div>
                }
            </Space>
            <ViewStory
                visible={visibleStoryModal}
                artistProfile= {artistProfile}
                onClose={()=>{setVisibleStoryModal(false)}}
                />
        </>
    )
}
export {ProfilePicture}
import { useState, useEffect} from "react"
import { Col, Row, Typography, Space, Form, Button, Divider, message} from "antd"
import { NavLink} from "react-router-dom"
import { LoginSignupButton, NFInput } from "../../components"
import { useSelector, useDispatch } from "react-redux"
import { actionsApi, facebookAppID, googleClientKey } from "../../shared"
import { useTranslation } from "react-i18next"
import {useGoogleLogin } from '@react-oauth/google'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
const { Text, Title } = Typography

const Loginpage = () =>{
    const {t}= useTranslation()
    var md5 = require('md5');
    const dispatch= useDispatch()
    const [form] = Form.useForm()
    const {data, loading}= useSelector(state => state?.login)
    const Login= ()=>{
        let data= form.getFieldsValue()
        let formData = new FormData()
        formData.append(`email`,data?.email)
        // formData.append(`password`, md5(data?.password))
        formData.append(`password`, data?.password)
        
        dispatch(actionsApi?.login(formData))
    }
    console.log("key:", googleClientKey)
    const [ user, setUser ] = useState(null);
    const [ profile, setProfile ] = useState([]);
   
    useEffect(() => {
        if (user) {
            getUsetData(user)
        }
    }, [user] )
    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: () => message.error('something went wrong. Please try again later!!!')
    });
    console.log("user:", user)
    const getUsetData= async (user)=>{
        dispatch(actionsApi?.generateToken({accessToken: user?.access_token})) 
    }
    const responseFacebook = (response) => {
        dispatch(actionsApi?.generateToken({accessToken: response?.accessToken}))
      }
    return(
        <div className="flex-col-center py-5 page-bg">
            <div className="width-93">
                <Row gutter={[32, 8]}>
                    <Col span={24}>
                        <Title level={2}>{t("LOGIN")}.</Title>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[16,10]} className='justify-center'>
                            <Col xs={24} sm={24} md={14} lg={7}>
                                <Space direction="vertical" className="w-100 justify-center">
                                    <Space direction="vertical" size={3} className='width-100 text-center'>
                                        <Title level={4} className='m-0'>
                                            {t("DIVE INTO THE WORLD OF")}
                                        </Title>
                                        <Title level={2}  className='m-0'>
                                            {t("DIGITAL MUSIC COLLECTIBLES")}
                                        </Title>
                                    </Space>
                                    <Form
                                        form={form}
                                        layout="vertical"
                                        initialValues={true}
                                        onFinish={Login}
                                        >
                                        <Row gutter={[16,16]}>
                                            <Col span={24}>
                                                <NFInput
                                                    name='email'
                                                    type='email'
                                                    label={t('Email')}
                                                    size='large'
                                                    required
                                                    message={t('Please enter email')} 
                                                    placeholder='user@nfsounds.com' 
                                                    value={form.getFieldValue('email')}
                                                    />
                                            </Col>
                                            <Col span={24}>
                                                <NFInput
                                                    password
                                                    name='password'
                                                    label={t('Password')}
                                                    size='large'
                                                    required
                                                    message={t('Please enter password')} 
                                                    placeholder='Abcd@123' 
                                                    value={form.getFieldValue('password')}
                                                    />
                                            </Col>
                                            <Col span={24}>
                                                <div className="justify-end">
                                                    <NavLink to='/forgot-password' className='dim-color fw-500'>
                                                        {t("Forget Password?")}
                                                    </NavLink>
                                                </div>
                                                <Button 
                                                    type="primary" 
                                                    htmlType="submit"
                                                    size="large"
                                                    block
                                                    loading={loading}
                                                    >
                                                    {t("LOGIN")}
                                                </Button>
                                            </Col>
                                            <Col span={24}>
                                                <Space size={5} className='w-100 justify-center'>
                                                    <Text strong>{t("No account yet?")}</Text>
                                                    <NavLink to='/signup' className='dim-color fw-500'>{t("Create one")}</NavLink>
                                                </Space>
                                            </Col>
                                        </Row>
                                        
                                    </Form>
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className='justify-center width-96'>
                <Divider>
                    {t("OR")}
                </Divider>
            </div>
            <div className="width-93">
                <Row gutter={[16,10]} className='justify-center'>
                    <Col xs={24} sm={24} md={14} lg={7}>
                        <Space 
                            direction="vertical"
                            className="w-100"
                            size={15}
                            >
                            <LoginSignupButton
                                title={t('LOGIN with Google')}
                                style={{background: '#4186F5'}}
                                icon= 'google.png'
                                onClick={login}
                                />
                            <LoginSignupButton
                                title={t('LOGIN with Apple')}
                                style={{background: '#000'}}
                                icon= 'apple.png'
                                />
                            <FacebookLogin
                                appId= {facebookAppID}
                                fields="name,email,picture"
                                scope="public_profile,user_friends,email"
                                callback={responseFacebook}
                                render={renderProps => (
                                    <LoginSignupButton
                                        title={t('LOGIN with Facebook')}
                                        style={{background: '#3B599A'}}
                                        icon= 'facebook.png'
                                        onClick={renderProps.onClick}
                                    />
                                )}
                            />
                        </Space>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Loginpage
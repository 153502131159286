import { Button, Image} from "antd"
export const SocialMediaButton= ({icon, url})=>{
    return (
        <a 
            href={url}
            target="_blank"
            rel="external"
        >
            <Button
                shape='circle'
                icon={
                    <Image 
                        src={'/assets/icons/'+icon} 
                        alt='NFSounds social media button' 
                        preview={false}
                        width={23}
                        />
                }
                type='primary'
                size='large'
            />
        </a>
    )
}
import React,{ useState } from 'react'
import {Typography, Collapse } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'

const { Panel } = Collapse;
const {Title} = Typography;

const FaqsList = () => {
    const [currentPanel,setCurrentPanel]=useState(['1'])
    const faqData = 
        {   
            faqs:[
                {
                    
                    name:'Term of use',
                    description:`The Stacks series of products: Stacks: Landing Page Kit, Stacks: Portfolio Kit, Stacks: eCommerce Kit. "Stacks is a production-ready library of stackable content blocks built in React Native. Mix-and-match dozens of responsive elements to quickly configure your favorite landing page layouts or hit the ground running with 10 pre-built templates, all in light or dark mode."`
                },
                {
                    
                    name:'How to start with Stacks',
                    description:`The Stacks series of products: Stacks: Landing Page Kit, Stacks: Portfolio Kit, Stacks: eCommerce Kit. "Stacks is a production-ready library of stackable content blocks built in React Native. Mix-and-match dozens of responsive elements to quickly configure your favorite landing page layouts or hit the ground running with 10 pre-built templates, all in light or dark mode."`
                },
                {
                    
                    name:'Does it support Dark Mode',
                    description:`The Stacks series of products: Stacks: Landing Page Kit, Stacks: Portfolio Kit, Stacks: eCommerce Kit. "Stacks is a production-ready library of stackable content blocks built in React Native. Mix-and-match dozens of responsive elements to quickly configure your favorite landing page layouts or hit the ground running with 10 pre-built templates, all in light or dark mode."`
                },
                {
                    
                    name:'Does it support Auto-Layout',
                    description:`The Stacks series of products: Stacks: Landing Page Kit, Stacks: Portfolio Kit, Stacks: eCommerce Kit. "Stacks is a production-ready library of stackable content blocks built in React Native. Mix-and-match dozens of responsive elements to quickly configure your favorite landing page layouts or hit the ground running with 10 pre-built templates, all in light or dark mode."`
                },
                {
                    
                    name:'What is Stacks Design System',
                    description:`If you need further information, support or claim related to London ride share 2023, then contact us on our email or contact number.`
                },
            ]
        }
  return (
    <div>
        <Collapse
                defaultActiveKey={['1']}
                onChange={(keys)=>{setCurrentPanel(keys)}}
            >
                {
                    faqData?.faqs?.map((faq,f)=>
                        <Panel 
                            showArrow={false}  
                            header={
                                <Title className='my-0' level={5}>{faq?.name}</Title>
                            } 
                            key={f} 
                            extra={
                                ((currentPanel?.findIndex(x=>x==f))>-1)?
                                <MinusCircleOutlined className='font-20 brand-color'/>:
                                <PlusCircleOutlined className='font-20 brand-color'/>
                            }
                            >
                            <p className='my-0'>
                                {
                                    faq?.description
                                }
                            </p>
                        </Panel>
                    )
                }
        </Collapse>
    </div>
  )
}

export {FaqsList}
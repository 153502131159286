import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl'
import { Alert } from '../../../../components'
export const getArtistProfile = createAsyncThunk('ArtistProfile',
    async (object, { dispatch, getState }) => {
        dispatch(gettingArtistProfile())
        let userToken=localStorage.getItem('token')
        var myHeaders = new Headers();
        myHeaders.append("token", `${userToken}`)
        myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
        myHeaders.append("content-type", `application/json`)
        var requestOptions = {
            method: 'POST',
            headers:myHeaders,
            redirect: 'follow',
            body: JSON.stringify(object)
        }
        fetch(domainUrl + `/artistProfile`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode===1)
                    dispatch(artistProfileResponse(result?.data[0]))      
                else
                {
                    dispatch(artistProfileClear())
                    Alert("error","error while loading artist profile") 
                }
            })
            .catch(error => {
                dispatch(artistProfileClear())
                Alert("error","error while loading artist profile") 
            })
    }
)

const artistProfileSlice = createSlice({
    name: 'artistProfile',
    initialState: {
        data: null,
        loading: false
    },
    reducers: {
        gettingArtistProfile: (state, action) => {
            state.loading = true
            state.data= null
        },
        artistProfileResponse: (state, action) => {
            state.data = action.payload;
            state.loading= false;
        },
        artistProfileClear: (state) => {
            state.data = null;
            state.loading = false;
        },
    },
})

export const {gettingArtistProfile, artistProfileResponse, artistProfileClear} = artistProfileSlice.actions
export default artistProfileSlice.reducer
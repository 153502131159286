import { Alert } from "../../../components"
import { domainUrl } from "../../constants/apiconstans/domanUrl"
import { message } from "antd";
import { checkAuthorization } from "../../helpers"

const addPlaylist=(data)=>{
    let userToken=localStorage.getItem('token')
    var myHeaders = new Headers();
    myHeaders.append("token", `${userToken}`)
    myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
    var requestOptions = {
      headers: myHeaders,
      method: 'POST',
      redirect: 'follow',
      body: data
    }
    return (
      fetch(domainUrl+"/addPlayListType",requestOptions)
        .then(response=>response.json())
        .then(result=>{
            if(result?.ResponseCode)
                {
                    Alert("success", "Playlist created successfully")
                    return result?.play_list
                }
            else       
                throw result?.ResponseMsg
        })
        .catch((error)=>{
            Alert("error",error)
            return 0
        })
    )
}
const addToPlaylist=(data)=>{
    let userToken=localStorage.getItem('token')
    var myHeaders = new Headers();
    myHeaders.append("token", `${userToken}`)
    myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
    var requestOptions = {
      headers: myHeaders,
      method: 'POST',
      redirect: 'follow',
      body: data
    }
    return (
      fetch(domainUrl+"/addToMyPlaylist",requestOptions)
        .then(response=>response.json())
        .then(result=>{
            if(result?.ResponseCode)
                {
                    message.success(result?.ResponseMsg)
                    return 1
                }
            else       
                throw result?.ResponseMsg
        })
        .catch((error)=>{
            message.error(error)
            return 0
        })
    )
}
const getTrackList= (playlist_type) => {
    const userToken = localStorage.getItem('token')
    let user_id= localStorage?.getItem("userId")
    var myHeaders = new Headers()
    myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
    myHeaders.append("token", userToken || null)
    let formData = new FormData()
    formData.append(`user_id`,user_id || null)
    formData.append(`playlist_type`,playlist_type || null)
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: formData
    };
    fetch(domainUrl+`/getTrackList`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result?.ResponseCode)
                return result
            else
                throw "error while getting tracklist"
        })
        .catch(error => {
            Alert("error", error) 
            return 0
        })
}
const removeTrackFromPlaylist= (formData) => {
    const userToken = localStorage.getItem('token')
    var myHeaders = new Headers()
    myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
    myHeaders.append("token", userToken || null)
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: formData
    };
    return (
        fetch(domainUrl+`/removeFromMyPlaylist`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if(result?.ResponseCode)
            {
                Alert('success',result?.ResponseMsg)
                return 1
            }
            else
                throw result?.ResponseMsg
        })
        .catch(error => {
            Alert("error", error) 
            return 0
        })
    )
}
const order=(data)=>{
    const {userToken, key}= checkAuthorization()
    var myHeaders = new Headers();
    myHeaders.append("token", `${userToken}`)
    myHeaders.append("key", key)
    myHeaders.append("content-type", `application/json`)
    var requestOptions = {
      headers: myHeaders,
      method: 'POST',
      redirect: 'follow',
      body: JSON.stringify(data)
    }
    return (
      fetch(domainUrl+"/orderTrackList",requestOptions)
        .then(response=>response.json())
        .then(result=>{
            if(result?.ResponseCode)
                {
                    message.success("success", 'track reorder successfully')
                    return 1
                }
            else       
                throw result?.ResponseMsg
        })
        .catch((error)=>{
            message.error(error)
            return 0
        })
    )
}
const renameMyPlaylist= (data)=>{
    let userToken=localStorage.getItem('token')
    var myHeaders = new Headers();
    myHeaders.append("token", `${userToken}`)
    myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
    var requestOptions = {
      headers: myHeaders,
      method: 'POST',
      redirect: 'follow',
      body: data
    }
    return (
      fetch(domainUrl+"/renameMyPlaylist",requestOptions)
        .then(response=>response.json())
        .then(result=>{
            if(result?.ResponseCode)
                {
                    message.success(result?.ResponseMsg)
                    return result?.playlist_type
                }
            else       
                throw result?.ResponseMsg
        })
        .catch((error)=>{
            message.error(error)
            return 0
        })
    )
}
const removeMyPlaylist= (data)=>{
    let userToken= localStorage.getItem('token')
    var myHeaders = new Headers();
    myHeaders.append("token", `${userToken}`)
    myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
    var requestOptions = {
      headers: myHeaders,
      method: 'POST',
      redirect: 'follow',
      body: data
    }
    return (
      fetch(domainUrl+"/removeMyPlaylist",requestOptions)
        .then(response=>response.json())
        .then(result=>{
            if(result?.ResponseCode)
                {
                    message.success(result?.ResponseMsg)
                    return 1
                }
            else       
                throw result?.ResponseMsg
        })
        .catch((error)=>{
            message.error(error)
            return 0
        })
    )
}
export {
    addPlaylist, 
    addToPlaylist, 
    getTrackList, 
    removeTrackFromPlaylist, 
    order, 
    renameMyPlaylist, 
    removeMyPlaylist
}
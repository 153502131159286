import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl} from '../../../constants/apiconstans/domanUrl';
import { Alert } from '../../../../components';
import { checkAuthorizationWithoutLogin } from '../../../helpers'
import moment from 'moment'
export const getAllDroppingSoonItems = createAsyncThunk('DroppingSoonItems',
    async (data, { dispatch, getState }) => {
        console.log("i call")
        const {userToken, user_id, key}= checkAuthorizationWithoutLogin()
        dispatch(gettingDroppingSoonItems())
        var myHeaders = new Headers();
        myHeaders.append("key", key)
        myHeaders.append("token", userToken)
        myHeaders.append("pageNo", data?.pageNo)
        myHeaders.append("recordsPerPage", data?.pageSize)

        let formData = new FormData()
        formData.append(`user_id`,user_id)
        formData.append(`dateTime`,moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss'))

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: formData
        };
        fetch(domainUrl + `/upcoming-items`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.data)
                    {
                        dispatch(droppingSoonItemsResponse(result?.data))
                        dispatch(setPages({pageNo: data?.pageNo, pageSize: data?.pageSize, totalRecords: result?.totalRecords}))
                    }
                else
                    throw result?.message
            })
            .catch(error => {
                dispatch(droppingSoonItemsClear())
                console.error(error) 
            })
    }
)

const droppingSoonItemsSlice = createSlice({
    name: 'DroppingSoonItems',
    initialState: {
        data: [],
        pagination:{
            pageNo:1,
            pageSize: 6,
            totalRecords:0,
        },
        loading: false
    },
    reducers: {
        gettingDroppingSoonItems: (state) => {
            state.loading = true;
        },
        droppingSoonItemsResponse: (state, action) => {
            state.data = action.payload;
            state.loading= false;
        },
        droppingSoonItemsClear: (state) => {
            state.data = [];
            state.loading = false;
        },
        setPages:(state, action)=>{
            state.pagination=action.payload
        },
    },
})

export const {gettingDroppingSoonItems, droppingSoonItemsResponse, droppingSoonItemsClear, setPages} = droppingSoonItemsSlice.actions
export default droppingSoonItemsSlice.reducer
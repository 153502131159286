import {Form, Input, Typography} from 'antd';
const {Search}= Input

export const NFSearch = ({name,label,type, size, disabled,required,message,value,placeholder, ...props}) => {
  return (
    <Form.Item
        name={name}
        label={<Typography.Text strong>{label}</Typography.Text>}
        rules={[
            {
            required,
            message,
            },
        ]}
        >
        <Search 
            placeholder={placeholder || ''} 
            value={value || ''} 
            size={size || 'middle'}
            disabled={disabled || false}
            className='radius-6'
            {...props}
            />
    </Form.Item>  
  )
}
import { useState } from "react"
import { useDispatch, useSelector} from "react-redux"
import { Space, Button, Form, Input, Typography} from "antd"
import { actionsApi, commentOnPost, commentOnFeed} from "../../shared"
import {SendOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"
const {Text}= Typography
export const CommentSection=({feed, isPost})=>{

    const {t}= useTranslation()
    const {data: posts}= useSelector(state => state?.artistPosts)
    console.log("posts:", posts)
    const dispatch= useDispatch()
    const [form] = Form.useForm()
    const [loading, setLoading]= useState(false)

    const Comment= async ()=>{
        setLoading(true)
        let feed_id= feed?.feed_id
        let user_id= localStorage?.getItem("userId")
        let data=form.getFieldsValue()
        if(isPost)
        {
            await commentOnPost({
                user_id,
                comment: data?.comment,
                post_id: feed?.feed_id
            })
            setLoading(false)
            dispatch(actionsApi?.getPostComments(feed?.feed_id))
            dispatch(actionsApi?.artistPostsResponse([
                ...posts?.map(post=>{
                    if(post?.id===feed?.id)
                        return ({
                            ...post,
                            comments: post?.comments+1
                        })
                    return post
                })
            ]))
        }
        else
        {
            let formData = new FormData()
            formData.append(`user_id`, user_id || null)
            formData.append(`feed_id`, feed_id)
            formData.append(`comment`, data?.comment)
            let result= await commentOnFeed(formData)
            if(result)
                dispatch(actionsApi?.commentOnFeed(feed_id))
            setLoading(false)
        }
        form.resetFields()
    }
    
    return (
        <Form 
            layout="vertical"
            form={form}
            initialValues={true}
            onFinish={()=> Comment()}
            onClick={e => e.stopPropagation()}
            >
                <Form.Item
                    name={'comment'}
                    label={<Text strong>{t('Comment')}</Text>}
                    rules={[
                        {
                            required: true,
                            message: `${t('please enter comment')}`,
                        }
                    ]}
                    >
                    <Space 
                        className='w-100 borderGold spacfirst radius-6' 
                        style={{alignItems:'flex-end'}}
                        >
                        <Input 
                            type={'text'}
                            placeholder={t('Write a comment')+"..."} 
                            value={form.getFieldValue('comment')}
                            className='border0 w-100' 
                            style={{background: 'transparent'}}
                            />
                        <Button 
                            loading={loading}
                            htmlType='submit'
                            icon={<SendOutlined />}
                            className="post-like-button"
                            >
                        </Button>
                    </Space>
                </Form.Item> 
        </Form>
    )
}
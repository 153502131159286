import {useEffect} from "react"
import { useDispatch } from "react-redux"
import { CollectionBanner, CollectionComp } from '../../components/CollectionComponents'
import { useNavigate } from 'react-router-dom'
import { actionsApi } from "../../shared"

export const Collection = () => {
  const navigate= useNavigate()
  const dispatch= useDispatch()
    useEffect(()=>{
        if(!localStorage.getItem('token'))
          navigate(`/`, { replace: true })
        return (()=>{
          dispatch(actionsApi?.collectionClear())
        })
    },[])
  return (
    <div>
      <CollectionBanner />
      <CollectionComp />
    </div>
  )
}
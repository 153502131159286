import { useEffect } from 'react'
import { useSelector, useDispatch} from 'react-redux'
import { actionsApi } from '../../shared'
import { useParams } from 'react-router'
import { Col, Row } from "antd"
import LeftCardSingle2 from "./LeftCardSingle2"
import RightDetails2 from "./RightDetails2"
export const ItemDetail=({isCollectionItem, isMarketPlaceItem})=>{
    
    const {itemID, marketplaceID, storeID}= useParams()
    const dispatch= useDispatch()
    const {data: marketPlaceItem}= useSelector(state => state?.marketPlace)

    useEffect(()=>{
        if(isMarketPlaceItem)
            {
                dispatch(actionsApi?.getItemDetail(storeID))
                let user_id= localStorage?.getItem("userId")
                let formData = new FormData()
                formData.append(`user_id`, user_id)
                formData.append(`store_id`, storeID)
                formData.append(`marketplace_id`, marketplaceID)
                dispatch(actionsApi?.getMarketPlaceItems(formData))
            }
        else
            dispatch(actionsApi?.getItemDetail(itemID))
        return (
            ()=> {
                dispatch(actionsApi?.itemDetailClear())
                dispatch(actionsApi?.marketPlaceClear())
            }
        )
    },[])
    return (
        <div className="justify-center py-3">
            <Row className="width-93" gutter={[32, 32]}>
                <Col lg={13} sm={24} xs={24}>
                    <LeftCardSingle2
                        {...{isCollectionItem, isMarketPlaceItem,  marketPlaceItem}}
                    />
                </Col>
                <Col lg={11} sm={24} xs={24}>
                    <RightDetails2 
                        {...{isCollectionItem, isMarketPlaceItem,  marketPlaceItem}}
                        />
                </Col>
            </Row>
        </div>
    )
}
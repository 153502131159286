import React,{ useState, useEffect } from 'react'
import { Space, Avatar, Typography, Button} from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { actionsApi, beforeAddToCart, beforeRemoveCartItem } from '../../shared'
import {DeleteFilled } from '@ant-design/icons'
import { QuantityCounter } from '../'
import './cart.css'

const { Title, Text } = Typography

const LeftSide = ({setAmount}) => {
    const {data}= useSelector(state => state?.cart)
    const dispatch= useDispatch()
    const [state, setState]= useState([])
    useEffect(()=>{
        dispatch(actionsApi?.getCartList())
    },[])
    useEffect(()=>{
        if(data)
            if(data?.cart)
                    setState(data?.cart)
    }, [data])
    useEffect(()=>{
        if(state)
        {
            let total=0;
            for(let i=0;i<state?.length;i++)
                total+= (state[i]?.quantity*(state[i]?.amount || state[i]?.product?.listing_price))
            setAmount(total)
        }
    },[state])
    const removeCartItem= async(store_id)=>{
        let result= await beforeRemoveCartItem(store_id)
        if(result)
            dispatch(actionsApi?.cartResponse(
                {
                    ...data,
                    cart: [...state?.filter(item=> item?.store_id!== store_id)]
                }
            ))
    }
    const upDown=async (index, countUp=true)=>{
        setState([
            ...state?.map((item, i)=>{
                if(i===index)
                return (
                    {
                        ...item,
                        quantity: countUp?(item?.quantity+1):(item?.quantity-1)
                    }
                )
                else 
                    return item
            })
        ])
        await beforeAddToCart(state[index]?.store_id, countUp?(state[index]?.quantity+1):(state[index]?.quantity-1))
    }
    const handleChange=async(value, index)=>{
        setState([
            ...state?.map((item, i)=>{
                if(i===index)
                return (
                    {
                        ...item,
                        quantity: value
                    }
                )
                else 
                    return item
            })
        ])
        await beforeAddToCart(state[index]?.store_id, value)
    }
  return (
    <div className='dflex my-5'>
        <div className='width93'>
            <div direction='vertical' className='radius-12 bg-white w-100  shadow-1 parentspace'>
                {
                    state?.map((item, index)=>
                        <div 
                            className='w-100 space-between py-3 px-3 cartspace' 
                            key={'cart-item-'+index}
                            size={15}
                            wrap={true}
                            >
                            <Space size={15}>
                                <Avatar 
                                    src={item?.product?.type==='video'? item?.product?.thumbnail:item?.product?.image} 
                                    shape='square' 
                                    size={70} 
                                    />
                                <Space direction='vertical'>
                                    <Title level={4} className='m-0 fontmbl' ellipsis style={{maxWidth:'200px'}}>
                                        {
                                             item?.product?.store_name
                                        }
                                    </Title>
                                    <Button>
                                        {
                                            (item?.amount || item?.product?.listing_price)*item?.quantity
                                        } €
                                    </Button>
                                </Space>
                            </Space>
                            <Space>
                                {
                                    !item?.amount &&
                                    <QuantityCounter
                                        quantity={item?.quantity}
                                        index={index}
                                        available_stock={item?.product?.available_stock}
                                        upDown={upDown}
                                        onChange={(value)=>{
                                            if(value>=1 && value<=item?.product?.available_stock)
                                                handleChange(value, index)
                                            else  if(value>item?.product?.available_stock)
                                                handleChange(item?.product?.available_stock, index)
                                            else 
                                            handleChange(1, index)
                                        }} 
                                        />
                                }
                                <Button 
                                    shape='circle'
                                    size='small'
                                    className='border0'
                                    icon={<DeleteFilled className='red-5'/>}
                                    onClick={()=>{removeCartItem(item?.store_id)}}
                                    >
                                </Button>
                            </Space>
                        </div>
                    )
                }
                
            </div>
        </div>
    </div>
  )
}

export {LeftSide}
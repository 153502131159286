import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl2 } from '../../../constants/apiconstans/domanUrl';
import { Alert } from '../../../../components';
export const getAllAds = createAsyncThunk('Ads',
    async (data, { dispatch, getState }) => {
        // const tokenString = localStorage.getItem('token');
        // const userToken = JSON.parse(tokenString);
        dispatch(gettingAds())
        var myHeaders = new Headers();
        // myHeaders.append("Authorization", `Bearer ${userToken}`)
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl2 + `/ads`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.success)
                    dispatch(adsResponse(result?.records))
                else
                {
                    dispatch(adsClear())
                    Alert("error",result?.message) 
                }
            })
            .catch(error => {
                dispatch(adsClear())
                Alert("error","error while loading ads") 
            })
    }
)

const AdsSlice = createSlice({
    name: 'Ads',
    initialState: {
        data: [],
        loading: false
    },
    reducers: {
        gettingAds: (state, action) => {
            state.loading = true;
        },
        adsResponse: (state, action) => {
            state.data = action.payload;
            state.loading= false;
        },
        adsClear: (state, action) => {
            state.data = [];
            state.loading = false;
        },
    },
})

export const {gettingAds, adsResponse, adsClear} = AdsSlice.actions
export default AdsSlice.reducer
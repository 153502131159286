import { useEffect } from "react"
import { NavLink} from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {Typography, Space, Avatar, Dropdown,Menu, Button, Skeleton, Tooltip} from "antd"
import {PoweroffOutlined, UserOutlined} from "@ant-design/icons"
import {logoutUser, actionsApi} from '../shared'
const {Title, Text}= Typography

export const  UserDropDown =()=>{
    const appDispatcher= useDispatch()
    const {data, loading}= useSelector(state => state?.userProfile)
    useEffect(()=>{
      appDispatcher(actionsApi?.getUserProfile())
    }, [])
    const logout=async ()=>{
        await logoutUser()
        localStorage.clear()
        window.location.href='/'
      }
    const Notification = (
        <Menu 
          className="px-3"
          style={{
            transform:'translateY(2px)',
            width:'155px'
          }}
          disabled={loading}
          items={[
            {
              label:<NavLink to='/user-profile'>
                  <Space>
                      <UserOutlined className="up"/> 
                      <Text strong>Profile</Text>
                  </Space>
              </NavLink>,
              key: '0',
            },
            {
                label: <Space onClick={logout}>
                            <PoweroffOutlined className="up"/> 
                            <Text strong>Disconnect</Text>
                        </Space>,
                key: '1',
              }
          ]}
        />
      )
    return(
        <Dropdown 
          overlay={Notification} 
          trigger={['click']} 
          placement="bottom"
          arrow
          >
            <Button 
              type="primary" 
              className="py-0 center"
              >
                <Space size={12}>
                  {
                    loading ?
                      <>
                        <Skeleton.Avatar 
                          active 
                          size='small' 
                          shape='circle' 
                          />
                        <Skeleton.Input 
                          active 
                          size='small' 
                          style={{minWidth:'43px'}}
                          block
                          />
                      </>
                    :
                    <>
                      <Avatar
                        src={data?.profile || '/assets/img/signup-img.png'}
                        alt="nf-sounds"
                        size="small"
                        shape="square"
                        />
                      <Tooltip
                        title={data?.username}
                        placement="bottom"
                      >
                        <Title 
                          level={5} 
                          style={{color:'white', maxWidth:'43px'}} 
                          className='my-0'
                          ellipsis
                          >
                            {
                              data?.username
                            }
                        </Title>
                      </Tooltip>
                    </>
                  }  
                  
                </Space>
            </Button>
        </Dropdown>
    )
}
import { useDispatch } from "react-redux"
import { Space, Button, Typography} from "antd"
import { CommentOutlined, HeartFilled, HeartOutlined } from "@ant-design/icons"
import { actionsApi, likePost, likeUnlikeOnFeed} from "../../shared"
const {Title}= Typography
export const LikeSection=({feed, data, isPost})=>{
    console.log("feed:", feed)
    const dispatch= useDispatch()
    const like=async ()=>{
        let feed_id= feed?.feed_id
        let user_id= localStorage?.getItem("userId")
        if(isPost)
        {
            let result= await likePost({user_id, post_id: feed?.id})
            dispatch(actionsApi?.artistPostsResponse([
                ...data?.map(post=>{
                    if(post?.id===feed?.id)
                    return (
                        {
                            ...post,
                            likes: result?.ResponseMsg==='Dislike'?post?.likes-1: post?.likes+1,
                            likeStatus: !post?.likeStatus
                        }
                    )
                    return post
                })
            ]))
        }
        else
        {
            let formData = new FormData()
            formData.append(`user_id`, user_id || null)
            formData.append(`feed_id`, feed_id)
            let result= await likeUnlikeOnFeed(formData)
            if(result)
            dispatch(actionsApi?.likeUnlikeOnFeed(feed_id))
        }   
     }
    
    return (
        <Space size={10}>
            <Space size={4}>
                <Button
                    icon={(feed?.likeStatus || feed?.IsLike)?<HeartFilled style={{color:'red', fontSize:'28px'}}/>: <HeartOutlined className='favorite' style={{fontSize:'28px'}}/>}
                    shape='circle'
                    onClick={(e)=>{
                        e.stopPropagation()
                        like()
                    }}
                    className="post-like-button"
                    />
                <Title className='m-0' level={5}>
                    {
                        feed?.likes || feed?.like_count || 0
                    }
                </Title>
            </Space>
            <Space size={4}>
                <Button
                    icon={<CommentOutlined/>}
                    shape='circle'
                    className="post-comment-button"
                    />
                <Title className='m-0' level={5}>
                    {
                        feed?.comments || feed?.comment_count || 0
                    }
                </Title>
            </Space>
        </Space>
    )
}
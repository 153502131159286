
import { Alert } from "../../../components"
import { domainUrl } from "../../constants/apiconstans/domanUrl"
import {key} from "../../"
const getArtistChat=(artistID)=>{
    let userToken=localStorage.getItem('token')
    var myHeaders = new Headers();
    myHeaders.append("token", `${userToken}`)
    myHeaders.append("key", key)
    var requestOptions = {
      headers: myHeaders,
      method: 'GET',
      redirect: 'follow'
    }
    return (
      fetch(domainUrl+"/viewLiveChat/"+artistID,requestOptions)
        .then(response=>response.json())
        .then(result=>{
            if(result?.ResponseCode)
                return result?.data
            else       
                throw result?.message
        })
        .catch((error)=>{
            Alert("error",error)
            return 0
        })
    )
}
const sendMesage=(data)=>{
  let userToken=localStorage.getItem('token')
  var myHeaders = new Headers();
  myHeaders.append("token", `${userToken}`)
  myHeaders.append("key", key)
  myHeaders.append("content-type", `application/json`)
  var requestOptions = {
    headers: myHeaders,
    method: 'POST',
    redirect: 'follow',
    body: JSON.stringify(data)
  }
  return (
    fetch(domainUrl+"/SendLiveMessage",requestOptions)
      .then(response=>response.json())
      .then(result=>{
          if(result?.ResponseCode)
              return 1
          else       
              throw result?.message
      })
      .catch((error)=>{
          // Alert("error",error)
          return 0
      })
  )
}

export {getArtistChat, sendMesage}
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl} from '../../../constants/apiconstans/domanUrl'
import {checkAuthorizationWithoutLogin} from '../../../helpers'

export const getAllStoreItems = createAsyncThunk('Store',
    async (data, { dispatch, getState }) => {
        const {userToken, key}= checkAuthorizationWithoutLogin()
        dispatch(gettingStore())
        var myHeaders = new Headers()
        myHeaders.append("key", key)
        myHeaders.append("token", userToken)
        myHeaders.append("pageNo", data?.pageNo)
        myHeaders.append("recordsPerPage", data?.pageSize)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: data?.formData
        }
        fetch(domainUrl + `/getStores`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.data)
                {
                    dispatch(storeResponse(result?.data))
                    dispatch(setPages({pageNo: data?.pageNo, pageSize: data?.pageSize, totalRecords: result?.totalRecords}))
                    dispatch(setStoreLookups({
                        artistLookup:result?.artist,
                        genreLookup:result?.type,
                    }))
                }
                else
                    throw result?.message
            })
            .catch(error => {
                dispatch(storeClear())
                console.error(error)
            })
    }
)

const storeSlice = createSlice({
    name: 'Store',
    initialState: {
        data: null,
        loading: false,
        storeLookups:{artistLookup:[], genreLookup:[]},
        pagination:{
            pageNo:1,
            pageSize: 6,
            totalRecords:0,
        },
        filters:{
            artist_id: 0,
            type_id: 0,
            price: null,
            release_date: null,
            search_text: '',
        }
    },
    reducers: {
        gettingStore: (state) => {
            state.loading = true
        },
        storeResponse: (state, action) => {
            state.data = action.payload;
            state.loading= false;
        },
        storeClear: (state) => {
            state.data = null;
            state.loading = false;
        },
        setStoreLookups:(state, action)=>{
            state.storeLookups= action.payload
        },
        setPages:(state, action)=>{
            state.pagination= action.payload
        },
        changeStoreFilters:(state, action)=>{
            state.filters= action.payload
        }
    },
})

export const {gettingStore, storeResponse, storeClear, setStoreLookups, setPages, changeStoreFilters} = storeSlice.actions
export default storeSlice.reducer
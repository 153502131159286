import { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import {Space} from 'antd'
import './index.css'

const DroppingSoonSlider = ({data}) => {
    useEffect(()=>{
        if(data)
        {
            videoWidth()
            window.addEventListener('resize', videoWidth)
        }
    }, [data])
    const videoWidth= ()=>{
        let coverWidth= document.getElementsByClassName("dropping-soon-item-cover")[0]
        if(coverWidth)
        {
            coverWidth= coverWidth?.offsetWidth*0.20
            let items= document.getElementsByClassName("imgsiz-n")
            if(items)
            {
                for(let i=0; i<items?.length;i++)
                    {
                        items[i].style.width=`${coverWidth}px`
                        items[i].style.height=`${coverWidth}px`
                    }
            }
        }
    }
  return (
    <div className='justify-center'>
        {
            data?.length<=2 ?
            <Space className='width-93 dropping-soon-item-cover dropping-soon-item-cover-one-two' size={20}>
                {
                    data?.filter((_, index)=> index<=4)?.map((item, index)=>
                        <NavLink to={'/item-detail/'+item?.store_id}>
                            <div 
                                className={'imgsiz-n'} 
                                key={'dropping-soon-item-'+index}
                                style={{background:`url('${item?.type==='video' ? item?.thumbnail : item?.image}')`}}
                                >
                            </div>
                        </NavLink>
                        )
                }
            </Space>
            :
            data?.length<4 ?
            <Space className='width-93 dropping-soon-item-cover dropping-soon-item-cover-three' size={0}>
                {
                    data?.filter((_, index)=> index<=4)?.map((item, index)=>
                        <NavLink to={'/item-detail/'+item?.store_id}>
                            <div 
                                className={'imgsiz-n img'+(index+1)} 
                                key={'dropping-soon-item-'+index}
                                style={{background:`url('${item?.type==='video' ? item?.thumbnail : item?.image}')`}}
                                >
                            </div>
                        </NavLink>
                        )
                }
            </Space>
            :
            <Space className='width-93 dropping-soon-item-cover' size={0}>
                {
                    data?.filter((_, index)=> index<=4)?.map((item, index)=>
                        <NavLink to={'/item-detail/'+item?.store_id}>
                            <div 
                                className={'imgsiz-n img'+(index+1)} 
                                key={'dropping-soon-item-'+index}
                                style={{background:`url('${item?.type==='video' ? item?.thumbnail : item?.image}')`}}
                                >
                            </div>
                        </NavLink>
                        )
                }
            </Space>
        }
    </div>
  )
}

export {DroppingSoonSlider}
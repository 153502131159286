import { useState } from 'react'
import {Avatar, Modal, Space, Typography} from 'antd'
import { useSelector} from 'react-redux'
import Slider from 'react-slick'
import "./index.css"
import { NFMediaPlayer } from '../../../pages'
const { Title} = Typography;

const ViewStory = ({visible, artistProfile, onClose}) => {
    const {data, loading}= useSelector(state => state?.artistStories)
    const [currentStory, setCurrentStory]= useState(0)
    var businesspartner = {
      dots: false,
      infinite: false,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay:false,
      nextArrow: <p>ascd</p>,
      prevArrow: <p>abc</p>,
      afterChange: index => setCurrentStory(index),
      responsive: [    
        {
          breakpoint: 530,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
      ]
    }
  return (
    <div>
        <Modal
          title={null} 
          visible={visible}
          onCancel={onClose}
          onOk={onClose}
          footer={null}
          width={'450px'}
          className='story-drawer'
          centered
          destroyOnClose
          >
          <div className='story-cover'>
            <div
              className='stories'
              >
              {
                <Slider {...businesspartner}>
                  {
                    data?.map((story, index)=>
                      <div key={'story-'+index}>
                        <div className='story'>
                          {
                            story?.type==='video'?
                            <div className='video-story'>
                              <NFMediaPlayer 
                                src={story?.image} 
                                controls={false}
                                autoplay={true}
                                play={index===currentStory}
                                isStory
                                />
                            </div>
                            :
                            <img
                              key={index}
                              src={story?.image}
                              alt='nf-sounds'
                              style={{
                                margin:'auto',
                                maxWidth:'100%',
                                maxHeight:'100%',
                                width:'auto',
                                height: 'auto'
                              }}
                              />
                          }
                        </div>
                      </div>
                    )
                  }
                </Slider>
              }
              <div className='header'>
                <Space>
                  <Avatar
                    size={40}
                    src={artistProfile?.image}
                    alt='nf-sounds'
                    />
                  <Title level={5} className='my-0 text-white'>{artistProfile?.name}'s Story</Title>
                </Space>
              </div>
            </div>
          </div>
        </Modal>
    </div>
  )
}

export {ViewStory}
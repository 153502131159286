import {Image, Typography, Space} from "antd"

const {Title}= Typography
const DataNotFound= ({alt})=>{
    return (
        <Space className="width-100 pt-3" direction="vertical center">
            <Image 
                src={'/assets/img/Nothing-to-see-yet.png'}
                alt= {alt}
                preview={false}
            />  
            <Title level={2} className='my-0'>
                Sorry ! Nothing yet ...
            </Title>
        </Space>
    )
}
export {DataNotFound}
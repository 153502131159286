import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl3 } from '../../../constants/apiconstans/domanUrl';
import { Alert } from '../../../../components';
export const getSalesPersons = createAsyncThunk('salesPersons',
    async (data, { dispatch, getState }) => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        dispatch(gettingSalesPersons())
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${userToken}`);
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        fetch(domainUrl3 + `/sales-reps`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.success)
                    dispatch(salesPersonsResponse(result?.data))
                else
                {
                    dispatch(salesPersonsClear())
                    Alert("error",result?.message) 
                }
            })
            .catch(error => {
                dispatch(salesPersonsClear())
                Alert("error","error while loading sales Persons") 
            })
    }
)

const SalesPersonsSlice = createSlice({
    name: 'salesPersons',
    initialState: {
        data: [],
        loadingSalesPersons: false
    },
    reducers: {
        gettingSalesPersons: (state, action) => {
            state.loadingSalesPersons = true;
        },
        salesPersonsResponse: (state, action) => {
            state.data = action.payload;
            state.loadingSalesPersons= false;
        },
        salesPersonsClear: (state, action) => {
            state.data = [];
            state.loadingSalesPersons = false;
        },
    },
})

export const {gettingSalesPersons, salesPersonsResponse, salesPersonsClear} = SalesPersonsSlice.actions;
export default SalesPersonsSlice.reducer;
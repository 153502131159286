import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl} from '../../../constants/apiconstans/domanUrl';
import { Alert } from '../../../../components';
import { checkAuthorization } from '../../../helpers';
import { message } from 'antd';
export const getMyCollectionMusic = createAsyncThunk('CollectionMusic',
    async (_, { dispatch, getState }) => {
        const {userToken, user_id, key}= checkAuthorization()
        dispatch(gettingCollectionMusic())
        var myHeaders = new Headers()
        myHeaders.append("key", key)
        myHeaders.append("token", userToken)
        let formData = new FormData()
        formData.append(`user_id`, user_id)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: formData
        }
        fetch(domainUrl + `/collectionMusic`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode)
                    dispatch(collectionMusicResponse(result?.data))
                else
                    throw "error while getting Collection Music items"
            })
            .catch(error => {
                dispatch(collectionMusicClear())
                message.error(error) 
            })
    }
)

const collectionMusicSlice = createSlice({
    name: 'collectionMusic',
    initialState: {
        data: null,
        loading: false
    },
    reducers: {
        gettingCollectionMusic: (state) => {
            state.loading = true;
        },
        collectionMusicResponse: (state, action) => {
            state.data = action.payload
            state.loading= false
        },
        collectionMusicClear: (state) => {
            state.data = null
            state.loading = false
        }
    },
})

export const {gettingCollectionMusic, collectionMusicResponse, collectionMusicClear} = collectionMusicSlice.actions
export default collectionMusicSlice.reducer
export function generateRandomUsername(prefix, length=8) {
    const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const prefixLength = prefix.length 
    let randomUsername = prefix
    for (let i = prefixLength; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomUsername += characters.charAt(randomIndex);
    }
    return randomUsername;
  }
export function generateRandomNumberExcluding(exclude, limit) {
    let randomNumber
    do {
      randomNumber = Math.floor(Math.random() * limit)
    } while (randomNumber === exclude)
    return randomNumber
  }
  
  
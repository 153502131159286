import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl} from '../../../constants/apiconstans/domanUrl';
import { Alert } from '../../../../components';
import { checkAuthorization } from '../../../helpers';
export const showAllNotifications= createAsyncThunk('Notifications',
    async (object, { dispatch, getState }) => {
        const userToken = localStorage.getItem('token')
        let user_id= localStorage?.getItem("userId")
        dispatch(gettingNotifications())
        var myHeaders = new Headers();
        myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
        myHeaders.append("token", userToken || null)
        let formData = new FormData()
        formData.append(`user_id`, user_id || null)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: formData
        };
        fetch(domainUrl + `/showNotification`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode)
                    dispatch(notificationsResponse(result?.data))
                else
                {
                    dispatch(notificationsClear())
                    Alert("error", "error while getting notifications") 
                }
            })
            .catch(error => {
                dispatch(notificationsClear())
                Alert("error", error) 
            })
    }
)
export const clearNotifications= createAsyncThunk('Notifications',
    async (object, { dispatch, getState }) => {
        const {userToken, user_id, key}= checkAuthorization()
        var myHeaders = new Headers();
        myHeaders.append("key", key)
        myHeaders.append("token", userToken)
        let formData = new FormData()
        formData.append(`user_id`, user_id)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: formData
        }
        fetch(domainUrl + `/clearNotificaion`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode)
                    dispatch(clearNoticationsCount())
                else
                    throw "error while cleearing notifications"
            })
            .catch(error => {
                dispatch(notificationsClear())
                console.error(error) 
            })
    }
)

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        data: [],
        count:0,
        loading: false,
    },
    reducers: {
        gettingNotifications: (state, action) => {
            state.loading = true;
        },
        notificationsResponse: (state, action) => {
            state.data = action.payload;
            state.count= action?.payload?.length
            state.loading= false;
        },
        notificationsClear: (state, action) => {
            state.data = [];
            state.count= 0
            state.loading = false;
        },
        clearNoticationsCount: (state, action) =>{
            state.count= 0
        }
    }
})

export const {
    gettingNotifications, 
    notificationsResponse, 
    notificationsClear,
    clearNoticationsCount
} = notificationsSlice.actions
export default notificationsSlice.reducer
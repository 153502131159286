import { message } from 'antd'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl} from '../../../constants/apiconstans/domanUrl'
import { checkAuthorization } from '../../../helpers'

export const getPlaylists = createAsyncThunk('Playlists',
    async (data, { dispatch, getState }) => {

        const {userToken, key, user_id}= checkAuthorization()    
        dispatch(gettingPlaylists())
        var myHeaders = new Headers()
        myHeaders.append("key", key)
        myHeaders.append("token", userToken)
        let formData = new FormData()
        formData.append(`user_id`, user_id)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: formData
        };
        fetch(domainUrl+`/getTrackList`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode)
                    dispatch(playlistsResponse(result?.playlist_type))
                else
                    throw 'error while getting playlists'
            })
            .catch(error => {
                dispatch(playlistsClear())
                message.error(error) 
            })
    }
)

const playlistsSlice = createSlice({
    name: 'playlists',
    initialState: {
        data: null,
        loading: false,
        playlists: []
    },
    reducers: {
        gettingPlaylists: (state) => {
            state.loading = true
        },
        playlistsResponse: (state, action) => {
            state.data = action.payload;
            state.loading= false
        },
        playlistsClear: (state) => {
            state.data = null
            state.loading = false
        },
    },
})

export const {gettingPlaylists, playlistsResponse, playlistsClear} = playlistsSlice.actions
export default playlistsSlice.reducer
import {useNavigate} from "react-router-dom"
import { AutoComplete, Avatar, Image, Input, Space, Typography } from 'antd'
import { useState } from 'react'
import { IconSearch } from '@tabler/icons-react';
import { searchArtist } from '../../shared';
import { LoadingOutlined } from '@ant-design/icons'
import { useTranslation } from "react-i18next";
const {Text}= Typography
const AutoCompleteInput = () => {

  const {t}= useTranslation()
  const navigate= useNavigate()
  const [loading, setLoading]= useState(false)
  const [options, setOptions] = useState([])

  const handleSearch =async (name) => {
    setLoading(true)
    let result= await searchArtist({name})
    setOptions([
      ...result?.map(_=>(
        {
          id: _?.id , 
          value:_?.name, 
          label:<Space size={6}>
            <Avatar 
              src={_?.icon} 
              alt='NFsounds' 
              size={45}
              shape="circle"
              />
            <Space direction='vertical' size={3}>
              <Text strong>
              {
                _?.name
              }
              </Text>
              <Text>
              {
                _?.name
              }
              </Text>
            </Space>
          </Space>
        }
      ))
    ])
    setLoading(false)
  }
  const onSelect = (value) => {
    let index= options?.findIndex(fin=>fin?.value===value)
    if(index>-1)
      navigate(`/artist/${options[index]?.id}`, { replace: true })
  }

  return (
    <AutoComplete
      options={options}
      onSelect={onSelect}
      onSearch={handleSearch} 
      style={{width: '100%'}}
    >
      <Input 
          size="large" 
          className='w-100 radius-6 commn-btn'
          placeholder= {t("Search artist")}
          prefix={<IconSearch />}
          suffix={loading && <LoadingOutlined/>}
          />
    </AutoComplete>
  )
}
export {AutoCompleteInput}
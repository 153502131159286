import { useState, useRef } from "react"
import ReactPlayer from "react-player"
import "./index.css"
import Control from "./Control"
import { formatTime } from "./format";
import { useEffect } from "react";

let count= 0
export const NFMediaPlayer=({src, autoplay, thumbnail, play, isStory})=>{
    const videoPlayerRef = useRef(null);
    const progressBarRef = useRef()
    const controlRef = useRef(null);
  
    const [videoState, setVideoState] = useState({
      playing: false,
      muted: false,
      volume: 0.5,
      playbackRate: 1.0,
      played: 0,
      seeking: false,
      buffer: false,
    });
  
    //Destructuring the properties from the videoState
    const { playing, muted, volume, playbackRate, played, seeking, buffer } = videoState
    const currentTime = videoPlayerRef.current
      ? videoPlayerRef.current.getCurrentTime()
      : "00:00";
    const duration = videoPlayerRef.current
      ? videoPlayerRef.current.getDuration()
      : "00:00";
  
    const formatCurrentTime = formatTime(currentTime);
    const formatDuration = formatTime(duration);
    useEffect(()=>{
      if(isStory)
      {
        if(!play)
          setVideoState({ ...videoState, playing: false })
      }
    },[isStory, play])
    useEffect(()=>{
      if(currentTime===duration)
      setVideoState({
        ...videoState,
        playing:false,
        played: 0,
        seeking: false,
        buffer: false
      })
    }, [currentTime, duration])
    useEffect(()=>{
      if(autoplay)
        playPauseHandler()
    }, [autoplay])
    const playPauseHandler = () => {
      //plays and pause the video (toggling)
      setVideoState({ ...videoState, playing: !videoState.playing });
    };
  
    const rewindHandler = () => {
      videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() - 5);
    };
  
    const handleFastFoward = () => {
      videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() + 10);
    }
    const progressHandler = (state) => {
      if (count > 0) {
        controlRef.current.style.visibility = "hidden";
      } else if (controlRef.current.style.visibility === "visible") {
        count += 1;
      }
  
      if (!seeking) {
        setVideoState({ ...videoState, ...state });
      }
    }

    const seekHandler = (e, value) => {
      setVideoState({ ...videoState, played: parseFloat(value / 100) });
      videoPlayerRef.current.seekTo(parseFloat(value / 100));
    }

    const onSeekMouseDownHandler = (e) => {
      setVideoState({ ...videoState, seeking: true });
    }
    const seekMouseUpHandler = (e, value) => {
      console.log(value);
  
      setVideoState({ ...videoState, seeking: false });
      videoPlayerRef.current.seekTo(value / 100);
    }
  
    const volumeChangeHandler = (value) => {
      const newVolume = parseFloat(value) / 100;
  
      setVideoState({
        ...videoState,
        volume: newVolume,
        muted: Number(newVolume) === 0 ? true : false, // volume === 0 then muted
      });
    };
  
    const volumeSeekUpHandler = (e, value) => {
      const newVolume = parseFloat(value) / 100;
  
      setVideoState({
        ...videoState,
        volume: newVolume,
        muted: newVolume === 0 ? true : false,
      })
    };
  
    const muteHandler = () => {
      //Mutes the video player
      setVideoState({ ...videoState, muted: !videoState.muted });
    };
  
   
  
    const mouseMoveHandler = () => {
      console.log("mouse move")
      controlRef.current.style.visibility = "visible"
      count = 0
    };
    const moveLeaveHandler= () => {
      console.log('mouse leave')
      if(videoState?.playing)
        controlRef.current.style.visibility = "hidden"
      else
        controlRef.current.style.visibility = "visible"
    }
  
    const bufferStartHandler = () => {
      console.log("Bufering.......");
      setVideoState({ ...videoState, buffer: true });
    };
  
    const bufferEndHandler = () => {
      console.log("buffering stoped ,,,,,,play");
      setVideoState({ ...videoState, buffer: false });
    }
    const openFullscreen= ()=> {
        let videoContainer= document.getElementsByClassName("video_container")[0]
        videoContainer.classList.toggle("video_container-full-screen")
    }

    return (
      <div className="video_container">
        <div 
          className="player__wrapper" 
          onMouseMove={mouseMoveHandler} 
          onMouseLeave={moveLeaveHandler}>
          {/* {buffer && <p>Loading</p>} */}

          <Control
            videoPlayerRef= {videoPlayerRef}
            progressBarRef={progressBarRef}
            controlRef={controlRef}
            onPlayPause={playPauseHandler}
            playing={playing}
            onRewind={rewindHandler}
            onForward={handleFastFoward}
            played={played}
            onSeek={seekHandler}
            onMouseSeekDown={onSeekMouseDownHandler}
            onSeekMouseUp={seekMouseUpHandler}
            volume={volume}
            onVolumeChangeHandler={volumeChangeHandler}
            onVolumeSeekUp={volumeSeekUpHandler}
            mute={muted}
            onMute={muteHandler}
            playRate={playbackRate}
            duration={formatDuration}
            currentTime={formatCurrentTime}
            buffer= {buffer}
            openFullscreen= {openFullscreen}
            thumbnail= {thumbnail}
          />
        </div>
        <div
          onMouseMove={mouseMoveHandler} 
          onMouseLeave={moveLeaveHandler}
        >
          <ReactPlayer
            ref={videoPlayerRef}
            className="player"
            url= {src}
            width="100%"
            height="100%"
            playing={playing}
            volume={volume}
            muted={muted}
            onProgress={progressHandler}
            onBuffer={bufferStartHandler}
            onBufferEnd={bufferEndHandler}
            // onLoadedMetadata={onLoadedMetadata}
          />
        </div>
      </div>
    )
}
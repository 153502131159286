import { message } from "antd";
import { domainUrl } from "../../constants/apiconstans/domanUrl";
const likeUnlikeOnFeed=(data)=>{
  let userToken=localStorage.getItem('token')
  var myHeaders = new Headers();
  myHeaders.append("token", `${userToken}`)
  myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
  var requestOptions = {
    headers: myHeaders,
    method: 'POST',
    redirect: 'follow',
    body: data
  }
  return (
    fetch(domainUrl+`/feedAddRemoveLike`,requestOptions)
      .then(response=>response.json())
      .then(result=>{
         if(result?.ResponseCode===1)
          return 1
         else
          throw result?.ResponseMsg
      })
      .catch((error)=>{
          message.error(error)
          return 0
      })
  )
}
const commentOnFeed=(data)=>{
    let userToken=localStorage.getItem('token')
    var myHeaders = new Headers();
    myHeaders.append("token", `${userToken}`)
    myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
    var requestOptions = {
      headers: myHeaders,
      method: 'POST',
      redirect: 'follow',
      body: data
    }
    return (
      fetch(domainUrl+`/feedAddComment`,requestOptions)
        .then(response=>response.json())
        .then(result=>{
           if(result?.ResponseCode===1)
            return 1
           else
            throw result?.ResponseMsg
        })
        .catch((error)=>{
            message.error(error)
            return 0
        })
    )
}
export {
    likeUnlikeOnFeed,
    commentOnFeed
}

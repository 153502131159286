import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl } from '../../../constants/apiconstans/domanUrl'
import { Alert } from '../../../../components'
export const getArtistPosts = createAsyncThunk('ArtistPosts',
    async (artistID, { dispatch, getState }) => {
        dispatch(gettingArtistPosts())
        let userToken=localStorage.getItem('token')
        let user_id= localStorage?.getItem("userId")
        var myHeaders = new Headers();
        myHeaders.append("token", `${userToken}`)
        myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
        myHeaders.append("content-type", `application/json`)
        var requestOptions = {
            method: 'POST',
            headers:myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                user_id,
                artist_id: artistID
            })
        }
        fetch(domainUrl + `/getPostByartistId`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode===1)
                    dispatch(artistPostsResponse(result?.data))
                else
                {
                    dispatch(artistPostsClear())
                    throw 'error'
                }
            })
            .catch(error => {
                dispatch(artistPostsClear())
                Alert("error", error) 
            })
    }
)

const artistPostsSlice = createSlice({
    name: 'artistPosts',
    initialState: {
        data: null,
        loading: false
    },
    reducers: {
        gettingArtistPosts: (state) => {
            state.loading = true
        },
        artistPostsResponse: (state, action) => {
            state.data = action.payload
            state.loading= false
        },
        artistPostsClear: (state) => {
            state.data = null
            state.loading = false
        },
    },
})

export const {gettingArtistPosts, artistPostsResponse, artistPostsClear} = artistPostsSlice.actions
export default artistPostsSlice.reducer
import {useRef, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch} from "react-redux"
import {Modal, Typography, Form, Row, Col, Button} from "antd"
import { NFInput} from "../../Form"
import { actionsApi, renameMyPlaylist} from "../../../shared"

const {Title}= Typography

const PlaylistRenameModal=({visible, old_playlist_name, onClose})=>{

    const {t}= useTranslation()
    const dispatch= useDispatch()
    const {data : playlists}= useSelector(state => state?.playlists)
    const [form] = Form.useForm()
    const btnRef = useRef() 
    const [loading, setLoading]= useState(false)
    useEffect(()=>{
        if(visible)
            {
                setLoading(false)
                form.setFieldsValue({new_playlist_name: old_playlist_name})
            }
    }, [visible])
    const selectFile = () => {
        btnRef.current.click()
    }
    const renamePlaylist=async ()=>{

        setLoading(true)
        let user_id= localStorage?.getItem("userId")
        let data=form.getFieldsValue()
        let formData = new FormData()
        formData.append(`user_id`, user_id)
        formData.append(`old_playlist_name`, old_playlist_name)
        formData.append(`new_playlist_name`, data?.new_playlist_name)
        let result=await renameMyPlaylist(formData)
        if(result)
            dispatch(actionsApi?.playlistsResponse([...playlists?.map(playlist=>{
                if(playlist?.name===old_playlist_name)
                    return {...playlist, name: data?.new_playlist_name}
                return playlist
            })]))
        onClose()
        setLoading(false)
    }
    return (
        <Modal
            visible={visible} 
            title={<Title className="my-0" level={4}>{t("Rename Playlist")}</Title>} 
            footer={
                <Button 
                    type="primary" 
                    onClick={selectFile}
                    loading={loading}
                    size="large"
                    >
                    {t("Rename")}
                </Button>
            } 
            onCancel={onClose}
            centered
            >
                <Form 
                    layout="vertical"
                    onFinish={renamePlaylist} 
                    form={form}
                    initialValues={{
                        remember: true,
                    }}
                    >
                    <Row gutter={16}>
                        <Col span={24}>
                            <NFInput
                                label={t('Playlist')}
                                required
                                message={t('please enter new name')}
                                size='large'
                                name='new_playlist_name'
                                value={form.getFieldValue('new_playlist_name') || ''}
                                placeholder= {t('e.g my favorite')}
                                maxLength={10}
                                showCount
                                />
                        </Col>
                        <Button 
                            htmlType="submit" 
                            ref={btnRef}
                            style={{display:'none'}}
                            >
                            Rename
                        </Button>
                    </Row>
                </Form>
        </Modal>
    )
}
export {PlaylistRenameModal}
import React,{ useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Image, Button, Space, Badge, Input} from 'antd'
import {useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { UserDropDown, Notifications } from '../'
import { useSelector, useDispatch} from 'react-redux'
import {IconMenu2} from '@tabler/icons-react'
import { actionsApi } from '../../shared'
import { SideMenu } from './SidebarMenu'
import './index.css'
const {Search}= Input

const Navbar = () => {
    const {t}= useTranslation()
    const dispatch= useDispatch()
    let location = useLocation()
    const {data}= useSelector(state => state?.login)
    const {data: cart}= useSelector(state => state?.cart)
    const {count}= useSelector(state=> state?.notifications)
    const [token, setToken]= useState(null)
    const [showmenu, setShowMenu] = useState(false)
    const [logshow, setLogShow ] = useState(false)
    const [currentTab, setCurrentTab]= useState(null)

    useEffect(()=>{
        setCurrentTab(location?.pathname?.split("/")[1])
    }, [location])
    useEffect(()=>{
        dispatch(actionsApi?.getCartList())
    }, [])
    useEffect(()=>{
        setToken(localStorage.getItem('token'))
    },[data])

  return (
    <div className='justify-center' id='navbar'>
        <div className='widthnav borderbottom width-90'>
            <div className='mainNavbar'>
                <div className='navBar'>
                    <div>
                        <NavLink to='/'>
                            <Image 
                                src='/assets/img/logo.png' 
                                preview={false} 
                                className='sizelogo' 
                                />
                        </NavLink>
                    </div>
                    <div className={showmenu?'menubar active':'menubar hide'}>
                        <ul>
                            <li>
                                <NavLink to='/store'>
                                    <Button 
                                        type={currentTab==='store' || (token  && currentTab==='') ? 'primary':'default'}
                                        className={currentTab==='store' || (token  && currentTab==='') ? '': 'tab-button'}
                                        >
                                        {t("Store")}
                                    </Button>
                                </NavLink>    
                            </li>    
                            <li>
                                <NavLink to='/collection'>
                                    <Button 
                                        type={currentTab==='collection'?'primary':'default'}
                                        className={currentTab!=='collection'?'tab-button':''}
                                        >
                                        {t("Collection")}
                                    </Button>
                                </NavLink>    
                            </li>    
                            <li>
                                <NavLink to='/tracklist'>
                                    <Button 
                                        type={currentTab==='tracklist'?'primary':'default'}
                                        className={currentTab!=='tracklist'?'tab-button':''}
                                        >
                                        {t("Music")}
                                    </Button>
                                </NavLink>    
                            </li>    
                            <li>
                                <NavLink to='/artist'>
                                    <Button 
                                        type={currentTab==='artist'?'primary':'default'}
                                        className={currentTab!=='artist'?'tab-button':''}
                                        >
                                        {t("Artists")}
                                    </Button>
                                </NavLink>    
                            </li>   
                            <li>
                                <NavLink to='/marketplace'>
                                    <Button 
                                        type={currentTab==='marketplace'?'primary':'default'}
                                        className={currentTab!=='marketplace'?'tab-button':''}
                                        >
                                        {t("MarketPlace")}
                                    </Button>
                                </NavLink>    
                            </li>                    
                        </ul>
                    </div>
                </div>
                <Space className='w-100 justify-end'>   
                    {
                        token &&
                        <Space  style={{marginRight:'10px'}} size={10}>
                            <Badge count={cart?.cart?.length} overflowCount={9}>
                                <Button size='middle' shape='circle'>
                                    <NavLink to='/cart'>
                                        <Image src='/assets/img/carticon.png' width={'20px'} className='up' preview={false} />
                                    </NavLink>
                                </Button>
                            </Badge>
                            <Badge count={ count} overflowCount={10}>
                                <Notifications/>
                            </Badge>
                        </Space>
                    }
                    <Search 
                        placeholder={ t('Search')} 
                        className='radius-6 search-bar'
                        style={{width: '150px'}}
                        />
                    {
                        !token &&
                        <Space>
                            <NavLink to='/'>
                                <Button
                                    type={currentTab==='' ? 'primary':'default'}
                                    >
                                    {t("Log In")}
                                </Button>
                            </NavLink>
                            <NavLink to='/signup'>
                                <Button 
                                    type={currentTab==='signup' ? 'primary':'default'}
                                    >
                                    {t("Sign Up")}
                                    <span onClick={()=>setLogShow(!logshow)}></span>
                                </Button>
                            </NavLink>
                        </Space>
                    }
                    {
                        token &&
                        <UserDropDown/>
                    }
                    <div className='hamburger'>
                        <Button 
                            onClick={() => {setShowMenu(!showmenu) }} 
                            icon={<IconMenu2/>}
                            >
                        </Button>
                    </div>
                </Space>
            </div>
        </div>
        <SideMenu
            visible={showmenu}
            onClose={()=> {setShowMenu(false)}}
            />
    </div>
  )
}

export {Navbar}
export const domainUrl = "https://nfsound-backend.mkaits.com/api"
export const domainUrl2 = "https://nfsound-backend.mkaits.com/api"
export const domainUrl3 = "https://nfsound-backend.mkaits.com/api"
export const domainUrl4 = "https://nfsound-backend.mkaits.com/api"
// export const domainUrl = process.env.REACT_APP_DOMAIN_URL
// export const loginDomain = process.env.REACT_APP_IDENTITY_URL
// export const userloginDomain = process.env.REACT_APP_LOGIN_URL

// REACT_APP_IDENTITY_URL




import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { domainUrl4} from '../../../constants/apiconstans/domanUrl';
import { Alert } from '../../../../components';
export const placeOrder = createAsyncThunk('Checkout',
    async (formData, { dispatch, getState }) => {
        dispatch(gettingCheckout())
        const userToken = localStorage.getItem('token')
        var myHeaders = new Headers()
        myHeaders.append("key","2b223e5cee713616hs54ac203b24e9a123703VT001")
        myHeaders.append("token", userToken || null)
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: formData
        };
        fetch(domainUrl4 + `/placeOrder`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if(result?.ResponseCode)
                    {
                        Alert("success", result?.ResponseMsg)
                    }
                else
                {
                    dispatch(checkoutClear())
                    Alert("error", "error while Checkout") 
                }
            })
            .catch(error => {
                dispatch(checkoutClear())
                Alert("error", error) 
            })
    }
)

const checkoutSlice = createSlice({
    name: 'checkout',
    initialState: {
        data: null,
        loading: false
    },
    reducers: {
        gettingCheckout: (state, action) => {
            state.loading = true;
        },
        checkoutResponse: (state, action) => {
            state.data = action.payload;
            state.loading= false;
        },
        checkoutClear: (state, action) => {
            state.data = null;
            state.loading = false;
        },
    },
})

export const {gettingCheckout, checkoutResponse, checkoutClear} = checkoutSlice.actions
export default checkoutSlice.reducer